import React from 'react';
import moment from 'moment';
import { MDBContainer, MDBCardText } from 'mdbreact';

import Dashboard from './components/DashBoard';
import SumBoard from './components/SumBoard';
import { Fragment } from 'react';
import SignalR from '../../services/signalR';
import DateFilter from './components/DateFilter';
import http from '../../services/http';
import httpAff from '../../services/http/affiliates';
import _ from 'lodash';
import { siteUrlsConfig } from '../../configs';

const initialState = {
  todayPurchase: 0,
  todayFillProfile: 0,
  yesterdayPurchase: 0,
  yesterdayFillProfile: 0,
  yesterdayTotalAmount: 0,
  todayTotalAmount: 0,
  todayConfirmedEmails: 0,
  yesterdayConfirmedEmails: 0,
  todayConversion: 0,
  yesterdayConversion: 0,
  todayFtd: 0,
  yesterdayFtd: 0,
}
class ReportPage extends React.Component  {
  state = {
    form: {
      From: moment().format('YYYY-MM-DD'),
      To: moment().format('YYYY-MM-DD')
    },
    values: {
      3: {},
      8: {},
      37: {},
    },
    increment: 0,
    sumed: {},
    testValues: {
      3: { ...initialState },
      8: { ...initialState },
      37: { ...initialState },
    }
  }

  componentDidMount() {
    this.fetchInfo(3);
    this.fetchInfo(8);
    this.fetchInfo(37);
  }

  
  getTodayParams = (actionType, choosedSiteId) => {
    const { form : { To, From } } = this.state;
    if (actionType) {
      return ({
        SiteId: choosedSiteId,
        ActionType: actionType,
        From,
        To,
      })
    } else {
      return ({
        SiteId: choosedSiteId,
        From,
        To,
      })
    }
  };

  getYesterdayParams = (actionType, choosedSiteId) => {
    const { form : { From } } = this.state;
    const periodDate = moment(From).subtract(1, 'days').format('YYYY-MM-DD');
    if (actionType) {
      return ({
        SiteId: choosedSiteId,
        ActionType: actionType,
        From: periodDate,
        To: periodDate,
      })
    } else {
      return ({
        SiteId: choosedSiteId,
        From: periodDate,
        To: periodDate,
      })
    }
  }

  isTodayInPeriod = (fromDate, toDate) => {
    const today = moment().startOf('day'); // Получаем сегодняшний день без времени
    const from = moment(fromDate, 'YYYY-MM-DD').startOf('day');
    const to = moment(toDate, 'YYYY-MM-DD').endOf('day');
  
    return today.isBetween(from, to, null, '[]'); // Проверка включительно
  };

  updateValues = (type, value, siteId) => {
    this.setState({
      testValues: {
        ...this.state.testValues,
        [siteId]: {
          ...this.state.testValues[siteId],
          [type]: value
        }
      }
    });
  }

  fetchInfo = async (siteId) => {
    try {

      this.setState({loading: true, todayPurchaseLoading: true, todayFillProfileLoading: true, yesterdayPurchaseLoading: true, yesterdayFillProfileLoading: true, yesterdayTotalAmountLoading: true, todayConfirmedEmailsLoading: true})

      //FILL PROFILE FIRST BLOCK
      const {data: yesterdayFtd} = await http.get('/reports/report4_2', {
        params: this.getYesterdayParams('firstpurchase', siteId)
      });
      this.updateValues('yesterdayFtd', yesterdayFtd.count, siteId)

      const {data: todayFtd} = await http.get('/reports/report4_2', {
        params: this.getTodayParams('firstpurchase', siteId)
      });
      this.updateValues('todayFtd', todayFtd.count, siteId)

      //FILL PROFILE FIRST BLOCK
      const {data: yesterdayFillProfile} = await http.get('/reports/report4_2', {
        params: this.getYesterdayParams('FillProfile', siteId)
      });
      this.updateValues('yesterdayFillProfile', yesterdayFillProfile.count, siteId)

      const {data: todayFillProfile} = await http.get('/reports/report4_2', {
        params: this.getTodayParams('FillProfile', siteId)
      });
      this.updateValues('todayFillProfile', todayFillProfile.count, siteId)
      
      // EMAIL CONFIRMED SECOND BLOCK
      const {data: todayConfirmedEmails} = await http.get('/reports/report4_2', {
        params: this.getTodayParams('emailconfirmed', siteId)
      });
      const {data: yesterdayConfirmedEmails} = await http.get('/reports/report4_2', {
        params: this.getYesterdayParams('emailconfirmed', siteId)
      });
      this.updateValues('todayConfirmedEmails', todayConfirmedEmails.count, siteId)
      this.updateValues('yesterdayConfirmedEmails', yesterdayConfirmedEmails.count, siteId)
  
      // PURCHASE THIRD BLOCK
      const {data: todayPurchase} = await http.get('/reports/report4_2', {
        params: this.getTodayParams('Purchase', siteId)
      });
      this.updateValues('todayPurchase', todayPurchase.quantity, siteId)
    
      const {data: yesterdayPurchase} = await http.get('/reports/report4_2', {
        params: this.getYesterdayParams('Purchase', siteId)
      });
      this.updateValues('yesterdayPurchase', yesterdayPurchase.quantity, siteId)
  
      const {data: todayConversion} = await httpAff.get(`/api/${siteUrlsConfig[siteId]}/GetAffData/${this.getTodayParams().From}/${moment(this.getTodayParams().To).add(1, 'days').format('YYYY-MM-DD')}`);
      const groupedTodayConversion = _.groupBy(todayConversion, i => i.email);
      const formattedTodayConversion = Object.keys(groupedTodayConversion).map((key, index) => ({
        sum: groupedTodayConversion[key][0].sum,
      }))
      const countTodayConversion = _.sumBy(formattedTodayConversion, 'sum');
      this.updateValues('todayConversion', countTodayConversion, siteId)
    
      const {data: yesterdayConversion} = await httpAff.get(`/api/${siteUrlsConfig[siteId]}/GetAffData/${this.getYesterdayParams().From}/${moment(this.getYesterdayParams().To).add(1, 'days').format('YYYY-MM-DD')}`);
      const groupedYesterdayConversion = _.groupBy(yesterdayConversion, i => i.email);
      const formattedYesterdayConversion = Object.keys(groupedYesterdayConversion).map((key, index) => ({
        sum: groupedYesterdayConversion[key][0].sum,
      }))
      const countYesterdayConversion = _.sumBy(formattedYesterdayConversion, 'sum');
      this.updateValues('yesterdayConversion', countYesterdayConversion, siteId)

    
      const [{ data: yesterdayTotal }, { data: todayTotal }] = await Promise.all(
        [
          http.get('/reports/report5_2', 
            {
              params: this.getYesterdayParams(undefined, siteId)
            },
            
          ),
          http.get('/reports/report5_2', {
            params: this.getTodayParams(undefined, siteId)
          })
        ]
      );
      this.updateValues('yesterdayTotalAmount', yesterdayTotal.amount, siteId)
      this.updateValues('todayTotalAmount', todayTotal.amount, siteId)
    
    } catch (err) {
    }
  }
  
  
  onNewMessage = (message) => {
    const { form : { To, From } } = this.state;
    const isTodayInPeriod = this.isTodayInPeriod(From, To);

    if (!isTodayInPeriod) {
      return;
    }
    const siteId = message.siteId;
    if (message.type.name === "purchase") {
      this.setState(prevState => {
        return {
          ...prevState,
          testValues: {
            ...prevState.testValues,
            [siteId]: {
              ...prevState.testValues[siteId],
              todayPurchase: prevState.testValues[siteId].todayPurchase + 1,
              todayTotalAmount: prevState.testValues[siteId].todayTotalAmount + (+message.value),
            }
          }
        }
      })
    }
    if (message.type.name === "emailconfirmed") {
      this.setState(prevState => {
        return {
          ...prevState,
          testValues: {
            ...prevState.testValues,
            [siteId]: {
              ...prevState.testValues[siteId],
              todayConfirmedEmails: prevState.testValues[siteId].todayConfirmedEmails + 1,
            }
          }
        }
      })
    }
    if (message.type.name === "fillprofile") {
      this.setState(prevState => {
        return {
          ...prevState,
          testValues: {
            ...prevState.testValues,
            [siteId]: {
              ...prevState.testValues[siteId],
              todayFillProfile: prevState.testValues[siteId].todayFillProfile + 1,
            }
          }
        }
      })
    }
  };

  onFormChange = (type, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [type]: value
      }
    })
  };

  onSubmit = () => {
    this.fetchInfo(3);
    this.fetchInfo(8);
    this.fetchInfo(37);
  }

  render() {
    return (
      <Fragment>
        <SignalR onNewMessage={this.onNewMessage} allSitesConnected />
        <MDBContainer fluid id='v6' className='mb-5'>
          <DateFilter onFormChange={this.onFormChange} form={this.state.form} onSubmit={this.onSubmit} />
          <MDBCardText>Total</MDBCardText>
          <SumBoard sum={this.state.testValues} />
          <MDBCardText>Bezkompleksov</MDBCardText>
          <Dashboard values={this.state.testValues} siteId={3} />
          <MDBCardText>NatalieDate</MDBCardText>
          <Dashboard values={this.state.testValues} siteId={8} />
          <MDBCardText>FlirtForDate</MDBCardText>
          <Dashboard values={this.state.testValues} siteId={37} />
        </MDBContainer>
      </Fragment>
    );
  }
}

export default ReportPage;