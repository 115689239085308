import React, { useEffect, useState } from "react";
import {
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBSelect,
  MDBCol,
  MDBSpinner,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBIcon
} from "mdbreact";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import http from "../../../../services/http";

import * as S from "./styled";
import TableRow from "../TableRow";

const PartnerWebTable = () => {

  const choosedSiteId = useSelector(state => state.app.choosedSiteId);
  
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [collapsed, setCollapsed] = useState(false);
  const [info, setInfo] = useState([]);
  const [partners, setPartners] = useState([]);
  const [webs, setWebs] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");

  const resize = () => {
    if (window.innerWidth >= 780 && collapsed) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, []);

  const [filter, setFilter] = useState({
    linkId: "",
    webId: "",
    linkName: queryParams.get("linkName") || "",
    payOut: {
      operation: {
        value: "=",
        label: "=",
      },
      value: "",
    },
    isActive: {
      label: "No Filter",
      value: "No Filter",
    },
  });

  const [sorting, setSorting] = React.useState({
    field: "",
    direction: "asc",
  });

  const [loadingInfo, setLoadingInfo] = useState(false);

  useEffect(() => {
    if (selectedPartner) {
      getInfo();
    }
  }, [choosedSiteId, selectedPartner]);

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    const { data } = await http.get("/users");
    const formatted = data
      .filter((i) => i.roles?.length && i.roles[0].role === 3)
      .map((i) => ({
        ...i,
        text: i.email,
        value: i.email,
        role: 3,
      }))
      .sort((a, b) => a.email.localeCompare(b.email));
    const indexToCheck =
      formatted.findIndex((i) => i.email === "cpamatica@mediamars.com") || 2;
      if (formatted[indexToCheck]) {
        formatted[indexToCheck].checked = true;
      }

    setPartners(formatted);
    if (formatted[indexToCheck]) {
      setSelectedPartner(formatted[indexToCheck].email);
    }
  };

  const getInfo = async () => {
    setLoadingInfo(true);
    const userId = partners.find((p) => p.email === selectedPartner).id;
    try {
      const { data } = await http.get("/partnerdeals/byuserdetailed", {
        params: {
          userId: userId,
        },
      });
      const filteredDealsBySiteId = data.filter((i) => i.siteId === choosedSiteId);
      const webs = await getWebs(filteredDealsBySiteId);
      setInfo(webs);
    } catch (err) {
      console.log('err', err);
      alert('Errored');
    }
    setLoadingInfo(false);
  };

  const getWebs = async (deals) => {
    const promises = deals.map(deal => http.get(`/partnerdeals/${deal.id}/webs`));
    const resolved = await Promise.allSettled(promises);
    const webs = resolved.filter(i => i.status === 'fulfilled').map(i => i.value.data);
    const websWithSum = webs.map(i => countDailyLimitSum(i))
    let formatted = [];
    websWithSum.forEach(web => {
      web.forEach(w => {
        formatted.push(w);
      })
    })
    const final = formatted.map(web => {
      const findDeal = deals.find(deal => deal.id === web.partnerDealId);
      return ({
        ...web,
        deal: findDeal,
      });
    })
    return final;
  }

  const countDailyLimitSum = (web) => {
    let sum = 0;
    [...web].forEach(w => {
      sum += w.dailyLimit;
    })
    return web.map(i => ({
      ...i,
      dailyLimitSum: sum,
    }))
  };

  const onSelectPartner = (partner) => {
    setSelectedPartner(partner);
    const formatted = partners;
    const index = formatted.findIndex((i) => i.email === partner);
    if (formatted[index]) {
      formatted[index].checked = true;
    }
    setPartners(formatted);
  };

  const onFilterChange = (type, value) =>
    setFilter({ ...filter, [type]: value });

  const onPayoutValueChange = (value) => {
    setFilter({
      ...filter,
      payOut: {
        ...filter.payOut,
        value,
      },
    });
  };

  const onPayoutOperationChange = (operation) => {
    setFilter({
      ...filter,
      payOut: {
        ...filter.payOut,
        operation,
      },
    });
  };

  const filterInfo = (data) => {
    return data
      .filter((item) => item.deal.partnerLink.uid.includes(filter.linkId))
      .filter((item) => item.webId.includes(filter.webId))
      .filter((item) =>
        item.deal.partnerLink.name
          .toLowerCase()
          .includes(filter.linkName.toLowerCase())
      )
      .filter((item) => {
        if (filter.isActive.value === "True") {
          return item.status;
        }
        if (filter.isActive.value === "False") {
          return !item.status;
        }
        return true;
      })
      .filter((item) => {
        if (filter.payOut.value === "") {
          return true;
        }
        if (filter.payOut.operation.value === "=") {
          return item.deal.payout == filter.payOut.value;
        }
        if (filter.payOut.operation.value === ">=") {
          return item.deal.payout >= filter.payOut.value;
        }
        if (filter.payOut.operation.value === "<=") {
          return item.deal.payout <= filter.payOut.value;
        }
        if (filter.payOut.operation.value === ">") {
          return item.deal.payout > filter.payOut.value;
        }
        if (filter.payOut.operation.value === "<") {
          return item.deal.payout < filter.payOut.value;
        }
        return true;
      });
  };

  const onSortClick = (header) => {
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const isString = (value) => typeof value === "string";

  const sortedData = () => {
    if (!info.length) {
      return [];
    }

    if (sorting.field === "LinkId") {
      return info.sort((a, b) => {
        const aValue = Number(a.deal.partnerLink.uid);
        const bValue = Number(b.deal.partnerLink.uid);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }

    if (sorting.field === "LinkName") {
      return info.sort((a, b) => {
        const aValue = a.deal.partnerLink.name;
        const bValue = b.deal.partnerLink.name;
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    if (sorting.field === "WebId") {
      return info.sort((a, b) => {
        const aValue = a.webId;
        const bValue = b.webId;
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    if (sorting.field === "Payout") {
      return info.sort((a, b) => {
        const aValue = Number(a.deal.payout);
        const bValue = Number(b.deal.payout);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    if (sorting.field === "IsActive") {
      return info.sort((a, b) => {
        const aValue = Number(a.status);
        const bValue = Number(b.status);
        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
    }
    return info;
  };

  return (
    <MDBCard narrow className="pb-3">
      <MDBCardBody>
      <S.CollapseButton onClick={() => setCollapsed(!collapsed)}>
        <MDBIcon icon={!collapsed ? 'minus' : 'bars'} />
      </S.CollapseButton>
        {!collapsed && <>
          <MDBRow>
            <MDBCol lg="3" md="6">
              <MDBSelect
                label="Partner"
                className="colorful-select dropdown-primary mx-2"
                options={partners}
                selected={selectedPartner}
                getTextContent={onSelectPartner}
                search
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="3">
              <MDBInput
                label="LinkId"
                value={filter.linkId}
                onChange={(e) => onFilterChange("linkId", e.target.value)}
              />
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                label="LinkName"
                value={filter.linkName}
                onChange={(e) => onFilterChange("linkName", e.target.value)}
              />
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                label="WebId"
                value={filter.webId}
                onChange={(e) => onFilterChange("webId", e.target.value)}
              />
            </MDBCol>
            <MDBCol md="3">
              <span>Is Active</span>
              <Select
                isSearchable={false}
                styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
                placeholder={"Is Active"}
                options={[
                  { value: "False", label: "False" },
                  { value: "True", label: "True" },
                  { value: "No Filter", label: "No Filter" },
                ]}
                value={filter.isActive}
                onChange={(val) => onFilterChange("isActive", val)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="2">
              <span>Payout compare</span>
              <Select
                isSearchable={false}
                styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
                placeholder={"operation"}
                options={[
                  { value: "=", label: "=" },
                  { value: ">", label: ">" },
                  { value: ">=", label: ">=" },
                  { value: "<", label: "<" },
                  { value: "<=", label: "<=" },
                ]}
                value={filter.payOut.operation}
                onChange={(val) => onPayoutOperationChange(val)}
              />
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                label="Payout"
                value={filter.payOut.value}
                onChange={(e) => onPayoutValueChange(e.target.value)}
              />
            </MDBCol>
          </MDBRow>
        </>}
        <S.Container>
          <MDBTable
            scrollY
            responsive
            bordered
            maxHeight={500}
          >
            <MDBTableHead color="cyan" textWhite>
              <tr>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "LinkId"}
                  onClick={() => onSortClick("LinkId")}
                >
                  LinkId
                </S.Th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "LinkName"}
                  onClick={() => onSortClick("LinkName")}
                >
                  LinkName
                </S.Th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "WebId"}
                  onClick={() => onSortClick("WebId")}
                >
                  WebId
                </S.Th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "Payout"}
                  onClick={() => onSortClick("Payout")}
                >
                  Payout
                </S.Th>
                <th>Dailylimit</th>
                <S.Th
                  direction={sorting.direction}
                  isSorting={sorting.field === "IsActive"}
                  onClick={() => onSortClick("IsActive")}
                >
                  IsActive
                </S.Th>
                <th>Start_date</th>
                <th></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {!loadingInfo ? (
                filterInfo(sortedData()).map((row) => (
                  <TableRow
                    key={row.id}
                    row={row}
                    getInfo={getInfo}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <MDBSpinner />
                  </td>
                </tr>
              )}
            </MDBTableBody>
          </MDBTable>
        </S.Container>
      </MDBCardBody>
    </MDBCard>
  );
};

export default PartnerWebTable;
