import React from 'react';
import { MDBContainer } from 'mdbreact';

import PartnerStats from './PartnerStats';

const PartnerStatsPage = () =>  {
    return (
        <MDBContainer fluid>
          <PartnerStats />
        </MDBContainer>
    );
}

export default PartnerStatsPage;