/* eslint-disable react-hooks/exhaustive-deps */
import {
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBCardFooter,
  MDBSpinner,
  MDBInput,
} from "mdbreact";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import Select from "react-select";
import { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import http from "../../../services/http";

import DateInput from "../../../components/DateInput";
import { countProfit, countRoi, countTotals } from "../utils";
import AgeFilter from "../components/AgeFilter";
import ConditionFilter from "../components/ConditionFilter";

import renderLoadingContent from "../../../components/renderLoadingContent";

import * as S from "./styled";
import useDateScrollHeader from "../../../hooks/useDateScrollHeader";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { failureOptions } from "../../../configs";

const PartnerStats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { choosedSiteId } = useSelector((state) => state.app);
  const [openFailureSnackbar] = useSnackbar(failureOptions);

  const [sites, setSites] = useState([]);
  const [partners, setPartners] = useState([]);

  const [filterForm, setFilterForm] = useState({
    site: [],
    partner: [],
    age: [],
    payout: "",
    dailyLimit: "",
    linkId: "",
    webId: "",
    country: "",
  });

  const [dateForm, setDateForm] = useState({
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  useDateScrollHeader(
    {
      date: `${moment(dateForm.from).format("DD-MM-YYYY")} - ${moment(
        dateForm.to
      ).format("DD-MM-YYYY")}`,
    },
    [dateForm]
  );

  const groupingItems = [
    {
      param: "Site",
      isFiltering: false,
    },
    {
      param: "User",
      isFiltering: false,
    },
    {
      param: "LinkId",
      isFiltering: true,
      filterParam: "linkId",
      redirectParam: "WebId",
    },
    {
      param: "WebId",
      isFiltering: true,
      filterParam: "webId",
      redirectParam: "SubId",
    },
    {
      param: "SubId",
      isFiltering: false,
    },
    {
      param: "Country",
      isFiltering: true,
      filterParam: "country",
    },
    {
      param: "Age",
      isFiltering: false,
    },
  ];

  const [grouping, setGrouping] = useState("User");

  const getSites = async () => {
    const { data } = await http.get("/sites/foradminpanel");
    const filteredSites = data.filter(
      (site) => site.id !== 15 && site.id !== 35
    );
    setSites(filteredSites);
    const selectedSite = filteredSites.find((s) => s.id === choosedSiteId);
    if (selectedSite) {
      onFilterFormChange(
        [{ label: selectedSite.name, value: choosedSiteId }],
        "site"
      );
    }
  };

  const getPartners = async () => {
    const { data = [] } = await http.get("/users");
    const partnerUsers = data.filter((u) =>
      Boolean(u.roles.find((r) => r.role === 3))
    );
    const sorted = _.orderBy(partnerUsers, (i) => i.email, "asc");
    setPartners(sorted);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await http.get("/reports/partnerstats", {
        params: {
          Grouping: grouping,
          StartDate: dateForm.from,
          EndDate: dateForm.to,
          SiteId: filterForm.site.map((s) => s.value).join(", "),
          PartnerId: filterForm.partner.map((p) => p.value).join(", "),
          Age: filterForm.age.map((a) => a.value).join(", "),
          Payout: filterForm.payout,
          DailyLimit: filterForm.dailyLimit,
          LinkId: filterForm.linkId,
          WebId: filterForm.webId,
          Country: filterForm.country,
        },
      });
      setData(data);
    } catch (err) {
      openFailureSnackbar("Something went wrong...");
    }
    setLoading(false);
  };

  useEffect(() => {
    getSites();
    getPartners();
  }, []);

  useEffect(() => {
    loadData();
  }, [
    grouping,
    filterForm.site,
    filterForm.linkId,
    filterForm.webId,
    filterForm.country,
  ]);

  const totals = countTotals(data, {
    moneyAmount: "moneyAmountCurrency",
    payoutAmount: "payoutAmountCurrency",
  });

  const onFilterFormChange = (value, field) => {
    setFilterForm({
      ...filterForm,
      [field]: value,
    });
  };

  const [sorting, setSorting] = useState({
    field: "",
    direction: "asc",
  });

  const onSortClick = (header) => {
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const headers = [
    {
      label: "Grouping",
      field: "grouping",
    },
    {
      label: "FillProfiles",
      field: "fillProfiles",
    },
    {
      label: "Primary sales",
      field: "firstPurchases",
    },
    {
      label: "Purchase Amount",
      field: "purchaseAmount",
    },
    {
      label: `Money amount (${totals.moneyAmount}$)`,
      field: "moneyAmount",
    },
    {
      label: `Payout (${totals.payoutAmount}$)`,
      field: "payoutAmount",
    },
    {
      label: "ROI",
      field: "roi",
    },
    {
      label: "Profit",
      field: "profit",
    },
  ];

  const formatData = (data) =>
    data.map((item) => ({
      ...item,
      roi: countRoi(
        item.moneyAmount,
        item.payoutAmount,
        item.payoutAmountCurrency,
        item.moneyAmountCurrency
      ),
      profit: countProfit(
        item.moneyAmount,
        item.payoutAmount,
        item.payoutAmountCurrency,
        item.moneyAmountCurrency
      ),
    }));

  const sortData = (data) => {
    return sorting.field
      ? _.orderBy(data, (i) => i[sorting.field], sorting.direction)
      : data;
  };

  const onGroupingItemClick = (value) => {
    const findGrouping = groupingItems.find((i) => i.param === grouping);
    if (findGrouping?.isFiltering) {
      onFilterFormChange(value, findGrouping?.filterParam);
      if (findGrouping?.redirectParam) {
        setGrouping(findGrouping.redirectParam)
      }
    }
  };

  return (
    <S.Container>
      <MDBCard>
        <MDBCardBody>
          <MDBRow>
          <DateInput
              value={dateForm}
              onChange={(value) => setDateForm(value)}
            />
            <MDBCol lg="4" md="6">
              <MDBInput
                onChange={(e) => onFilterFormChange(e.target.value, "linkId")}
                value={filterForm.linkId}
                label="LinkId"
              />
            </MDBCol>
            <MDBCol lg="4" md="6">
              <MDBInput
                onChange={(e) => onFilterFormChange(e.target.value, "webId")}
                value={filterForm.webId}
                label="WebId"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol lg="4" md="6">
              <span>Site</span>
              <Select
                isSearchable={false}
                placeholder={"Site"}
                options={sites.map((s) => ({ label: s.name, value: s.id }))}
                isMulti
                value={filterForm.site}
                onChange={(val) => onFilterFormChange(val, "site")}
                closeMenuOnSelect={false}
              />
            </MDBCol>
            <MDBCol lg="4" md="6">
              <span>Partner</span>
              <Select
                isSearchable
                styles={{
                  multiValue: (s) => ({ ...s, maxWidth: 100 }),
                }}
                placeholder={"Partner"}
                options={partners.map((s) => ({ label: s.email, value: s.id }))}
                isMulti
                value={filterForm.partner}
                onChange={(val) => onFilterFormChange(val, "partner")}
                closeMenuOnSelect={false}
              />
            </MDBCol>
            <MDBCol lg="4" md="6">
              <MDBInput
                onChange={(e) => onFilterFormChange(e.target.value, "country")}
                value={filterForm.country}
                label="Country"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol lg="4" md="6">
              <AgeFilter
                value={filterForm.age}
                onChange={(val) => onFilterFormChange(val, "age")}
              />
            </MDBCol>
            <MDBCol lg="4" md="6">
              <ConditionFilter
                label="Payout"
                onChange={(val) => onFilterFormChange(val, "payout")}
              />
            </MDBCol>
            <MDBCol lg="4" md="6">
              <ConditionFilter
                label="DailyLimit"
                onChange={(val) => onFilterFormChange(val, "dailyLimit")}
              />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardFooter>
          <MDBBtn color="default" onClick={loadData}>
            Apply
          </MDBBtn>
        </MDBCardFooter>
      </MDBCard>
      <MDBCard className="mt-5">
        <MDBTabs className="mb-3">
          {groupingItems.map((item) => (
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => setGrouping(item.param)}
                active={grouping === item.param}
              >
                {item.param}
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>
        {renderLoadingContent(
          <MDBTable responsive bordered>
            <MDBTableHead color="cyan" textWhite>
              <tr>
                {headers.map((h) => (
                  <S.Th
                    direction={sorting.direction}
                    isSorting={sorting.field === h.field}
                    onClick={() => onSortClick(h.field)}
                  >
                    {h.label}
                  </S.Th>
                ))}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {sortData(formatData(data)).map((item) => (
                <tr>
                  <td
                    style={
                      groupingItems.find((i) => i.param === grouping)
                        ?.isFiltering
                        ? {
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }
                        : {}
                    }
                    onClick={() => onGroupingItemClick(item.grouping)}
                  >
                    {item.grouping}
                  </td>
                  <td>{item.fillProfiles}</td>
                  <td>{item.firstPurchases}</td>
                  <td>{item.purchaseAmount}</td>
                  <td>
                    {item.moneyAmount} {item.moneyAmountCurrency}
                  </td>
                  <td>
                    {item.payoutAmount} {item.payoutAmountCurrency}
                  </td>
                  <td>
                    {countRoi(
                      item.moneyAmount,
                      item.payoutAmount,
                      item.payoutAmountCurrency,
                      item.moneyAmountCurrency
                    )}
                  </td>
                  <td>
                    {countProfit(
                      item.moneyAmount,
                      item.payoutAmount,
                      item.payoutAmountCurrency,
                      item.moneyAmountCurrency
                    )}
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>,
          loading
        )}
      </MDBCard>
    </S.Container>
  );
};

export default PartnerStats;
