import { MDBBtn } from "mdbreact";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
    render() {
        return (
            <ExcelFile filename={`HighRollers-data`} element={<MDBBtn>Download Excel</MDBBtn>}>
                <ExcelSheet data={this.props.data} name="HighRollersData">
                    <ExcelColumn label="ProfileId" value="profileId"/>
                    <ExcelColumn label="Conversions" value="conversions"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download;