/* eslint-disable no-unused-vars */
import React from 'react';
import { MDBInput, MDBBtn, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions } from 'mdbreact';
import { useSnackbar } from 'react-simple-snackbar'

import { ROLES } from '../../../../constants';

import http from '../../../../services/http';
import { failureOptions, succesOptions } from '../../../../configs';


const initialUserForm = {
  email: '',
  password: '',
  role: 0,
};

const CreateUser = ({sites}) => {
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const [formSites, setFormSites] = React.useState([]);

  const [userForm, setUserForm] = React.useState({
    ...initialUserForm,
    site: sites[0].id,
  })

  const onInputChange = (value, inputType) => {
    setUserForm({
      ...userForm,
      [inputType]: value,
    });
  }

  const createUser = async () => {
    try {
      console.log(userForm);
      const {data: user} = await http.post('/users', {
        ...userForm,
        siteId: formSites[0],
      });
      const {data: users} = await http.get('/users');
      const createdUser = users.find(u => u.email === user.email);

      const sitesWithoutFirstEl = formSites.filter((_, i) => i > 0);
      sitesWithoutFirstEl.map(async (s) => {
        await http.post(`/users/${createdUser.id}/roles`, {
          role: userForm.role,
          siteId: s,
        }); 
      })

      openSuccessSnackbar('Created');
    } catch (err) {
      openFailureSnackbar('Failure');
    }

    setUserForm({
      ...userForm,
      email: '',
      password: '',
    })
  };

  return (
    <form>
      <p className="h5 text-center mb-4">Create</p>
      <div className="grey-text">
        <MDBInput
          onChange={(e) => onInputChange(e.target.value, 'email')}
          value={userForm.email}
          required
          label="User email"
          group
          type="email"
          validate
          error="wrong"
          success="right"
        />
        <MDBInput
          onChange={(e) => onInputChange(e.target.value, 'password')}
          value={userForm.password}
          required
          label="User password"
          group
          type="password"
          validate
        />
      </div>
      <MDBSelect
        label="Pick User role"
        getValue={(val) => onInputChange(parseInt(val), 'role')}
        className='colorful-select dropdown-green'
      >
        <MDBSelectInput selected={userForm.role} />
        <MDBSelectOptions>
          {Object.keys(ROLES).map(role => <MDBSelectOption key={role} value={role}>{ROLES[role]}</MDBSelectOption>)}
        </MDBSelectOptions>
      </MDBSelect>
      <MDBSelect
        label="Pick User site"
        getValue={(val) => setFormSites(val)}
        className='colorful-select dropdown-primary'
        multiple
        
      >
        <MDBSelectInput selected={userForm?.site?.id} />
        <MDBSelectOptions>
          {sites.map(param => <MDBSelectOption checked key={param.id} value={param.id}>{param.name}</MDBSelectOption>)}
        </MDBSelectOptions>
      </MDBSelect>
      <div className="text-center">
        <MDBBtn onClick={createUser}>Create</MDBBtn>
      </div>
    </form>
  )
}

export default CreateUser;