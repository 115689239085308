import React from 'react';
import { MDBContainer } from 'mdbreact';

import AffTable from './components/AffTable';

import * as S from './styled';

class AffDataPage extends React.Component  {

  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <AffTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default AffDataPage;