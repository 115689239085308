import React from "react";

import { websTableHeaders } from "./constants";

import BaseTable from "../BaseTable";
import { convertCurrencyToSymbol } from "../../utils";
import { RoiCell, TypeCell } from "./styled";

const WebsTable = ({
  data = [],
  onSelectWeb,
  selectedPartner,
  selectedLink,
  selectedLinkUid,
}) => {
  const rowRender = (webItem) => (
    <tr style={{ backgroundColor: !webItem.isActive ? '#fadadd' : 'transparent' }}>
      <td
        style={{ cursor: "pointer", fontWeight: 'bold', color: 'blue' }}
        onClick={() => onSelectWeb(webItem.web)}
      >
        <u>
          <strong>
            <div>{selectedPartner}</div>
            <div>{selectedLinkUid}</div>
            <div>{webItem.web}</div>
          </strong>
        </u>
      </td>
      <TypeCell type={webItem.actionName}>{webItem.actionName}</TypeCell>
      <td>{parseInt(webItem.ffd)}</td>
      <td>{webItem.countAll}</td>
      <td>{webItem.count}</td>
      <td>{webItem.coins}</td>
      <td>{webItem.dailyLimit}</td>
      <td>
        {webItem.payout} {convertCurrencyToSymbol(webItem.payoutCurrency)}
      </td>
      <td>
        {webItem.investment} {convertCurrencyToSymbol(webItem.payoutCurrency)}
      </td>
      <td>
        {webItem.turnover} {convertCurrencyToSymbol(webItem.turnoverCurrency)}
      </td>
      <RoiCell roi={webItem.roi}>{webItem.roi}</RoiCell>
    </tr>
  );

  return (
    <BaseTable
      rowKey="web"
      data={data}
      rowRender={rowRender}
      headers={websTableHeaders}
    />
  );
};

export default WebsTable;
