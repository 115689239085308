import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "react-simple-snackbar";
import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBIcon } from "mdbreact";

import * as S from "./styled";
class Dashboard extends React.Component {
  render() {
    const siteId = this.props.siteId;
    const values = this.props.values[siteId];
    return (
      <S.Container>
        <section className="mb-4">
          <MDBRow>
            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" size="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="primary"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="users" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayFillProfile}
                    </h5>
                    <p className="font-small grey-text">Fill Profile</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayFillProfile}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="warning"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="envelope" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayConfirmedEmails}
                    </h5>
                    <p className="font-small grey-text">Confirmed</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayConfirmedEmails}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="info"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="dollar-sign" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayPurchase}
                    </h5>
                    <p className="font-small grey-text">Sales</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayPurchase}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="danger"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="database" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayTotalAmount}
                    </h5>
                    <p className="font-small grey-text">Amount</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayTotalAmount}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="danger"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="database" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayConversion}
                    </h5>
                    <p className="font-small grey-text">Conversion</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayConversion}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="success"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="pen" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {values.todayFtd}
                    </h5>
                    <p className="font-small grey-text">FTD</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {values.yesterdayFtd}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

          </MDBRow>
        </section>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  choosedSiteId: app.choosedSiteId,
});

export default withSnackbar(connect(mapStateToProps, null)(Dashboard));
