import React, { useMemo } from 'react';
import { MDBCardBody,  MDBTable, MDBTableBody, MDBTableHead, MDBCard, MDBSpinner, MDBIcon} from 'mdbreact';
import { connect } from 'react-redux';

import axios from "axios";

import http from '../../../../services/http/affiliates';

import * as S from './styled';
import TableRow from '../TableRow';
import { siteUrlsConfig } from '../../../../configs';
import SearchInput from '../../../../components/SearchInput';

const pageSize = 50;

const headers = [
  'ID',
  'Email',
  'ProfileId',
  'Enabled',
  'Messages',
  'Limit',
  'AddMethod',
  'Online',
];

const MegaChatTable = ({ choosedSiteId, user }) => {
  const [searchText, setSearchTest] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [loading, setLoading] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  // const [data, setData] = React.useState([]);
  const [sorting, setSorting] = React.useState({
    field: '',
    direction: 'asc',
  });


  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/AffiliatesMegachat`, null, {
          cancelToken: cancelTokenSource.token
        });
        const megaChatData = response.data;
        const formatted = megaChatData.map((item, index) => ({ ...item, ind: index + 1 }));
        setAllData(formatted);
        setTotalPages(Math.ceil(formatted.length / pageSize));
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled due to component unmount or choosedSiteId change.');
        } else {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      cancelTokenSource.cancel('Request canceled due to cleanup');
    };
  
  }, [choosedSiteId, pageSize, siteUrlsConfig, http]);

  const onSortClick = (header) => {
    if (header === 'ID') return;
    setSorting({
      field: header,
      direction: sorting.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const isString = value => typeof value === 'string';

  const sortedData = (data) => {
    if (!data.length) {
      return [];
    }

    const field = sorting.field.toLocaleLowerCase();

    if (field === 'messages') {
      if (sorting.direction === 'desc') {
        const filtered1 = data.filter(i => i.m1 && i.m2);
        const filtered2 = data.filter(i => i.m1 && !i.m2);
        const filtered3 = data.filter(i => !i.m1 && !i.m2);
        return [...filtered1, ...filtered2, ...filtered3];
      } else {
        return data;
      }
    }
    return data.sort((a, b) => {

      const aValue = isString(a[field])
        ? a[field].includes('$')
          ? Number(a[field].replace(/\$/g, ''))
          : a[field]
        : a[field];
      const bValue = isString(b[field])
        ? b[field].includes('$')
          ? Number(b[field].replace(/\$/g, ''))
          : b[field]
        : b[field];

      return sorting.direction === 'asc' ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
    });
  }

  const paginate = (array, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  const filteredBySearch = (items) => searchText ? items.filter(item => item?.email?.toLowerCase().startsWith(searchText.toLowerCase()) ||  `${item?.profileid}`.startsWith(searchText.toLowerCase())) : items;
  
  const filteredData = filteredBySearch(sortedData(allData));

  const data = useMemo(() => paginate(filteredData, currentPage), [currentPage, filteredData, sorting]);


  return (
    <MDBCard narrow className='pb-3'>
      <MDBCardBody>
        <SearchInput onInputChange={value => {
          setCurrentPage(1);
          setSearchTest(value);
        }} />
        <S.Container>
          <MDBTable responsive bordered>
            <MDBTableHead color="pink" textWhite>
              <tr sorting={sorting}>
                {headers.map(h => <S.Th style={{ width: h === "Email" ? '300px' : 'auto'}} withSorting={h !== 'ID'} direction={sorting.direction} isSorting={sorting.field === h} onClick={() => onSortClick(h)}>{h}</S.Th>)}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {!loading ? data.map((row) => <TableRow key={row.profileid} choosedSiteId={choosedSiteId} row={row} />) : <tr><td colSpan={8}><MDBSpinner /></td></tr>}
            </MDBTableBody>
           <S.PagingContainer>
              <MDBIcon onClick={() => setCurrentPage(currentPage - 1)} style={{ cursor: 'pointer', opacity: currentPage > 1 ? 1 : 0 }} icon="chevron-left" />
              <input onChange={e => setCurrentPage(Number(e.target.value))} style={{ width: 100, textAlign: 'center' }} value={currentPage} type="number" placeholder="Search" className="form-control form-control-sm ml-0 my-1" />
              <MDBIcon onClick={() => setCurrentPage(currentPage + 1)} style={{ cursor: 'pointer', opacity: currentPage < totalPages ? 1 :0 }} icon="chevron-right" />
              {totalPages} pages
           </S.PagingContainer>
          </MDBTable>
        </S.Container>
      </MDBCardBody>
    </MDBCard>
  )
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(MegaChatTable);