import React from 'react';
import { MDBContainer } from 'mdbreact';

import OptimizationTable from './components/OptimizationTable';


class OptimizationPage extends React.Component  {

  render() {
    return (
        <MDBContainer fluid>
          <OptimizationTable />
        </MDBContainer>
    );
  }
}

export default OptimizationPage;