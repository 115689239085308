import React from 'react';
import { MDBContainer } from 'mdbreact';

import PartnerWebTable from './components/PartnerWebTable';

import * as S from './styled';

class PartnerWebPage extends React.Component  {
  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <PartnerWebTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default PartnerWebPage;