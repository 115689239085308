/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBIcon,
  MDBCollapseHeader,
  MDBCollapse,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSpinner,
  MDBCardText,
} from "mdbreact";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";

import DateInput from "../../components/DateInput";

import http from "../../services/http";
import { Container } from "./styled";
// import { getRates } from "../../constants";
import { rub_to_usd, usd_to_eur } from "../../constants";
import useDateScrollHeader from "../../hooks/useDateScrollHeader";

const MonthlyReportPage = () => {
  const [collapseID, setCollapseID] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState({
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  useDateScrollHeader(
    {
      date: `${moment(dates.from).format("DD-MM-YYYY")} - ${moment(
        dates.to
      ).format("DD-MM-YYYY")}`,
    },
    [dates]
  );

  const convertToUsd = ({ actionPayoutSum, partnerDealCurrency }) => {
    // const { rub_to_usd, usd_to_eur } = getRates();
    if (partnerDealCurrency === "Rub") {
      return parseInt(actionPayoutSum * rub_to_usd);
    }
    if (partnerDealCurrency === "Eur") {
      return parseInt(actionPayoutSum / usd_to_eur);
    }
    return parseInt(actionPayoutSum);
  };

  const totalActionSum = _.sumBy(data, convertToUsd);

  const groupedByUser = _.groupBy(data, ({ userEmail }) => userEmail);

  const choosedSiteId = useSelector((state) => state.app.choosedSiteId);

  const loadData = async () => {
    setLoading(true);
    const { data: response } = await http.get("/reports/partner8", {
      params: {
        SiteId: choosedSiteId,
        From: dates.from,
        To: dates.to,
      },
    });
    convertToUsd(response);
    setData(response);
    setCollapseID("");
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [choosedSiteId]);

  const onCollapse = (id) => {
    setCollapseID(collapseID === id ? "" : id);
  };

  const tableRows = [
    {
      label: 'LinkId',
      fieldName: 'partnerLinkUid',
    },
    {
      label: 'LinkName',
      fieldName: 'partnerLinkName',
    },
    {
      label: 'Count',
      fieldName: 'count',
    },
    {
      label: 'Action',
      fieldName: 'actionTypeName',
    },
    {
      label: 'Investment',
      fieldName: 'actionPayoutSum',
    },
    {
      label: 'Currency',
      fieldName: 'partnerDealCurrency',
    },
  ]
  return (
    <MDBContainer fluid>
      <Container>
        <MDBRow className="justify-content-between">
          <DateInput value={dates} onChange={(value) => setDates(value)} loadData={loadData} />
          <MDBCol md="4">
            <MDBCard className="card-block">
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <MDBBtn
                    tag="a"
                    floating
                    size="lg"
                    color="red"
                    className="ml-4"
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon="database" size="2x" />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {totalActionSum} $
                  </h5>
                  <p className="font-small grey-text">Action Payout Sum</p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        {loading && <MDBSpinner />}

        {!loading && !Object.keys(groupedByUser).length && (
          <MDBCardText className="ml-2">No info</MDBCardText>
        )}

        {!loading &&
          !!Object.keys(groupedByUser).length &&
          Object.keys(groupedByUser).map((userEmail) => (
            <MDBCard className="mt-3">
              <MDBCollapseHeader
                tagClassName="d-flex justify-content-between"
                onClick={() => onCollapse(userEmail)}
              >
                <strong>
                  {userEmail} ({_.sumBy(groupedByUser[userEmail], convertToUsd)}{" "}
                  $)
                </strong>
                <MDBIcon
                  icon={collapseID === userEmail ? "angle-up" : "angle-down"}
                />
              </MDBCollapseHeader>
              <MDBCollapse id={userEmail} isOpen={collapseID}>
                <MDBCardBody>
                  <MDBTable>
                    <MDBTableHead>
                      <tr>
                        {tableRows.map(row => (
                          <th>{row.label}</th>
                        ))}
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {groupedByUser[userEmail].map((item) => (
                        <tr>
                          <>
                          {tableRows.map(row => (
                            <td>{item[row.fieldName]}</td>
                          ))}
                          </>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          ))}
      </Container>
    </MDBContainer>
  );
};

export default MonthlyReportPage;
