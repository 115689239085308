import React from "react";

import { linksTableHeaders } from "./constants";

import BaseTable from "../BaseTable";
import { convertCurrencyToSymbol } from "../../utils";
import { RoiCell, TypeCell } from "./styled";

const LinksTable = ({ data = [], onSelectLink, selectedPartner }) => {
  const rowRender = (linkItem) => (
    <tr style={{ backgroundColor: !linkItem.isActive ? '#fadadd' : 'transparent' }}>
      <td
        style={{ cursor: "pointer", fontWeight: 'bold', color: 'blue' }}
        onClick={() => onSelectLink(linkItem.linkId, linkItem.linkUid)}
      >
        <u>
          <strong>
            <div>{selectedPartner}</div>
            <div>
              {linkItem.linkUid}({linkItem.linkName})
            </div>
          </strong>
        </u>
      </td>
      <TypeCell type={linkItem.actionName}>{linkItem.actionName}</TypeCell>
      <td>{parseInt(linkItem.ffd)}</td>
      <td>{linkItem.countAll}</td>
      <td>{linkItem.count}</td>
      <td>{linkItem.coins}</td>
      <td>{linkItem.dailyLimit}</td>
      <td>
        {linkItem.payout} {convertCurrencyToSymbol(linkItem.payoutCurrency)}
      </td>
      <td>
        {linkItem.investment} {convertCurrencyToSymbol(linkItem.payoutCurrency)}
      </td>
      <td>
        {linkItem.turnover} {convertCurrencyToSymbol(linkItem.turnoverCurrency)}
      </td>
      <RoiCell roi={linkItem.roi}>{linkItem.roi}</RoiCell>
    </tr>
  );
  return (
    <BaseTable
      rowKey="linkUid"
      data={data}
      rowRender={rowRender}
      headers={linksTableHeaders}
    />
  );
};

export default LinksTable;
