import React from 'react';
import { MDBContainer } from 'mdbreact';

import ManualActionsTable from './components/ManualActionsTable';

import * as S from './styled';

class ManualActionsPage extends React.Component  {

  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <ManualActionsTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default ManualActionsPage;