import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleHeaderDate } from "../store/app";

const useDateScrollHeader = ({ date }, deps = []) => {
  const dispatch = useDispatch();

  const handleScroll = () => {
    if (window.scrollY === 0) {
      dispatch(toggleHeaderDate(null, false));
    } else {
      dispatch(toggleHeaderDate(date, true));
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      dispatch(toggleHeaderDate(null, false))
      window.removeEventListener("scroll", handleScroll);
    }
  }, [...deps]);

  return null;
};

export default useDateScrollHeader;
