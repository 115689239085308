import React from 'react';
import { MDBContainer} from 'mdbreact';

import Dashboard from '../DashboardPage/components/DashBoard';
import { connect } from 'react-redux';

class MainPage extends React.Component  {
  // componentDidMount() {
  //   const { user } = this.props;
  //   const isAffiliate = user?.role?.includes('Affiliate');
  //   if (isAffiliate) {
  //     return;
  //   }
  //   this.props.history.push('/');
  // }

  render() {
    return (
      <MDBContainer fluid>
        <Dashboard />
      </MDBContainer>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  user: auth.user,
});

export default connect(mapStateToProps, null)(MainPage);