export const ROLES = {
  0: 'Admin',
  1: 'Affiliate',
  2: 'Manager',
  3: 'Partners',
  4: 'HR',
}

const eur_to_eur = 1;
const usd_to_eur = 1;
const rub_to_eur = 0.017;
const rub_to_usd = 0.017;

export {
  eur_to_eur,
  usd_to_eur,
  rub_to_eur,
  rub_to_usd,
}