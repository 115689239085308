import _ from "lodash";

import { usd_to_eur, rub_to_usd } from "../../constants";

const isNumeric = (str) => {
  if (typeof str === "number") return true;
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const sortBy = (data, field, direction) =>
  _.orderBy(
    data,
    (i) => (isNumeric(i[field]) ? Number(i[field]) : i[field]),
    direction
  );

export const countRoi = (
  turnover,
  investment,
  payoutCurrency,
  turnoverCurrency
) => {
  let investmentInUsd = investment;
  let turnOverInUsd = turnover;
  if (turnoverCurrency?.toLowerCase() === "eur") {
    turnOverInUsd = turnover * usd_to_eur;
  }
  if (turnoverCurrency?.toLowerCase() === "rub") {
    turnOverInUsd = turnover * rub_to_usd;
  }
  if (payoutCurrency?.toLowerCase() === "eur") {
    investmentInUsd = investment * usd_to_eur;
  }
  if (payoutCurrency?.toLowerCase() === "rub") {
    investmentInUsd = investment * rub_to_usd;
  }
  return Number((turnOverInUsd / investmentInUsd).toFixed(2));
};

export const convertDataToUsd = (data, valueKey, currencyKey) => {
  return [...data].map((i) => {
    if (i[currencyKey] === "Eur") {
      return {
        ...i,
        [valueKey]: i[valueKey] * usd_to_eur,
      };
    }
    if (i[currencyKey] === "Rub") {
      return {
        ...i,
        [valueKey]: i[valueKey] * rub_to_usd,
      };
    }
    return i;
  });
};

// {
//   investment: "payoutCurrency"
// }
export const countTotals = (data, countPayload) => {
  let totals = {};
  Object.keys(countPayload).map((field) => {
    const currency =
      countPayload[field] === "number" ? "usd" : countPayload[field];
    totals[field] = _.sumBy(
      convertDataToUsd(data, field, currency),
      (i) => i[field]
    ).toFixed(2);
  });
  return totals;
};


export const countAverageRoi = (data = []) => {
  const turnoversInUsd = convertDataToUsd(
    data,
    "turnover",
    "turnoverCurrency"
  ).map((i) => i.turnover);
  const investmentsInUsd = convertDataToUsd(
    data,
    "investment",
    "payoutCurrency"
  ).map((i) => i.investment);
  const turnoverSum = _.sum(turnoversInUsd);
  const investmentSum = _.sum(investmentsInUsd);
  const roi = countRoi(turnoverSum, investmentSum, "usd", "usd");
  return roi;
};

export const convertCurrencyToSymbol = (currency = '') => {
  if (currency?.toLowerCase() === "usd") {
    return '$';
  }
  if (currency?.toLowerCase() === "eur") {
    return '€';
  }
  if (currency?.toLowerCase() === "rub") {
    return '₽';
  }
  return '$';
}