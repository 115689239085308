import styled from 'styled-components';

export const Container = styled.div`
  .table {
    margin-bottom: 0;
  }
  td {
    vertical-align: middle;
    & a {
      color: #007bff;
      :hover {
        opacity: 0.5;
      }
    }
  }
`;


export const Th = styled.th`
  position: relative;
  padding-right: 30px;
  ::after {
    right: 1em;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0de";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'desc') ? 1 : 0.4}
  }
  ::before {
    right: 16px;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0dd";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'asc') ? 1 : 0.4}
  }
`;