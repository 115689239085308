import React from 'react';
import { MDBContainer } from 'mdbreact';

import Dashboard from './components/Dashboard';

class ReportPage extends React.Component  {

  render() {
    return (
      <MDBContainer fluid id='v6' className='mb-5'>
        <Dashboard />
      </MDBContainer>
    );
  }
}

export default ReportPage;