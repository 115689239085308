import styled from 'styled-components';

export const Container = styled.div`
  .nav-tabs {
    border-width: 0;
  }
  .nav-link {
    border-color: #1266f1;
    border: solid transparent;
    border-width: 0 0 2px;
    border-radius: 0;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 500;
    font-size: 12px;
    padding: 17px 29px 16px;
    &.active {
      border-bottom: 2px solid #1266f1;
      color: #1266f1;
    }
  }
`;

export const Th = styled.th`
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  ::after {
    right: 0em;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0de";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'desc') ? 1 : 0.4}
  }
  ::before {
    right: 0px;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0dd";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'asc') ? 1 : 0.4}
  }
`;

export const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid #4285f4;
  margin-right: 5px;
  background-color: ${({ checked }) => checked ? '#4285f4' : 'transparent'};
  cursor: pointer;
`;
