import React from 'react';
import { MDBContainer } from 'mdbreact';

import PartnerDealsTable from './components/PartnerDealsTable';

import * as S from './styled';

class PartnerDealsPage extends React.Component  {
  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <PartnerDealsTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default PartnerDealsPage;