/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";

import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBCardBody,
  MDBDataTable,
  MDBSpinner,
} from "mdbreact";

import http from "../../../../services/http/affiliates";
import { connect } from "react-redux";
import { siteUrlsConfig } from "../../../../configs";
import useDateScrollHeader from "../../../../hooks/useDateScrollHeader";

const AffBalanceTable = ({ choosedSiteId }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [form, setForm] = React.useState({
    From: moment().format("YYYY-MM-01"),
    To: moment().format("YYYY-MM-DD"),
    email: "",
  });

  useDateScrollHeader(
    {
      date: `${moment(form.From).format("DD-MM-YYYY")} - ${moment(
        form.To
      ).format("DD-MM-YYYY")}`,
    },
    [form]
  );

  React.useEffect(() => {
    onClick();
  }, [choosedSiteId]);

  const getTypeName = (typeid) => {
    let text = "";
    switch (typeid) {
      case 1:
        text = "Chat buy";
        break;
      case 2:
        text = "Bonus";
        break;
      case 3:
        text = "Fine";
        break;
      case 4:
        text = "Done";
        break;
      case 5:
        text = "Withdrawal Rejected";
        break;
      default:
        text = "";
    }
    return text;
  };

  const getPaymentMaleIdName = (payment, maleid, typeid) => {
    if (typeid === 2 || typeid === 3) return "Kosta";
    if (typeid === 1) return "profile id " + maleid;
    var text = "";
    switch (payment) {
      case 1:
        text = "BankCardRus";
        break;
      case 2:
        text = "BankCardUkr";
        break;
      case 3:
        text = "WebMoney";
        break;
      case 4:
        text = "Qiwi";
        break;
      default:
        text = "Undefined";
    }
    return text;
  };

  const onClick = async () => {
    setLoading(true);
    try {
      const { data } = await http.post(
        `/api/${siteUrlsConfig[choosedSiteId]}/AffiliateHistoryBalance`,
        {
          // param: "albk.106@bk.ru",
          param: form.email,
          start: form.From,
          end: form.To,
        }
      );

      const formattedData = data.map((item) => ({
        ...item,
        type: getTypeName(item.typeId),
        payment: getPaymentMaleIdName(
          item.paymentMethod,
          item.maleProfileId,
          item.typeId
        ),
      }));

      setData({
        columns: [
          {
            label: "Id",
            field: "id",
            width: 150,
          },
          {
            label: "Type",
            field: "type",
            width: 270,
          },
          {
            label: "Amount",
            field: "amount",
            width: 200,
          },
          {
            label: "Balance",
            field: "balance",
            width: 100,
          },
          {
            label: "Payment/MaleId",
            field: "payment",
            width: 100,
          },
          {
            label: "Date",
            field: "creationDate",
            width: 100,
          },
        ],
        rows: [...formattedData],
      });
    } catch (err) {}
    setLoading(false);
  };

  return (
    <>
      <MDBCard className="p-3 mb-5">
        <MDBRow center>
          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="From date"
              type="date"
              group
              value={form.From}
              onChange={(e) =>
                setForm({
                  ...form,
                  From: e.target.value,
                })
              }
            />
          </MDBCol>

          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="To date"
              group
              type="date"
              value={form.To}
              onChange={(e) => {
                console.log(e);
                setForm({
                  ...form,
                  To: e.target.value,
                });
              }}
            />
          </MDBCol>
          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="Email"
              group
              value={form.email}
              onChange={(e) => {
                console.log(e);
                setForm({
                  ...form,
                  email: e.target.value,
                });
              }}
            />
          </MDBCol>

          <MDBCol className="btn-container" lg="3" md="6">
            <MDBBtn onClick={onClick}>Show</MDBBtn>
          </MDBCol>
          <MDBCol className="btn-container" lg="3" md="6"></MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCard narrow className="pb-3">
        <MDBCardBody>
          {!loading ? (
            <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={data}
              infoLabel={["Showing", "to", "of", "rows"]}
              entries={50}
              info={true}
            />
          ) : (
            <MDBSpinner className="spinner-container" />
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(AffBalanceTable);
