export const LINE_GRAPH_OPTIONS = {
  fill: true,
  lineTension: 0.3,
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#1266f1",
  pointBackgroundColor: "#1266f1",
  pointBorderWidth: 5,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "rgb(0, 0, 0)",
  pointHoverBorderColor: "rgba(220, 220, 220,1)",
  pointHoverBorderWidth: 6,
  pointRadius: 2,
  pointHitRadius: 10,
};

export const options = { 
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          borderDash: [3, 2],
            color: 'rgba(0, 0, 0, 0.0)'
          },
        display: true,
      }
    ],
    yAxes: [
      {
        gridLines: {
        borderDash: [3, 2],
        },
        ticks: {
          callback: (val) => val === 0 ? 0 : `${val / 1000}K`,
          beginAtZero: true
        }
      }
    ]
  }
};