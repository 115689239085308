import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class AlertModal extends Component {

onDelete = () => {
  this.props.onDelete();
  this.props.onClose();
}

render() {
  return (
    <MDBModal isOpen={this.props.visible} toggle={this.props.onClose}>
      <MDBModalHeader toggle={this.props.onClose}>Are you sure want to delete?</MDBModalHeader>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={this.props.onClose}>Close</MDBBtn>
        <MDBBtn color="primary" onClick={this.onDelete}>Yes</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    );
  }
}

export default AlertModal;