import React from "react";
import { withSnackbar } from "react-simple-snackbar";

import http from "../../services/http";

import UsersTable from "./components/UsersTable";

class UsersPage extends React.Component {
  state = {
    users: [],
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const { data } = await http.get("/users");
    this.setState({ users: data, loading: false });
  }

  deleteUser = async (userId) => {
    const { openSnackbar } = this.props;
    await http.delete(`/users/${userId}`);
    const { data } = await http.get("/users");
    openSnackbar("User deleted", 3000);
    this.setState({ users: data });
  };

  render() {
    const { loading } = this.state;
    return (
      <UsersTable
        deleteUser={this.deleteUser}
        loading={loading}
        users={this.state.users}
      />
    );
  }
}

const options = {
  style: {
    backgroundColor: "green",
    color: "white",
  },
};

export default withSnackbar(UsersPage, options);
