/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBCardBody,
  MDBTable,
  MDBSpinner,
  MDBCardText,
} from "mdbreact";

import { connect } from "react-redux";

import { siteUrlsConfig } from "../../configs";
import http from "../../services/http/affiliates";

const AffSalesTable = ({ choosedSiteId }) => {
  const [inputValue, setInputValue] = useState("");

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (inputValue) {
        const { data } = await http.post(
          `/api/${siteUrlsConfig[choosedSiteId]}/AffiliateSalesByDate`,
          {
            param: inputValue,
          }
        );
        setItems(data);
      } else {
        setItems([]);
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <>
      <MDBCard className="p-3 mb-5">
        <MDBCardText>Aff sales</MDBCardText>
        <MDBRow>
          <MDBCol lg="3" md="6">
            <MDBInput
              label="Email"
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
          </MDBCol>

          <MDBCol className="btn-container" lg="3" md="6">
            <MDBBtn onClick={fetchData}>Show</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      {items.length ? (
        <MDBCard narrow className="pb-3">
          <MDBCardBody>
            {!loading ? (
              <MDBTable responsive>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Chats</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((row) => (
                    <>
                      <tr key={row.day}>
                        <td>{row.day}</td>
                        <td>{row.chats}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </MDBTable>
            ) : (
              <MDBSpinner className="spin" />
            )}
          </MDBCardBody>
        </MDBCard>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(AffSalesTable);
