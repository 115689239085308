import React from 'react';
import { MDBContainer } from 'mdbreact';

import PaymentsTable from './components/PaymentsTable';


class PaymentsPage extends React.Component  {

  render() {
    return (
        <MDBContainer fluid>
          <PaymentsTable />
        </MDBContainer>
    );
  }
}

export default PaymentsPage;