import { MDBBtn } from "mdbreact";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
    render() {
        return (
            <ExcelFile filename="AffLinks" element={<MDBBtn>Download</MDBBtn>}>
                <ExcelSheet data={this.props.data} name="AffLinks">
                    <ExcelColumn label="Id" value="id"/>
                    <ExcelColumn label="Date" value="date"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download;