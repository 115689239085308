import React from 'react';
import { MDBContainer } from 'mdbreact';

import Stats from './components/Stats';

import * as S from './styled';

class StatsPage extends React.Component  {
  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <Stats />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default StatsPage;