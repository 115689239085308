import React, { useEffect, useMemo, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdbreact";
import Select from "react-select";

const values = [">", "<", "="];

const ConditionFilter = ({ onChange, label }) => {
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState(null);

  const finalValue = useMemo(() => {
    if (value && operator?.value) {
      return `${operator.value === "=" ? "" : operator.value}${value}`;
    }
    return null;
  }, [operator, value]);

  useEffect(() => {
    onChange(finalValue);
  }, [finalValue]);

  return (
    <MDBRow>
      <MDBCol md="5">
        <span style={{ whiteSpace: 'nowrap' }}>{label} compare</span>
        <Select
          isSearchable={false}
          styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
          placeholder={""}
          options={values.map(v => ({ label: v, value: v }))}
          value={operator}
          onChange={(val) => setOperator(val)}
          isClearable
        />
      </MDBCol>
      <MDBCol md="7">
        <MDBInput onChange={(e) => setValue(e.target.value)} label={label} />
        </MDBCol>
    </MDBRow>
  );
};

export default ConditionFilter;
