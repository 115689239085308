import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';

import TokenStorage from '../storage/token';
import RefreshTokenStorage from '../storage/refreshToken';
import { baseURL } from '../http';
import { useSelector } from 'react-redux';

const SignalR = ({onNewMessage, allSitesConnected = false}) => {
    const [ connection, setConnection ] = useState(null);

    const choosedSiteId = useSelector(state => state.app.choosedSiteId);
  
    const [isRetried, setRetried] = useState(false);

    const refreshToken = (token) => {
        const payload = {
          refreshToken: token
        }
        const headers = {
          'Content-Type': 'application/json'
        }
          return new Promise((resolve, reject) => {
            return axios.post(`${baseURL}/auth/refresh`, payload, { headers: headers }).then(response => {
              resolve(response)
            }).catch((error) => {
              reject(error)
            })
          })
          
      }
      
    const setupSignalRConnect = (token) => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseURL}/hubs/actions`, { accessTokenFactory: () => token })
            .configureLogging(2)
            .build();
        setConnection(newConnection);
    }

    useEffect(() => {
        const token = TokenStorage.get();
        setupSignalRConnect(token);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(async result => {
                    setRetried(false);
                    if (!allSitesConnected) {
                      connection.invoke("Subscribe", choosedSiteId);
                    } else {
                      connection.invoke("Subscribe", 3);
                      connection.invoke("Subscribe", 8);
                    }
                    connection.on('Action', message => {
                        onNewMessage(message);
                      });
                })
                .catch(async e => {
                    if (!isRetried && e?.toString().includes('Unauthorized')) {
                        setRetried(true);
                        const refresh = RefreshTokenStorage.get();
                        const response = await refreshToken(refresh);
                        TokenStorage.save(response.data.accessToken);
                        setupSignalRConnect(response.data.accessToken)
                    }
                });
        }
        return () => {
            if (connection) {
                connection.stop();
            }
        }
    }, [connection]);


    const mounted = useRef();
    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
      } else {
        if (!allSitesConnected) {
          let unubscribeSiteId = choosedSiteId === 8 ? 3 : 8;
          connection.invoke("Unsubscribe", unubscribeSiteId)
          connection.invoke("Subscribe", choosedSiteId);
        }
      }
    }, [choosedSiteId]);

    return (
        <div>

        </div>
    );
};

export default SignalR;