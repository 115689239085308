import axios from 'axios';
import TokenStorage from '../storage/token';
import RefreshTokenStorage from '../storage/refreshToken';

const baseURL = 'https://newpashka.azurewebsites.net'

const http = axios.create({
  withCredentials: false,
  baseURL,
  headers: {'Content-Type': 'application/json'},
});

const refreshToken = (token) => {
  const payload = {
    refreshToken: token
  }
  const headers = {
    'Content-Type': 'application/json'
  }
    return new Promise((resolve, reject) => {
      return axios.post(`${baseURL}/auth/refresh`, payload, { headers: headers }).then(response => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
    
}

http.interceptors.request.use(
  async request => {
    const token = TokenStorage.get();
    const newRequest = {...request};
    if (token) {
      newRequest.headers.Authorization = `Bearer ${token}`;
    }
    return newRequest;
  },
  error => {
    return Promise.reject(error)
  },
);

http.interceptors.response.use(undefined, err => {
  const error = err.response;
  if (error?.status===401 && error?.config && 
  !error?.config.__isRetryRequest) {
    const refresh = RefreshTokenStorage.get();
  return refreshToken(refresh).then(response => {
   error.config.__isRetryRequest = true;
   TokenStorage.save(response.data.accessToken);
   error.config.headers.Authorization = `Bearer ${response.data.accessToken}`;
   return http(error.config);
  });
 } 
})

export default http;
