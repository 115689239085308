export const getExpTimeFromToken = (token) =>  {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const parsed = JSON.parse(jsonPayload)
  return parsed.exp;
};

export const getRoleFromToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const parsed = JSON.parse(jsonPayload)
  const findRole = Object.keys(parsed).find(key => key.includes('/role'));
  if (typeof parsed[findRole] === 'object') {
    return parsed[findRole][0];
  } else {
    return parsed[findRole]
  }
};

export const getEmailFromToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const parsed = JSON.parse(jsonPayload)
  const findName = Object.keys(parsed).find(key => key.includes('/name'));
  return parsed[findName]
};

export const getIdFromToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const parsed = JSON.parse(jsonPayload)
  const findId = Object.keys(parsed).find(key => key.includes('/nameidentifier'));
  return parsed[findId];
};
