/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MDBInput,
  MDBSpinner,
  MDBCardBody,
  MDBBtn,
  MDBBadge,
  MDBCard,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { useSelector } from "react-redux";

import * as S from "./styled";
import http from "../../../services/http";

const dropdownOptions = [
  "Utm_source",
  "Utm_campaign",
  "Utm_term",
  "Utm_content",
  "Linkid",
  "Web_id",
  "Sub_id",
];

const dropdownOptions2 = [
  "None",
  "Utm_source",
  "Utm_campaign",
  "Utm_term",
  "Utm_content",
  "Linkid",
  "Web_id",
  "Sub_id",
];

const OptimizationTable = () => {
  const choosedSiteId = useSelector((state) => state.app.choosedSiteId);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await http.get("/reports/report12", {
        params: {
          SiteId: choosedSiteId,
          Name: form.Name,
          Value: form.Value,
          Param1: form.Param1,
          Param2: isSecondParamClear ? "" : form.Param2,
          Start: form.Start,
          End: form.End,
        },
      });
      if (response?.data) {
        setData(response.data);
      }
    } catch (err) {}
    setLoading(false);
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    field: "",
    direction: "asc",
  });

  const [form, setForm] = useState({
    Name: "",
    Value: "",
    Param1: "",
    Param2: "None",
    Cost: "",
    Start: "",
    End: "",
  });

  const headers = useMemo(
    () => [
      {
        label: form.Param1,
        id: "param1",
      },
      {
        label: form.Param2,
        id: "param2",
      },
      {
        label: "Fill Profiles",
        id: "fillProfiles",
      },
      {
        label: "Confirmed",
        id: "emailConfirmed",
      },
      {
        label: "Purchases",
        id: "purchases",
      },
      {
        label: "Money (₽)",
        id: "moneyAmount",
      },
      {
        label: "ROI",
        id: "roi",
      },
    ],
    [form.Param1, form.Param2]
  );

  const onFormChange = (value, type) => {
    if (type === "Param2" || type === "Param1") {
      setData([]);
    }
    setForm({
      ...form,
      [type]: value,
    });
  };

  const isSecondParamClear = form.Param2 === "None" || !form.Param2;

  const onSortClick = (header) => {
    if (header === "ID") return;
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const isString = (value) => typeof value === "string";

  const sortedData = () => {
    if (!data.length) {
      return [];
    }

    const field = sorting.field;

    let sorted = [];
    if (field === "roi") {
      sorted = data.sort((a, b) => {
        const aValue = calculateRoi(a.fillProfiles, a.moneyAmount);
        const bValue = calculateRoi(b.fillProfiles, b.moneyAmount);

        return sorting.direction === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });
      return sorted;
    }

    sorted = data.sort((a, b) => {
      const aValue = isString(a[field])
        ? a[field].includes("$")
          ? Number(a[field].replace(/\$/g, ""))
          : a[field]
        : a[field];
      const bValue = isString(b[field])
        ? b[field].includes("$")
          ? Number(b[field].replace(/\$/g, ""))
          : b[field]
        : b[field];

      return sorting.direction === "asc"
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    });
    return sorted;
  };

  const calculateRoi = useCallback(
    (fillProfiles, moneyAmount) => {
      const counted = moneyAmount / (fillProfiles * form.Cost);
      return isNaN(counted) ? -1 : counted;
    },
    [form.Cost]
  );

  const filteredBySearch = (items) => (false ? [] : items);

  const isFormEmpty =
    !form.Name || !form.Value || !form.Param1 || !form.Start || !form.End;

  useEffect(() => {
    if (!isFormEmpty) {
      loadData();
    }
  }, [choosedSiteId]);

  return (
    <>
      <MDBCard className="p-3 mb-5">
        <MDBRow>
          <MDBCol lg="5">
            <MDBRow center>
              <MDBBadge>Filter</MDBBadge>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="6">
                <MDBSelect
                  getTextContent={(val) => onFormChange(val, "Name")}
                  className="colorful-select dropdown-primary mx-2"
                >
                  <MDBSelectInput selected={form.Name} />
                  <MDBSelectOptions>
                    {dropdownOptions.map((param) => (
                      <MDBSelectOption key={param} value={param}>
                        {param}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
              <MDBCol lg="6">
                <MDBInput
                  required
                  label="Parameter value"
                  group
                  value={form.Value}
                  onChange={(e) => onFormChange(e.target.value, "Value")}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg="5">
            <MDBRow center>
              <MDBBadge>Break Down</MDBBadge>
            </MDBRow>
            <MDBRow>
              <MDBCol lg="6">
                <MDBSelect
                  getTextContent={(val) => onFormChange(val, "Param1")}
                  className="colorful-select dropdown-primary mx-2"
                >
                  <MDBSelectInput selected={form.Param1} />
                  <MDBSelectOptions>
                    {dropdownOptions.map((param) => (
                      <MDBSelectOption key={param} value={param}>
                        {param}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
              <MDBCol lg="6">
                <MDBSelect
                  getTextContent={(val) => onFormChange(val, "Param2")}
                  className="colorful-select dropdown-primary mx-2"
                >
                  <MDBSelectInput selected={form.Param2} />
                  <MDBSelectOptions>
                    {dropdownOptions2.map((param) => (
                      <MDBSelectOption key={param} value={param}>
                        {param}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg="2">
            <MDBRow center>
              <MDBBadge>ROI (₽)</MDBBadge>
            </MDBRow>
            <MDBRow>
              <MDBInput
                required
                label="Fillprofile Cost"
                group
                type="number"
                value={form.Cost}
                onChange={(e) => onFormChange(e.target.value, "Cost")}
              />
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="From date"
              type="date"
              group
              onChange={(e) => onFormChange(e.target.value, "Start")}
            />
          </MDBCol>

          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="To date"
              group
              type="date"
              onChange={(e) => onFormChange(e.target.value, "End")}
            />
          </MDBCol>

          <MDBCol className="btn-container" lg="3" md="6">
            <MDBBtn disabled={isFormEmpty} onClick={loadData}>
              Show
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      {loading && <MDBSpinner />}
      {data.length && !loading ? (
        <MDBCard narrow className="pb-3">
          <MDBCardBody>
            <MDBTable responsive bordered>
              <MDBTableHead color="pink" textWhite>
                <tr sorting={sorting}>
                  {headers
                    .filter((h) => h.label && h.label !== "None")
                    .map((h) => (
                      <S.Th
                        withSorting={h !== "ID"}
                        direction={sorting.direction}
                        isSorting={sorting.field === h.id}
                        onClick={() => onSortClick(h.id)}
                      >
                        {h.label}
                      </S.Th>
                    ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {filteredBySearch(sortedData()).map((row, index) => (
                  <tr key={`${row.param1}-${index}`}>
                    <td>{row.param1}</td>
                    {!isSecondParamClear && <td>{row.param2}</td>}
                    <td>{row.fillProfiles}</td>
                    <td>{row.emailConfirmed}</td>
                    <td>{row.purchases}</td>
                    <td>{row.moneyAmount}</td>
                    {!form.Cost ? (
                      <td></td>
                    ) : (
                      <Fragment>
                        <td
                          style={{
                            backgroundColor:
                              calculateRoi(row.fillProfiles, row.moneyAmount) <
                              1
                                ? "red"
                                : "green",
                            color: "white",
                          }}
                        >
                          {calculateRoi(
                            row.fillProfiles,
                            row.moneyAmount
                          ).toFixed(1)}
                        </td>
                      </Fragment>
                    )}
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      ) : null}
    </>
  );
};

export default OptimizationTable;
