import React from 'react';
import {MDBInput, MDBIcon, MDBBtn, MDBSpinner} from 'mdbreact';

import http from '../../../../services/http/affiliates';
import { siteUrlsConfig } from '../../../../configs';
import { useSelector } from 'react-redux';


const TableRow = ({row, choosedSiteId }) => {
  const [isMessagesShow, setMessagesShow] = React.useState(false);
  const [isEditable, setEditable] = React.useState(false);

  const [enabled, setEnabled] = React.useState(row.enabled);
  const [limit, setLimit] = React.useState(row.limit);

  const [m1, setM1] = React.useState(row.m1);
  const [m2, setM2] = React.useState(row.m2);

  const onMessageClick = (e) => {
    if (e.target.parentElement.id === "limit-editable" || e.target.parentElement.className === "md-form") return;
    setMessagesShow(!isMessagesShow);
  }

  const onSave = async () => {
    setEditable(false);
    await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/SaveMessagesForAff`, {
      "userid": row.userid,
      "email": row.email,
      "profileid": row.profileid,
      "enabled": enabled,
      "done": row.done,
      "addmethod": row.addmethod,
      "m1": m1,
      "m2": m2,
      "limit": limit,
    })
  }

  const onSetEnabled = async () => {
    setEnabled(!enabled)
    await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/ToggleMegachatForAff/${row.profileid}`)
  };

  const renderOnlineStatus = (item) => {
    return <div style={{width: 20, height: 20, borderRadius: 20, backgroundColor: item.online === 'Yes' ? '#33ff00' : '#d1c9c9'}}></div>
  }

  const [isLimitEditable, setLimitEditable] = React.useState(false);
  const [limitLoading, setLimitLoading] = React.useState(false);

  const saveLimit = async () => {
    setLimitLoading(true);
    try {
      await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/UpdateActionsLimit?`, null, {
        params: {
          profileid: row.profileid,
          limit,
        }
      })
    } catch(err) {
      setLimit(row.limit)
    }
    setLimitLoading(false);
    setLimitEditable(false);
  }

  const onCancelEditLimit = () => {
    setLimitEditable(false);
    setLimit(row.limit);
  };

  const renderLimitField = () => {
    if (!isLimitEditable) {
      return (
        <>
         {limit} <MDBIcon className="ml-1" icon="pen" onClick={() => setLimitEditable(true)} />
        </>
      )
    } else {
      return (
        <>
         <MDBInput disabled={limitLoading} type="number" value={limit} onChange={(e) => setLimit(e.target.value)} />
         <MDBBtn color="success" disabled={limitLoading} size="sm" onClick={saveLimit}>Save</MDBBtn>
         <MDBBtn size="sm" onClick={onCancelEditLimit}>Cancel</MDBBtn>
        </>
      )
    }
  }
  return (
    <>
      <tr style={{cursor: 'pointer'}} onClick={onMessageClick}>
        <td width="10">{row.ind}</td>
        <td>{row.email}</td>
        <td>{row.profileid}</td>
        <td><MDBInput onChange={onSetEnabled} checked={Boolean(enabled)} type="checkbox" id={`checkbox${row.profileid}`} /></td>
        <td>
          {!m1 ? <span><MDBIcon icon="times" /></span> : <span><MDBIcon icon="envelope" /></span>}
          {!m2 ? <span style={{marginLeft: 10}}><MDBIcon icon="times" /></span> : <span style={{marginLeft: 10, cursor: 'pointer'}}><MDBIcon icon="envelope" /></span>}
        </td>
        <td id="limit-editable">
          {renderLimitField()}
        </td>
        <td>{row.addmethod}</td>
        <td>
          {renderOnlineStatus(row)}
        </td>
      </tr>
      {isMessagesShow && <tr colSpan={5}>
        <td colSpan={3}>{!isEditable ? m1 :
         <textarea
          className="form-control form-control-sm"
          value={m1}
          onChange={e => setM1(e.target.value)}
        />}
        </td>
        <td colSpan={4}>{!isEditable ? m2 :
         <textarea
          className="form-control form-control-sm"
          value={m2}
          onChange={e => setM2(e.target.value)}
        />}
        </td>
        <td colSpan={1}>{!isEditable ? <MDBBtn onClick={() => setEditable(true)} size="sm">Edit</MDBBtn> : <MDBBtn onClick={onSave} size="sm">Save</MDBBtn>}</td>
      </tr>}
    </>
  )
};

export default TableRow;