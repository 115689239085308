import React, { useState } from 'react';

import { MDBCard, MDBCol, MDBRow, MDBCardBody, MDBSpinner, MDBDataTable, MDBInput, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

import moment from 'moment';
import http from '../../../../services/http/affiliates';
import { connect } from 'react-redux';
import { siteUrlsConfig, sitesConfig } from '../../../../configs';

import * as S from './styled';

const headers = [
  'Link',
  'Uses',
  'Buys',
  'Balance',
  'Date',
];

const PaymentsTable = ({choosedSiteId}) => {

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [data, setData] = useState([]);
  const [sorting, setSorting] = React.useState({
    field: 'date',
    direction: 'asc',
  });
  const [maleId, setMaleId] = useState('');

  const getData = async () => {
    setNoDataFound(false);
    setLoading(true);
    const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/GetMalePayments/${maleId}`);
    if (!data.length) {
      setNoDataFound(true);
    }
    setData(data)
    setLoading(false);
    setLoaded(true);
  }

  const isString = value => typeof value === 'string';

  const sortedData = () => {
    if (!data.length) {
      return [];
    }

    const field = sorting.field.toLocaleLowerCase() !== 'date' ? 'chats' : 'date';

    return data.sort((a, b) => {

      const aValue = isString(a[field])
        ? a[field].includes('$')
          ? Number(a[field].replace(/\$/g, ''))
          : a[field]
        : a[field];
      const bValue = isString(b[field])
        ? b[field].includes('$')
          ? Number(b[field].replace(/\$/g, ''))
          : b[field]
        : b[field];

      return sorting.direction === 'asc' ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
    });
  }

  const onSortClick = (header) => {
    if (header === 'ID') return;
    setSorting({
      field: header,
      direction: sorting.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const renderMainTable = () => {
    let finallArr = [];
  
    sortedData().map(i => ({...i, balance: 0})).reduce((previousValue, currentValue, currentIndex) => {
      if (currentIndex === 0) {
        currentValue.balance = currentValue.chats;
      } else {
        currentValue.balance = previousValue?.balance + currentValue.chats;
      }
      finallArr.push(currentValue);
      return currentValue;
    }, 0);
  
    if (loading) {
      return (
        <MDBSpinner className="spin" />
      )
    }
    if (!loaded) {
      return null
    }
    if (loaded && noDataFound) {
      return <span>No Data Found</span>
    }
    return (
      <MDBTable striped responsive bordered>
        <MDBTableHead color="pink" textWhite>
          <tr sorting={sorting}>
            {headers.map(h => (
              <S.Th
                withSorting={false}
                direction={sorting.direction}
                // onClick={() => onSortClick(h)}
                isSorting={sorting.field === h}>
                  {h}
              </S.Th>
            ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {finallArr.map(row => (
            <tr>
              <td>{row.fid !== -1 ? <a target="_blank" href={`${sitesConfig[choosedSiteId]}${row.fid}`}>{sitesConfig[choosedSiteId]}{row.fid}</a> : ''}</td>
              <td>{row.chats <= 0 ? row.chats: ''}</td>
              <td>{row.chats > 0 ? row.chats: ''}</td>
              <td>
                {row.balance}
              </td>
              <td>{moment(row.date).format('MM/DD/YYYY HH:mm:ss')}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    )
  }
  return (
    <S.Container>
      <MDBCard className='p-3 mb-5'>
        <MDBRow>

          <MDBCol className="btn-container" lg='3' md='6'>
            <MDBInput value={maleId} onChange={e => setMaleId(e.target.value)} label="Enter Male Id or Email" />
            <MDBBtn disabled={!maleId} onClick={getData}>Show</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCard narrow className='pb-3'>
          <MDBCardBody>
            {renderMainTable()}
          </MDBCardBody>
        </MDBCard>
      
    </S.Container>
  )
}

const mapStateToProps = ({app}) => ({
  choosedSiteId: app.choosedSiteId,
});

export default connect(mapStateToProps, null)(PaymentsTable);