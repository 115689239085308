import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import { MDBRow, MDBCol, MDBCard, MDBSpinner, MDBBtn, MDBIcon, MDBCardHeader, MDBCardBody } from 'mdbreact';
import { Line } from 'react-chartjs-2';

import http from '../../../../services/http';

import * as S from './styled';

const GRAPH_OPTIONS = {
  fill: true,
  lineTension: 0.3,
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "rgb(205, 130,1 58)",
  pointBackgroundColor: "rgb(255, 255, 255)",
  pointBorderWidth: 10,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "rgb(0, 0, 0)",
  pointHoverBorderColor: "rgba(220, 220, 220,1)",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

class Dashboard extends React.Component {
  state = {
    loading: false,
    todayPurchase: 0,
    todayPurchaseLoading: false,
    todayFillProfile: 0,
    todayFillProfileLoading: false,
    yesterdayPurchase: 0,
    yesterdayPurchaseLoading: false,
    yesterdayFillProfile: 0,
    yesterdayFillProfileLoading: false,
    yesterdayTotalAmount: 0,
    yesterdayTotalAmountLoading: false,
    todayTotalAmount: 0,
    todayTotalAmountLoading: false,
    todayConfirmedEmails: 0,
    todayConfirmedEmailsLoading: false,
    yesterdayConfirmedEmails: 0,
  };


  async componentDidMount() {
    await this.fetchInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.choosedSiteId !== prevProps.choosedSiteId) {
      this.fetchInfo();
    }
  }

  fetchInfo = async () => {
    try {
      const {choosedSiteId} = this.props;
      this.setState({loading: true, todayPurchaseLoading: true, todayFillProfileLoading: true, yesterdayPurchaseLoading: true, yesterdayFillProfileLoading: true, yesterdayTotalAmountLoading: true, todayConfirmedEmailsLoading: true})

      //FILL PROFILE FIRST BLOCK
      const {data: yesterdayFillProfile} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'FillProfile',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });
      this.setState({yesterdayFillProfile: yesterdayFillProfile.count, yesterdayFillProfileLoading: false});

      const {data: todayFillProfile} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'FillProfile',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      this.setState({todayFillProfile: todayFillProfile.count, todayFillProfileLoading: false});
      
      // EMAIL CONFIRMED SECOND BLOCK
      const {data: todayConfirmedEmails} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'emailconfirmed',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      const {data: yesterdayConfirmedEmails} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'emailconfirmed',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });

      console.log('todayConfirmedEmails', todayConfirmedEmails, yesterdayConfirmedEmails);
      this.setState({todayConfirmedEmails: todayConfirmedEmails.count, yesterdayConfirmedEmails: yesterdayConfirmedEmails.count, todayConfirmedEmailsLoading: false})
  
      // PURCHASE THIRD BLOCK
      const {data: todayPurchase} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'Purchase',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      this.setState({todayPurchase: todayPurchase.count, todayPurchaseLoading: false});
    
      const {data: yesterdayPurchase} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'Purchase',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });
      this.setState({yesterdayPurchase: yesterdayPurchase.count, yesterdayPurchaseLoading: false});
  
    
      const [{ data: yesterdayTotal }, { data: todayTotal }] = await Promise.all(
        [
          http.get('/reports/report5', 
            {
              params: {
                SiteId: choosedSiteId,
                Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              }
            },
            
          ),
          http.get('/reports/report5', {
            params: {
              SiteId: choosedSiteId,
              Date: moment().format('YYYY-MM-DD'),
            }
          })
        ]
      );
      this.setState({yesterdayTotalAmount: yesterdayTotal.amount, todayTotalAmount: todayTotal.amount, yesterdayTotalAmountLoading: false });

    } catch (err) {
    }
  }
  render() {
    return (
      <S.Container>
        <section className='mb-4'>
        <MDBRow>
          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard className="card-block">
                <MDBRow className='mt-3'>
                  <MDBCol md='5' size='5' className='text-left pl-4'>
                    <MDBBtn
                      tag='a'
                      floating
                      size='lg'
                      color='primary'
                      className='ml-4'
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon='users' size='2x' />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md='7' col='7' className='text-right pr-5'>
                    <h5 className='ml-4 mt-4 mb-2 font-weight-bold'>{this.state.todayFillProfile}</h5>
                    <p className='font-small grey-text'>Fill Profile</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-3'>
                  <MDBCol md='7' col='7' className='text-left pl-4'>
                    <p className='font-small dark-grey-text font-up ml-4 font-weight-bold'>
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md='5' col='5' className='text-right pr-5'>
                    <p className='font-small grey-text'>{this.state.yesterdayFillProfile}</p>
                  </MDBCol>
                </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard className="card-block">
                <MDBRow className='mt-3'>
                  <MDBCol md='5' col='5' className='text-left pl-4'>
                    <MDBBtn
                      tag='a'
                      floating
                      size='lg'
                      color='warning'
                      className='ml-4'
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon='envelope' size='2x' />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md='7' col='7' className='text-right pr-5'>
                    <h5 className='ml-4 mt-4 mb-2 font-weight-bold'>{this.state.todayConfirmedEmails}</h5>
                    <p className='font-small grey-text'>Confirmed</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-3'>
                  <MDBCol md='7' col='7' className='text-left pl-4'>
                    <p className='font-small dark-grey-text font-up ml-4 font-weight-bold'>
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md='5' col='5' className='text-right pr-5'>
                    <p className='font-small grey-text'>{this.state.yesterdayConfirmedEmails}</p>
                  </MDBCol>
                </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard className="card-block">
                <MDBRow className='mt-3'>
                  <MDBCol md='5' col='5' className='text-left pl-4'>
                    <MDBBtn
                      tag='a'
                      floating
                      size='lg'
                      color='info'
                      className='ml-4'
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon='dollar-sign' size='2x' />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md='7' col='7' className='text-right pr-5'>
                    <h5 className='ml-4 mt-4 mb-2 font-weight-bold'>{this.state.todayPurchase}</h5>
                    <p className='font-small grey-text'>Sales</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='my-3'>
                  <MDBCol md='7' col='7' className='text-left pl-4'>
                    <p className='font-small dark-grey-text font-up ml-4 font-weight-bold'>
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md='5' col='5' className='text-right pr-5'>
                   <p className='font-small grey-text'>{this.state.yesterdayPurchase}</p>
                  </MDBCol>
                </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard className="card-block">
              <MDBRow className='mt-3'>
                <MDBCol md='5' col='5' className='text-left pl-4'>
                  <MDBBtn
                    tag='a'
                    floating
                    size='lg'
                    color='danger'
                    className='ml-4'
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon='database' size='2x' />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md='7' col='7' className='text-right pr-5'>
                  <h5 className='ml-4 mt-4 mb-2 font-weight-bold'>{this.state.todayTotalAmount}</h5>
                  <p className='font-small grey-text'>Amount</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className='my-3'>
                <MDBCol md='7' col='7' className='text-left pl-4'>
                  <p className='font-small dark-grey-text font-up ml-4 font-weight-bold'>
                    Yesterday
                  </p>
                </MDBCol>
                <MDBCol md='5' col='5' className='text-right pr-5'>
                  <p className='font-small grey-text'>{this.state.yesterdayTotalAmount}</p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
      </S.Container>
    )
  }
}

const mapStateToProps = ({app}) => ({
  choosedSiteId: app.choosedSiteId,
});

export default withSnackbar(connect(mapStateToProps, null)(Dashboard));