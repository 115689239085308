import styled from 'styled-components';

export const Container = styled.div`
  .table {
    margin-bottom: 0;
    /* table-layout: fixed; */
  }
  td {
    vertical-align: middle;
  }
  .table-responsive {
    /* overflow-y: visible;
    overflow-x: visible; */
  }
`;


export const Th = styled.th`
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  ::after {
    right: 1em;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0de";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'desc') ? 1 : 0.4}
  }
  ::before {
    right: 16px;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0dd";
    position: absolute;
    bottom: .9em;
    display: ${({withSorting}) => withSorting ? 'block' : 'none'};
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'asc') ? 1 : 0.4}
  }
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
    padding-right: 0;
    input {
      width: 165px;
    }
`;

export const PagingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
  width: 300px;
`;

