import { MDBBtn } from "mdbreact";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
    getCuratorsString = (col) => {
        let str = '';
        col.curators.forEach((c, index) => {
            str += `${c}${index === col.curators.length - 1 ? '' : ', '}`
        })
        return str;
    }
    render() {
        return (
            <ExcelFile filename={`AffDataReport(${this.props.form.From}-${this.props.form.To}`} element={<MDBBtn>Download</MDBBtn>}>
                <ExcelSheet data={this.props.data} name="AffData">
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="AvgPerWeek" value="avgPerDayWeek"/>
                    <ExcelColumn label="AvgPerMonth" value="avgPerDayMonth"/>
                    <ExcelColumn label="Sum" value="sum"/>
                    <ExcelColumn label="Money" value="money"/>
                    <ExcelColumn label="Curator" value={col => this.getCuratorsString(col)}/>
                    <ExcelColumn label="Comment" value="comment"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download;