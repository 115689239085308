import React, { useState } from "react";
import { MDBInput, MDBBtn, MDBSpinner } from "mdbreact";
import { useSnackbar } from "react-simple-snackbar";

import MainContainer from "../../components/MainContainer";
import http from "../../services/http";
import { failureOptions, succesOptions } from "../../configs";


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [openFailureSnackbar] = useSnackbar(failureOptions);
  const [openSuccessSnackbar] = useSnackbar(succesOptions);



  const handleResetPassword = async () => {
    setLoading(true);
    try {
      await http.post("/auth/resetpassword", {
        email,
      });
      openSuccessSnackbar('Link sent')
    } catch (err) {
      openFailureSnackbar('Something went wrong')
    }
    setLoading(false);
  };

  return (
    <MainContainer>
      <form>
        <p className="h5 text-center mb-4">Reset password</p>
        <div className="grey-text">
          <MDBInput
            onChange={(e) => setEmail(e.target.value)}
            required
            label="Type your email"
            icon="envelope"
            group
            type="email"
            validate
            error="wrong"
            success="right"
          />
        </div>
        <div className="text-center">
          {loading ? (
            <MDBSpinner className="spinner" />
          ) : (
            <MDBBtn onClick={handleResetPassword}>Send reset link</MDBBtn>
          )}
        </div>
      </form>
    </MainContainer>
  );
};

export default ForgotPasswordPage;
