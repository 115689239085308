export const succesOptions = {
  style: {
    backgroundColor: 'green',
    color: 'white',
  }
}

export const failureOptions = {
  style: {
    backgroundColor: 'red',
    color: 'white',
  }
}

export const siteUrlsConfig = {
  3: 'bk',
  8: 'nd',
  37: 'fd',
};

export const sitesConfig = {
  8: 'https://nataliedate.com/profile/',
  3: 'https://bez-kompleksov.com/profile/',
  37: 'https://flirtfordate.com/profile/',
};
