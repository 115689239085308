export const formNavigation = (state, field) => {
  return {
    ...state,
    navigation: {
      ...state.navigation,
      [field]: {
        ...state.navigation[field],
        isOpen: !state.navigation[field].isOpen,
      },
    },
  };
};