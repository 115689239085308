import { MDBBtn, MDBCol, MDBInput, MDBCard, MDBCardText } from 'mdbreact';
import React from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { failureOptions, siteUrlsConfig, succesOptions } from '../../../../configs';
import http from '../../../../services/http/affiliates';

const ChangePassword = ({choosedSiteId}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const updatePassword = async () => {
    const {status} = await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/UpdatePassword/${email}/${password}`);
    if (status === 200) {
      openSuccessSnackbar('Updated!');
      setEmail('');
      setPassword('');
    } else {
      openFailureSnackbar('Error :(')
    }
  }
  return (
    <MDBCard className='p-3 mb-5'>
      <MDBCardText>Update password</MDBCardText>
      <MDBCol lg='6' md='6'>
        <MDBInput
          required
          label="Enter aff email"
          group
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <MDBInput
          required
          label="Enter new password"
          group
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <MDBBtn onClick={updatePassword}>Update</MDBBtn>
      </MDBCol>
    </MDBCard>
  )
}

export default ChangePassword;
