import React from 'react';
import { MDBContainer } from 'mdbreact';

import CuratorsTable from './components/CuratorsTable';

import * as S from './styled';

class CuratorsPage extends React.Component  {

  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <CuratorsTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default CuratorsPage;