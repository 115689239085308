import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
} from "mdbreact";
import moment from 'moment';
import { useSnackbar } from "react-simple-snackbar";
import { failureOptions } from "../../../../configs";
import React, { useEffect, useState } from "react";
import http from "../../../../services/http";


const TableRow = ({ row: data, getInfo }) => {
  const [openFailureSnackbar] = useSnackbar(failureOptions);
  const [isEditable, setEditable] = useState(false);

  const [oldDailyLimit] = useState(data.dailyLimit);
  const [row, setRow] = useState(data);

  useEffect(() => {
    setRow(data);
  }, [data]);

  const onFieldChange = (value, innerProp) => {
    setRow({
      ...row,
      [innerProp]: value,
    });
  };
  
  const onEditClick = () => {
    setEditable(true);
  };

  const onSavePress = async () => {
    setEditable(false);
    const body = {
      webId: row.webId,
      dailyLimit: +row.dailyLimit,
      status: row.status === "true" || row.status === 1 ? 1 : 0,
    };
    const isValid = isDailyLimitValid(row);
    if (isValid) {
      await http.patch(`/partnerdeals/${row.deal.id}/webs/${row.id}`, body);
    } else {
      openFailureSnackbar(`The amount of limits exceeds the limit on the deal (${row.dailyLimitSum} of partner deal)`)
    }
    getInfo();
  };

  const isDailyLimitValid = (web) => {
    if (web.deal.dailyLimit === 0) {
      return true;
    }
    const newDailyLimitSum = web.dailyLimitSum - oldDailyLimit + web.dailyLimit;
    if (newDailyLimitSum > web.deal.dailyLimitSum) {
      return false
    }
    return true
  }

  const onCancel = () => {
    setRow(data);
    setEditable(false);
  };

  return (
    <React.Fragment>
      {!isEditable ? (
        <tr>
          {/* UUID */}
          <td>{row.deal.partnerLink.uid}</td>
          {/* LinkName */}
          <td>{row.deal.partnerLink.name}</td>
          {/* webId */}
          <td>{row.webId}</td>
          {/* Payout */}
          <td>{row.deal.payout}</td>
          {/* Daily Limit */}
          <td>{row.dailyLimit}</td>
          {/* isActive */}
          <td>{row.status === 1 ? 'true' : 'false'}</td>
          {/* Start_date */}
          <td>
            {moment(row.createDate).format('YYYY-MM-DD HH:mm')}
          </td>
          <td>
            <MDBBtn size="sm" onClick={() => onEditClick(row.siteId)}>
              Edit
            </MDBBtn>
          </td>
        </tr>
      ) : (
        <tr>
          {/* UUID */}
          <td>{row.deal.partnerLink.uid}</td>
          {/* LinkName */}
          <td>{row.deal.partnerLink.name}</td>
          {/* webId */}
          <td>{row.webId}</td>
          {/* Payout */}
          <td>{row.deal.payout}</td>
          {/* Daily Limit */}
          <td>
            <input
              className="form-control form-control-sm"
              value={row.dailyLimit}
              onChange={(e) => {
                if (+e.target.value >= 0) {
                  onFieldChange(e.target.value, "dailyLimit");
                }
              }}
            />
          </td>
          {/* isActive */}
          <td>
            <MDBSelect
              className="colorful-select dropdown-primary mx-2"
              getTextContent={(val) => onFieldChange(val, "status")}
            >
              <MDBSelectInput selected={`${row.status === 1 ? 'true' : 'false'}`} />
              <MDBSelectOptions>
                {[{ name: "true" }, { name: "false" }].map((action) => (
                  <MDBSelectOption key={action.id} value={action.name}>
                    {action.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </td>
          {/* Start_date */}
          <td>
            {moment(row.createDate).format('YYYY-MM-DD HH:mm')}
          </td>
          <td>
            <MDBBtn color="success" size="sm" onClick={onSavePress}>
              Save
            </MDBBtn>
            <MDBBtn color="danger" size="sm" onClick={onCancel}>
              Cancel
            </MDBBtn>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default TableRow;
