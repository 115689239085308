import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact';
import { connect } from 'react-redux';

import SiteStorage from '../../services/storage/site';
import { toggleSideBar, chooseSite } from '../../store/app';
import { requestLogOut } from '../../store/auth';

import http from '../../services/http';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      sites: [],
    };
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleToggleClickA() {
    this.props.onSideNavToggleClick();
  }
  async componentDidMount() {
    const {choosedSiteId, chooseSite} = this.props;
    const {data} = await http.get('/sites/foradminpanel');
    this.setState({sites: data});
    if (!(data.find(site => site.id === choosedSiteId))) {
      chooseSite(data[0].id);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authenticated !== prevProps.authenticated) {
      const {data} = await http.get('/sites/foradminpanel');
      this.setState({sites: data});
    }
  }

  setChooseSite = (siteId) => {
    const {chooseSite} = this.props;
    SiteStorage.save(siteId);
    chooseSite(siteId)
  };

  render() {
    const navStyle = {
      paddingLeft: this.props.toggle ? '16px' : '240px',
      transition: 'padding-left .3s'
    };
    const { requestLogOut, choosedSiteId, isDateHeaderShow, dateHeader } = this.props;
    const {sites} = this.state;
    return (
      <Router>
        <MDBNavbar
          className='flexible-MDBNavbar'
          light
          expand='md'
          scrolling
          fixed='top'
          style={{ zIndex: 3 }}
        >
          <div
            onClick={this.handleToggleClickA}
            key='sideNavToggleA'
            style={{
              lineHeight: '32px',
              marginleft: '1em',
              verticalAlign: 'middle',
              cursor: 'pointer'
            }}
          >
            <MDBIcon icon='bars' color='white' size='lg' />
          </div>

          <MDBNavbarBrand style={navStyle}>
            <strong>{sites.find(site => site.id === choosedSiteId)?.name}</strong>
          </MDBNavbarBrand>
          {isDateHeaderShow && <div>{dateHeader}</div>}
          <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row' }}>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon='image' />{' '}
                <span className='d-none d-md-inline'>Sites</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: '400px', maxHeight: '400px', overflowY: 'scroll', }}>
              {sites.map(site => <MDBDropdownItem active={sites.find(site => site.id === choosedSiteId)?.name === site.name} key={site.id} onClick={() => this.setChooseSite(site.id)}>{site.name}</MDBDropdownItem>)}
              </MDBDropdownMenu>
            </MDBDropdown>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon='user' />{' '}
                <span className='d-none d-md-inline'>Profile</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: '200px' }}>
                <MDBDropdownItem onClick={requestLogOut}>Log Out</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarNav>
        </MDBNavbar>
      </Router>
    );
  }
}

const mapStateToProps = ({auth, app}) => ({
  authenticated: auth.authenticated,
  choosedSiteId: app.choosedSiteId,
  dateHeader: app.dateHeader,
  isDateHeaderShow: app.isDateHeaderShow,
});

const mapDispatchToProps = {
  toggleSideBar,
  requestLogOut,
  chooseSite,
}
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
