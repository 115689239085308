import React from 'react';
import { MDBContainer } from 'mdbreact';


import * as S from './styled';
import ChangePassword from './components/ChangePassword';
import CheckUser from './components/CheckUser';
import { connect } from 'react-redux';
import CheckBalance from './components/CheckBalance';

class ActionsPage extends React.Component  {

  render() {
    const {choosedSiteId, user} = this.props;
    const isAdmin = user?.role?.includes('Admin');
    return (
      <S.Container>
        <MDBContainer fluid>
          <ChangePassword choosedSiteId={choosedSiteId} />
          <CheckUser choosedSiteId={choosedSiteId} />
          {(isAdmin || user.email === "nastyahr@bk.ru") ? <CheckBalance choosedSiteId={choosedSiteId} /> : null}
        </MDBContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(ActionsPage);