import { usd_to_eur, rub_to_usd } from "../../constants";
import _ from 'lodash';

export const countRoi = (moneyTotal, payoutTotal, payoutCurrency, moneyCurrency) => {
  let payoutTotalInUsd = payoutTotal;
  let moneyTotalInUsd = moneyTotal;
  if (moneyCurrency.toLowerCase() === "eur") {
    moneyTotalInUsd = moneyTotal * usd_to_eur;
  }
  if (moneyCurrency.toLowerCase() === "rub") {
    moneyTotalInUsd = moneyTotal * rub_to_usd;
  }
  if (payoutCurrency.toLowerCase() === "eur") {
    payoutTotalInUsd = payoutTotal * usd_to_eur;
  }
  if (payoutCurrency.toLowerCase() === "rub") {
    payoutTotalInUsd = payoutTotal * rub_to_usd;
  }
  return Number((moneyTotalInUsd / payoutTotalInUsd).toFixed(2));
};

export const countProfit = (moneyTotal, payoutTotal, payoutCurrency, moneyCurrency) => {
  let payoutTotalInUsd = payoutTotal;
  let moneyTotalInUsd = moneyTotal;
  if (moneyCurrency.toLowerCase() === "eur") {
    moneyTotalInUsd = moneyTotal * usd_to_eur;
  }
  if (moneyCurrency.toLowerCase() === "rub") {
    moneyTotalInUsd = moneyTotal * rub_to_usd;
  }
  if (payoutCurrency.toLowerCase() === "eur") {
    payoutTotalInUsd = payoutTotal * usd_to_eur;
  }
  if (payoutCurrency.toLowerCase() === "rub") {
    payoutTotalInUsd = payoutTotal * rub_to_usd;
  }
  return Number((moneyTotalInUsd - payoutTotalInUsd).toFixed(2));
};

export const convertDataToUsd = (data, valueKey, currencyKey) => {
  return [...data].map((i) => {
    if (i[currencyKey] === "Eur") {
      return {
        ...i,
        [valueKey]: i[valueKey] * usd_to_eur,
      };
    }
    if (i[currencyKey] === "Rub") {
      return {
        ...i,
        [valueKey]: i[valueKey] * rub_to_usd,
      };
    }
    return i;
  });
}

export const countTotals = (data, countPayload) => {
  let totals = {};
  Object.keys(countPayload).map(field => {
    const currency = countPayload[field] === "number" ? 'usd' : countPayload[field];
    totals[field] = _.sumBy(
      convertDataToUsd(
        data,
        field,
        currency,
      ),
      (i) => i[field]
    ).toFixed(2)
  });
  return totals;
}