import React from "react";

import { MDBSpinner } from "mdbreact";

const renderLoadingContent = (content, loading) => {
  if (loading) {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <MDBSpinner className="spinner-container" />
    </div>;
  }
  return <>{content}</>;
};

export default renderLoadingContent;
