import React from "react";

import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBIcon } from "mdbreact";

import * as S from "./styled";

class SumBoard extends React.Component {
  formatSum = () => {
    const { sum } = this.props;
    let total = {};
    total.todayFillProfile = sum[3].todayFillProfile + sum[8].todayFillProfile;
    total.yesterdayFillProfile =
      sum[3].yesterdayFillProfile + sum[8].yesterdayFillProfile;
    total.todayConfirmedEmails =
      sum[3].todayConfirmedEmails + sum[8].todayConfirmedEmails;
    total.yesterdayConfirmedEmails =
      sum[3].yesterdayConfirmedEmails + sum[8].yesterdayConfirmedEmails;
    total.todayPurchase = sum[3].todayPurchase + sum[8].todayPurchase;
    total.yesterdayPurchase =
      sum[3].yesterdayPurchase + sum[8].yesterdayPurchase;
    total.todayTotalAmount = sum[3].todayTotalAmount + sum[8].todayTotalAmount;
    total.yesterdayTotalAmount =
      sum[3].yesterdayTotalAmount + sum[8].yesterdayTotalAmount;
    total.todayConversion = sum[3].todayConversion + sum[8].todayConversion;
    total.yesterdayConversion =
      sum[3].yesterdayConversion + sum[8].yesterdayConversion;
    total.todayFtd = sum[3].todayFtd + sum[8].todayFtd;
    total.yesterdayFtd =
      sum[3].yesterdayFtd + sum[8].yesterdayFtd;
    return total;
  };
  render() {
    const sum = this.formatSum();
    return (
      <S.Container>
        <section className="mb-4">
          <MDBRow>
            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" size="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="primary"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="users" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayFillProfile}
                    </h5>
                    <p className="font-small grey-text">Fill Profile</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayFillProfile}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="warning"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="envelope" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayConfirmedEmails}
                    </h5>
                    <p className="font-small grey-text">Confirmed</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayConfirmedEmails}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="info"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="dollar-sign" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayPurchase}
                    </h5>
                    <p className="font-small grey-text">Sales</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayPurchase}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>

            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="danger"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="database" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayTotalAmount}
                    </h5>
                    <p className="font-small grey-text">Amount</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayTotalAmount}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="danger"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="database" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayConversion}
                    </h5>
                    <p className="font-small grey-text">Conversion</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayConversion}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-4 mb-r">
              <MDBCard className="card-block">
                <MDBRow className="mt-3">
                  <MDBCol md="5" col="5" className="text-left pl-4">
                    <MDBBtn
                      tag="a"
                      floating
                      size="lg"
                      color="success"
                      className="ml-4"
                      style={{ padding: 0 }}
                    >
                      <MDBIcon icon="pen" size="2x" />
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="7" col="7" className="text-right pr-5">
                    <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                      {sum.todayFtd}
                    </h5>
                    <p className="font-small grey-text">FTD</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-3">
                  <MDBCol md="7" col="7" className="text-left pl-4">
                    <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                      Yesterday
                    </p>
                  </MDBCol>
                  <MDBCol md="5" col="5" className="text-right pr-5">
                    <p className="font-small grey-text">
                      {sum.yesterdayFtd}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </section>
      </S.Container>
    );
  }
}

export default SumBoard;
