import React from 'react';
import { MDBContainer } from 'mdbreact';

import AffBalanceTable from './components/AffBalanceTable';

import * as S from './styled';

class AffBalancePage extends React.Component  {

  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <AffBalanceTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default AffBalancePage;