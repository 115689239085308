/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MDBTooltip} from 'mdbreact';

import AlertModal from '../modals/AlertModal';

const Deactivate = ({onDelete}) => {
  const [isModalVisible, setModalVisible] = React.useState(false);

  return (
    <td>
      <MDBTooltip domElement>
        <a onClick={() => setModalVisible(true)}>
          <i className='fa fa-times red-text' />
        </a>
        <span>Deactivate</span>
      </MDBTooltip>
      <AlertModal onDelete={onDelete} visible={isModalVisible} onClose={() => setModalVisible(false)} />
    </td>
  )
};

export default Deactivate;