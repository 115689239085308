import React from 'react';

import http from '../../services/http';

import CreateUser from './components/CreateUser';
import MainContainer from '../../components/MainContainer';

class CreateUserPage extends React.Component  {
  state = {
    sites: [],
  }

  async componentDidMount() {
    this.setState({loading: true});
    const {data} = await http.get('/sites/foradminpanel');
    this.setState({sites: data,  loading: false});
  }

  render() {
    return (
      <MainContainer>
        {this.state.sites.length ? <CreateUser sites={this.state.sites} /> : null}
      </MainContainer>
    );
  }
}

export default CreateUserPage;