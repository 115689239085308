import styled from 'styled-components';
import {Link as L} from 'react-router-dom';

export const Container = styled.div`
  & .nav-link {
    background-color: rgba(0,0,0,0) !important;
  }
  .collapse {
    display: block;
  }
`;

export const NavLink = styled.div`
  color: ${({isSelected}) => !isSelected ? '#424242' : '#4285f4'};
  font-size: 13px;
  transition: all 0.3s linear;
  padding-left: 23px;
  font-weight: 400;
  text-decoration: none;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa-angle-down {
    position: absolute;
    right: 0;
    margin-right: 1.25rem;
    transition: all .15s ease-in 0s;
  }
  
  .rotate {
    transform: rotate(180deg)
  }

  :hover {
    background-color: rgba(0,0,0,0.05);
  }
`;

export const Link = styled(L)`
  padding: 0px 0px 0px 47px !important;
  color: ${({ isactive }) => isactive === "false" ? '#424242' : '#4285f4'} !important;
  font-size: 13px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  :hover {
    color: #4285f4 !important;
  }
`;