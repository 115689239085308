/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { MDBSelectOptions, MDBCard, MDBSelectOption, MDBSelectInput, MDBSelect, MDBCardBody, MDBDataTable, MDBBtn, MDBSpinner, MDBCardText } from 'mdbreact';

import http from '../../../../services/http/affiliates';
import { connect } from 'react-redux';
import { siteUrlsConfig } from '../../../../configs';
import SearchInput from '../../../../components/SearchInput';


import * as S from './styled';
import { Fragment } from 'react';

const AffTable = ({choosedSiteId}) => {

  const [data, setData] = useState([]);

  const params =  [
    50,
    200,
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
  ];

  const [totalSum, setTotalSum] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const columns = [
    {
      label: <div style={{fontWeight: 'bold', textAlign: 'center'}}>ID</div>,
      field: 'ind',
      width: 150,
      sort: 'disabled',
    },
    {
      label: <div style={{fontWeight: 'bold', textAlign: 'center'}}>Email</div>,
      field: 'email',
      width: 150,
    },
    {
      label: <div><div style={{fontWeight: 'bold', textAlign: 'center'}}>ProfileId</div></div>,
      field: 'profileId',
      width: 270,
    },
    {
      label: <div><div style={{fontWeight: 'bold', textAlign: 'center'}}>Sending</div></div>,
      field: 'sendingButton',
      width: 200,
    },
    {
      label: <div><div style={{fontWeight: 'bold', textAlign: 'center'}}>Batch</div></div>,
      field: 'batchDrop',
      width: 100,
    },
    {
      label: <div><div style={{fontWeight: 'bold', textAlign: 'center'}}>SentToday({totalSum})</div></div>,
      field: 'sentToday',
      width: 100,
    },
    {
      label: <div><div style={{fontWeight: 'bold', textAlign: 'center'}}>Online</div></div>,
      field: 'onlineStatus',
      width: 100,
    },
  ];

  const [affData, setAffData] = React.useState({
    columns: [],
    rows: [],
  });

  const [sortedBySending, setSortedBySending] = React.useState('');
  const [sortedByOnline, setSortedByOnline] = React.useState('');

  useEffect(() => {
    loadData();
  }, [choosedSiteId]);

  React.useEffect(() => {
    if (data.length) {
      setFinalData();
    }
  }, [JSON.stringify(data), affData.rows.length]);

  const renderSendButton = (item, index) => {
    if (!affData.rows[0]) {
      return null;
    }
    return item.sending ? <p className="sending">Sending..</p> : <MDBBtn size="sm" onClick={() => onButtonPress(index)}>Send</MDBBtn>
  }

  const renderOnlineStatus = (item) => {
    return <div style={{width: 20, height: 20, borderRadius: 20, backgroundColor: item.online === 'Yes' ? '#33ff00' : '#d1c9c9'}}></div>
  }
  
  const renderDropDown = (item, index) => (
    <MDBSelect 
        getTextContent={(val) => onDropChange(+val, item, index)}
        className='colorful-select dropdown-primary mx-2'
      >
      <MDBSelectInput selected={item.batch} />
      <MDBSelectOptions>
        {params.map(param => <MDBSelectOption key={param} value={param}>{param}</MDBSelectOption>)}
      </MDBSelectOptions>
    </MDBSelect>
  )

  const onDropChange = (val, item, index) => {
    const items = [...affData.rows];
    items[index].batch = +val;
    const formattedData = items.map((item, index) => ({
      ...item,
      sending: item.sending,
      batch: item.batch,
      sendingButton: renderSendButton(item, index),
      batchDrop: renderDropDown(item, index),
      onlineStatus: renderOnlineStatus(item),
    }))
    setAffData({
      columns: columns,
      rows: [...formattedData],
    });
  }
  const onButtonPress = async (index) => {
    const items = [...affData.rows];
    items[index].sending = 1;
    const formattedData = items.map((item, index) => ({
      ...item,
      sending: item.sending,
      batch: item.batch,
      sendingButton: renderSendButton(item, index),
      batchDrop: renderDropDown(item, index),
      onlineStatus: renderOnlineStatus(item),
    }))
    setAffData({
      columns: columns,
      rows: [...formattedData],
    });
    await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/SendAffManualActions`, {
      email: affData.rows[index].email,
      profileId: affData.rows[index].profileId,
      sending: affData.rows[index].sending,
      batch: affData.rows[index].batch,
      sentToday: affData.rows[index].sentToday,
    })
  }

  const loadData = async () => {
    setLoading(true);
    const {data} = await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/GetAffManualActions`);
    const dataWithFormattedBatch = data.map(item => ({
      ...item,
      batch: item.batch === 1000 ? 50 : item.batch
    }));
    setData(dataWithFormattedBatch);
  }

  const setFinalData = () => {
    if (!siteUrlsConfig[choosedSiteId]) return;
    setLoading(true);
    const formattedData = data.map((item, index) => ({
      ...item,
      sending: item.sending,
      batch: item.batch,
      sendingButton: renderSendButton(item, index),
      batchDrop: renderDropDown(item, index),
      onlineStatus: renderOnlineStatus(item),
    }))

    const sum = _.sumBy(data, 'sentToday');
    setTotalSum(sum);

    setAffData({
      columns: columns,
      rows: [...formattedData],
    });
    setLoading(false);
  }

  const filteredBySearch = data => {
    const filteredRows = data.rows.filter(item => item?.email?.toLowerCase().includes(searchText.toLowerCase()) || `${item?.profileId}`.includes(searchText.toLowerCase()));
    let finallRows = [];
    if (sortedBySending !== '') {
      if (sortedBySending === 'desc') {
        finallRows = _.sortBy(filteredRows, i => i.sending);
      }
      if (sortedBySending === 'asc') {
        finallRows = _.reverse(_.sortBy(filteredRows, i => i.sending));
      }
    } else if (sortedByOnline !== '') {
      if (sortedByOnline === 'desc') {
        finallRows = _.sortBy(filteredRows, i => i.online);
      }
      if (sortedByOnline === 'asc') {
        finallRows = _.reverse(_.sortBy(filteredRows, i => i.online));
      }
    } else {
      finallRows = filteredRows;
    }
    return {
      ...data,
      rows: finallRows,
    };
  };
  
  const onSort = (sortObj) => {
    if (sortObj.column === 'sendingButton') {
      setSortedBySending(sortObj.direction);
    } else {
      setSortedBySending('');
    }
    if (sortObj.column === 'onlineStatus') {
      setSortedByOnline(sortObj.direction);
    } else {
      setSortedByOnline('');
    }
  };

  return (
    <>
      <MDBCard narrow className='pb-3'>
          <MDBCardBody>
            <SearchInput onInputChange={value => setSearchText(value)} />
            {!loading ? <Fragment>
              <S.RowsTest>
                {
                  [...Array(+filteredBySearch(affData).rows.length).keys()].map(i => (
                    <S.Index>{i + 1}</S.Index>
                  ))
                }
              </S.RowsTest>
              <S.Container>
                <MDBDataTable
                  responsive
                  noBottomColumns
                  displayEntries={false}
                  searching={false}
                  paging={false}
                  data={filteredBySearch(affData)}
                  infoLabel={["Showing", "to", "of", "rows"]}
                  entries={50}
                  info={true}
                  onSort={onSort}
                />
              </S.Container>

            </Fragment> : <MDBSpinner className="spinner-container" />}
          </MDBCardBody>
        </MDBCard>
      
    </>
  )
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(AffTable);