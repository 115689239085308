import React from "react";
import {
  MDBInput,
  MDBSpinner,
  MDBCardBody,
  MDBBtn,
  MDBDataTable,
  MDBCard,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import { connect } from "react-redux";

import moment from "moment";

import http from "../../../../services/http";

const PartnerReport = ({ choosedSiteId, user }) => {
  const isAdmin = user?.role?.includes("dmi");
  const [users, setUsers] = React.useState([]);
  const [stats, setStats] = React.useState({
    columns: [],
    rows: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    From: moment().format("YYYY-MM-01"),
    To: moment().format("YYYY-MM-DD"),
  });

  React.useEffect(() => {
    async function getUsers() {
      const { data } = await http.get("/users");
      const partnersUsers = data.filter((u) =>
        Boolean(u.roles.find((r) => r.role === 3))
      );
      setUsers(partnersUsers);
    }
    if (isAdmin) {
      getUsers();
    }
  }, [users.length]);

  const onClick = async () => {
    setLoading(true);
    try {
      const { data } = await http.get("/reports/partner1", {
        params: {
          SiteId: choosedSiteId,
          UserId: isAdmin ? form.UserId : parseInt(user.id),
          ...form,
        },
      });

      setStats({
        ...stats,
        columns: [
          {
            label: <div style={{ fontWeight: "bold" }}>Fill Profiles</div>,
            field: "fillProfiles",
            width: 270,
          },
          {
            label: <div style={{ fontWeight: "bold" }}>Purchases</div>,
            field: "purchases",
            width: 200,
          },
          {
            label: (
              <div style={{ fontWeight: "bold" }}>Fill Profiles Payout</div>
            ),
            field: "fillProfilesPayout",
            width: 100,
          },
        ],
        rows: data,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onClick();
    setInitialLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading]);

  return (
    <>
      <MDBCard className="p-3 mb-5">
        <MDBRow>
          {isAdmin ? (
            <MDBCol lg="3" md="12">
              <MDBSelect
                getValue={(val) =>
                  setForm({
                    ...form,
                    UserId: val[0],
                  })
                }
                className="colorful-select dropdown-primary mx-2"
              >
                <MDBSelectInput selected={form.UserId} />
                <MDBSelectOptions>
                  {users.map((param) => (
                    <MDBSelectOption key={param?.id} value={param?.userId}>
                      {param?.email}
                    </MDBSelectOption>
                  ))}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
          ) : null}

          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="From date"
              type="date"
              group
              value={form.From}
              onChange={(e) =>
                setForm({
                  ...form,
                  From: e.target.value,
                })
              }
            />
          </MDBCol>

          <MDBCol lg="3" md="6">
            <MDBInput
              required
              label="To date"
              group
              type="date"
              value={form.To}
              onChange={(e) => {
                console.log(e);
                setForm({
                  ...form,
                  To: e.target.value,
                });
              }}
            />
          </MDBCol>

          <MDBCol className="btn-container" lg="3" md="6">
            <MDBBtn onClick={onClick}>Show</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      <MDBCard narrow className="pb-3">
        <MDBCardBody>
          {!loading ? (
            <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={stats}
              infoLabel={["Showing", "to", "of", "rows"]}
              entries={50}
              sortable={false}
              paging={false}
              // info={true}
            />
          ) : (
            <MDBSpinner className="spinner-container" />
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(PartnerReport);
