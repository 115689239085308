import React from 'react';
import { MDBIcon, MDBSpinner, MDBCardBody,  MDBBtn, MDBDataTable, MDBCard, MDBRow, MDBCol, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from 'mdbreact';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Select from "react-select";

import http from '../../../../services/http';
import DateInput from '../../../../components/DateInput';
import useDateScrollHeader from '../../../../hooks/useDateScrollHeader';

const params = [
  'Utm_source',
  'Utm_campaign',
  'Utm_term',
  'Utm_content',
  'Linkid',
];

const Stats = ({ choosedSiteId }) => {
  const [sortedBySending, setSortedBySending] = React.useState('');

  const [stats, setStats] = React.useState({
      columns: [],
      rows: [],
    });
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [dateForm, setDateForm] = React.useState({
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  useDateScrollHeader({
    date: `${moment(dateForm.from).format('DD-MM-YYYY')} - ${moment(dateForm.to).format('DD-MM-YYYY')}`,
  }, [dateForm]);
  
  const [form, setForm] = React.useState({
    Param: 'Utm_source',
    Param2: undefined,

  });

  const onClick = async () => {
    setLoading(true);
    try {
      const {data} = await http.get('/reports/report1_v2', {
        params: {
          SiteId: choosedSiteId,
          ...dateForm,
          ...form,
        }
      })
  
      const totalCount = {
          sales: _.sumBy(data, 'sales'),
          coins: _.sumBy(data, 'coins'),
          fillProfiles: _.sumBy(data, 'fillProfiles'),
          money: _.sumBy(data, 'money'),
          registrations: _.sumBy(data, 'registrations'),
          confirmed: _.sumBy(data, 'confirmed'),
          ftd: _.sumBy(data, 'ftd'),
      };
    
      const columns = [
        {
          label: <div style={{fontWeight: 'bold'}}>Param1</div>,
          field: 'param',
          width: 150,
        },
        form.Param2 ? {
          label: <div style={{fontWeight: 'bold'}}>Param2</div>,
          field: 'param2',
          width: 150,
        } : undefined,
        {
          label: <div><div style={{fontWeight: 'bold'}}>Registrations</div><div style={{fontStyle: 'italic'}}>({totalCount.registrations})</div></div>,
          field: 'registrations',
          width: 150,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Fill Profiles</div><div style={{fontStyle: 'italic'}}>({totalCount.fillProfiles})</div></div>,
          field: 'fillProfiles',
          width: 270,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Confirmed</div><div style={{fontStyle: 'italic'}}>({totalCount.confirmed})</div></div>,
          field: 'confirmed',
          width: 150,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>FTD</div><div style={{fontStyle: 'italic'}}>({totalCount.ftd})</div></div>,
          field: 'ftd',
          width: 150,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Sales</div><div style={{fontStyle: 'italic'}}>({totalCount.sales})</div></div>,
          field: 'sales',
          width: 200,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Coins</div><div style={{fontStyle: 'italic'}}>({totalCount.coins})</div></div>,
          field: 'coins',
          width: 100,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Money</div><div style={{fontStyle: 'italic'}}>({totalCount.money})</div></div>,
          field: 'money',
          width: 100,
        },
      ].filter(i => i !== undefined);
      setStats({
        ...stats,
        columns: [
          ...columns,
        ],
        rows: data,
      });
    } catch (err) {
      
    }
  
    setLoading(false);
  }
  React.useEffect(() => {
    onClick();
    setInitialLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading])

  const sortedData = data => {
    const rows = data.rows;
    let finallRows = [];
    if (sortedBySending !== '') {
      if (sortedBySending === 'desc') {
        finallRows = _.sortBy(rows, i => i.param2);
      }
      if (sortedBySending === 'asc') {
        finallRows = _.reverse(_.sortBy(rows, i => i.param2));
      }
    } else {
      finallRows = rows;
    }
    return {
      ...data,
      rows: finallRows,
    };
  };

  return (
    <>
        <MDBCard className='p-2 mb-5'>
          <MDBRow>
            <MDBCol lg='3' md='12'>
              <MDBSelect
                getTextContent={(val) => setForm({
                ...form,
                Param: val,
                })}
                className='colorful-select dropdown-primary mx-2'
              >
                <MDBSelectInput selected={form.Param} />
                <MDBSelectOptions>
                  {params.map(param => <MDBSelectOption key={param} value={param}>{param}</MDBSelectOption>)}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>

            <MDBCol lg='3' md='12'>
              <Select
                isSearchable={false}
                styles={{ control: (s) => ({ ...s, marginTop: 25 }) }}
                placeholder={"Param2"}
                options={params.map(p => ({ value: p, label: p }))}
                isClearable
                onChange={(val) =>
                  setForm({
                    ...form,
                    Param2: val?.value || undefined,
                  })
                }
              />
            </MDBCol>
      
            <DateInput
              value={dateForm}
              onChange={(value) => setDateForm(value)}
            />

            <MDBCol className="btn-container" lg='3' md='6'>
              <MDBBtn onClick={onClick}>Show</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCard>
        <MDBCard narrow className='pb-3'>
          <MDBCardBody>
            {!loading ? <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={sortedData(stats)}
              infoLabel={["Showing", "to", "of", "rows"]}
              entries={50}
              info={true}
              onSort={(sortObj) => {
                if (sortObj.column === 'param2') {
                  setSortedBySending(sortObj.direction);
                } else {
                  setSortedBySending('');
                }
              }}
            /> : <MDBSpinner className="spinner-container" />}
          </MDBCardBody>
        </MDBCard>
    </>
  )
}

const mapStateToProps = ({app}) => ({
  choosedSiteId: app.choosedSiteId,
});

export default connect(mapStateToProps, null)(Stats);