import styled from 'styled-components';

export const Container = styled.div`
  .table {
    margin-bottom: 0;
  }
  td {
    vertical-align: middle;
  }
  .table-responsive {
    /* overflow-y: visible;
    overflow-x: visible; */
  }
`;

export const AddButton = styled.div`
  width: 100%;
  height: 50px;
  background-color: #00bcd4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  :hover {
    opacity: ${({ disabled }) => disabled ? 0.5 : 0.7};
  }
`;

export const AddText = styled.span`
  color: white;
`;

export const Th = styled.th`
  cursor: pointer;
  position: relative;
  padding-right: 30px !important;
  ::after {
    right: 1em;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0de";
    position: absolute;
    bottom: .9em;
    display: block;
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'desc') ? 1 : 0.4}
  }
  ::before {
    right: 16px;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f0dd";
    position: absolute;
    bottom: .9em;
    display: block;
    opacity: ${({isSorting, direction}) => (isSorting && direction === 'asc') ? 1 : 0.4}
  }
`;

export const CollapseButton = styled.div`
  display: none;
  padding: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  @media screen and (max-width: 780px) {
    display: block;
  }
`;
