/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { MDBCard, MDBCardBody, MDBDataTable, MDBSpinner } from "mdbreact";

import http from "../../../../services/http/affiliates";
import { connect } from "react-redux";
import Download from "../Download";
import { siteUrlsConfig } from "../../../../configs";

const AffLinksTable = ({ choosedSiteId }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    onClick();
  }, [choosedSiteId]);

  const onClick = async () => {
    setLoading(true);
    const { data } = await http.get(
      `/api/${siteUrlsConfig[choosedSiteId]}/GetLinks`
    );

    setData({
      columns: [
        {
          label: "Id",
          field: "id",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          width: 270,
        },
      ],
      rows: [...data],
    });
    setLoading(false);
  };

  return (
    <>
      <Download data={data.rows} />
      <MDBCard narrow className="pb-3">
        <MDBCardBody>
          {!loading ? (
            <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={data}
              infoLabel={["Showing", "to", "of", "rows"]}
              entries={50}
              info={true}
            />
          ) : (
            <MDBSpinner className="spinner-container" />
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(AffLinksTable);
