import { MDBBtn, MDBCol, MDBInput, MDBCard, MDBRow, MDBSpinner, MDBCardText } from 'mdbreact';
import React, { useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { failureOptions, siteUrlsConfig } from '../../../../configs';
import http from '../../../../services/http/affiliates';
import SurePopup from './SurePopup';

const CheckBalance = ({choosedSiteId}) => {

  const [isModalVisible, setModalVisible] = useState(false);
  const [isResetModalVisible, setResetModalVisible] = useState(false);

  const [type, setType] = useState('increment');
  const [error, setError] = useState('');

  const [input, setInput] = useState('');
  const [isFound, setFound] = useState(false);
  const [foundUser, setFoundUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [addition, setAddition] = useState(0);

  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const checkBalance = async () => {
    setError('');
    setAddition(0);
    if (!input) {
      setError('Email cannot be empty!');
      return;
    }
    setLoading(true);
    setFoundUser({});
    setFound(false);
    try {
      const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/GetAffBalance/${input}`);
      setFoundUser(data);
      setFound(Boolean(data) && Boolean(data.userid));
      if (!Boolean(data) || !data.userid) {
        openFailureSnackbar('Invalid input');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  const onAdditionClick = async () => {
    setConfirmLoading(true);
    let value = type === 'increment' ? addition : `-${addition}`;
    try {
      await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/UpdateAffBalance/${value}/${foundUser.userid}`)
      setError(`Successfully updated the balance by ${value}!`)
    } catch (err) {
      setError('Something went wrong, please try again!');
    }
    setModalVisible(false);
    setFoundUser({
      ...foundUser,
      amount: type === 'increment' ? foundUser.amount + (+addition) : foundUser.amount - (+addition),
    })
    setAddition(0);
    setConfirmLoading(false);
  };

  const onAddClick = (type) => {
    setError('');
    if (!addition || !Number.isInteger(+addition)) {
      setError('Please enter a whole number!');
      return;
    }
    setType(type);
    setModalVisible(true);
  };

  const onResetBalance = async () => {
    setConfirmLoading(true);
    try {
      await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/UpdateAffBalance/-${foundUser.amount}/${foundUser.userid}`)
      setFoundUser({
        ...foundUser,
        amount: 0,
      })
      setError(`Successfully reseted the balance!!`)
    } catch (err) {
      setError('Something went wrong, please try again!');
    }
    setResetModalVisible(false);
    setConfirmLoading(false);
  }
  return (
    <MDBCard className='p-3 mb-5'>
      <MDBCardText>Check balance</MDBCardText>
      <MDBCol lg='6' md='6'>
      <MDBInput
        required
        label="Enter Aff Email"
        group
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
        <MDBBtn onClick={checkBalance}>Show</MDBBtn>
        {error ? <div style={{color: 'red'}}>{error}</div> : null}
        <MDBRow className="mt-5">
          {
            loading ?  <MDBSpinner className="spinner-container" /> : null
          }
          {
            isFound ? (
              <MDBCol md="6">
                <span><strong>Balance: {foundUser.amount}</strong></span>
                <MDBInput
                  label="Addition"
                  type="number"
                  value={addition}
                  onChange={(e) => setAddition(e.target.value)}
                />
                <MDBBtn onClick={() => onAddClick('increment')}>+</MDBBtn>
                <MDBBtn onClick={() => onAddClick('decrement')}>-</MDBBtn>
                <MDBBtn onClick={() => setResetModalVisible(true)}>Reset the balance</MDBBtn>
                <SurePopup isLoading={confirmLoading} visible={isModalVisible} onClose={() => setModalVisible(false)} onConfirm={onAdditionClick} />
                <SurePopup isLoading={confirmLoading} visible={isResetModalVisible} onClose={() => setResetModalVisible(false)} onConfirm={onResetBalance} />
              </MDBCol>
            ) : null
          }
        </MDBRow>
      </MDBCol>
    </MDBCard>
  )
}

export default CheckBalance;
