/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import moment from "moment";

import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBCardBody,
  MDBDataTable,
  MDBSpinner,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBCardText,
} from "mdbreact";

import http from "../../../../services/http/affiliates";
import { connect } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import Download from "../Download";
import {
  failureOptions,
  siteUrlsConfig,
  succesOptions,
} from "../../../../configs";

import CommentField from "../CommentField";

import * as S from "./styled";
import { Fragment } from "react";
import useDateScrollHeader from "../../../../hooks/useDateScrollHeader";
import DateInput from "../../../../components/DateInput";

const AffTable = ({ choosedSiteId }) => {
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions);

  const [allData, setAllData] = React.useState([]);
  const [curators, setCurators] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [affData, setAffData] = React.useState({
    columns: [],
    rows: [],
  });

  const [dateForm, setDateForm] = React.useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  const [form, setForm] = React.useState({
    curatorName: "",
    email: "",
    comment: "",
  });

  useDateScrollHeader(
    {
      date: `${moment(dateForm.from).format("DD-MM-YYYY")} - ${moment(
        dateForm.to
      ).format("DD-MM-YYYY")}`,
    },
    [dateForm]
  );

  React.useEffect(() => {
    onClick();
  }, [choosedSiteId]);

  React.useEffect(() => {
    recalculateTotal();
  }, [form.email, form.curatorName, form.comment]);

  const onDropChange = async (value, itemCurators, curatorsData, item) => {
    const addedElements = _.difference(value, itemCurators);
    const deletedElements = _.difference(itemCurators, value);
    if (addedElements.length) {
      try {
        await http.post(
          `/api/${siteUrlsConfig[choosedSiteId]}/AddAffToCuratorMany/${
            item.userId
          }/${curatorsData.find((c) => c.name === addedElements[0]).id}`
        );
        onClick(true);
        openSuccessSnackbar("Curator has added");
      } catch (err) {
        openFailureSnackbar("Something went wrong :(");
      }
    }
    if (deletedElements.length && !deletedElements.includes(null)) {
      try {
        await http.post(
          `/api/${siteUrlsConfig[choosedSiteId]}/RemoveAffFromCuratorMany/${
            item.userId
          }/${curatorsData.find((c) => c.name === deletedElements[0]).id}`
        );
        onClick(true);
        openSuccessSnackbar("Curator has removed");
      } catch (err) {
        openFailureSnackbar("Something went wrong :(");
      }
    }
  };

  const renderDropDownWithmultiple = (itemCurators, curatorsData, item) => {
    let dropParams = [];
    if (!itemCurators.length) {
      dropParams = [
        {
          name: "None",
          id: null,
        },
        ...curatorsData,
      ];
    } else {
      dropParams = [...curatorsData];
    }
    return (
      <MDBSelect
        getValue={(val) => onDropChange(val, itemCurators, curatorsData, item)}
        className="colorful-select dropdown-primary mx-2"
        multiple
        key={JSON.stringify(item)}
      >
        <MDBSelectInput />
        <MDBSelectOptions>
          {dropParams.map((curator) => (
            <MDBSelectOption
              checked={itemCurators.includes(curator.name)}
              key={curator.id}
              value={curator.name}
            >
              {curator.name}
            </MDBSelectOption>
          ))}
        </MDBSelectOptions>
      </MDBSelect>
    );
  };

  const recalculateTotal = () => {
    const filteredData = allData
      .filter((item) => {
        if (!form.curatorName || form.curatorName === "All") {
          return true;
        }
        return item.curators.includes(form.curatorName);
      })
      .filter((r) => r.email.includes(form.email));
    const totalCount = {
      avgPerDayWeek: Math.round(
        _.sumBy(filteredData, "avgPerDayWeek") / filteredData.length
      ),
      avgPerDayMonth: Math.round(
        _.sumBy(filteredData, "avgPerDayMonth") / filteredData.length
      ),
      sum: _.sumBy(filteredData, "sum"),
      money: _.sumBy(filteredData, "money"),
    };

    setAffData({
      columns: [
        {
          label: <div style={{ fontWeight: "bold" }}>ID</div>,
          field: "ind",
          width: 150,
          sort: "disabled",
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Email</div>,
          field: "email",
          width: 150,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>AvgPerDayWeek</div>
              <div style={{ fontStyle: "italic" }}>
                (
                {isNaN(totalCount.avgPerDayWeek) ? 0 : totalCount.avgPerDayWeek}
                )
              </div>
            </div>
          ),
          field: "avgPerDayWeek",
          width: 270,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>AvgPerDayMonth</div>
              <div style={{ fontStyle: "italic" }}>
                (
                {isNaN(totalCount.avgPerDayMonth)
                  ? 0
                  : totalCount.avgPerDayMonth}
                )
              </div>
            </div>
          ),
          field: "avgPerDayMonth",
          width: 200,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>Sum</div>
              <div style={{ fontStyle: "italic" }}>({totalCount.sum})</div>
            </div>
          ),
          field: "sum",
          width: 100,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>Money</div>
              <div style={{ fontStyle: "italic" }}>({totalCount.money})</div>
            </div>
          ),
          field: "money",
          width: 100,
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Curators</div>,
          field: "curatorsDrop",
          width: 100,
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Comment</div>,
          field: "commentField",
          width: 100,
        },
      ],
      rows: [...affData.rows],
    });
  };

  const onClick = async (withoutLoading = false) => {
    if (!withoutLoading) {
      setLoading(true);
    }
    const { data: curatorsData } = await http.get(
      `/api/${siteUrlsConfig[choosedSiteId]}/GetCurators/`
    );
    setCurators(curatorsData);
    const { data } = await http.get(
      `/api/${siteUrlsConfig[choosedSiteId]}/GetAffData/${dateForm.from}/${moment(dateForm.to).add(1, 'days').format('YYYY-MM-DD')}`
    );

    const grouped = _.groupBy(data, (i) => i.email);
    const formattedWithCurators = Object.keys(grouped).map((key, index) => ({
      email: grouped[key][0].email,
      avgPerDayMonth: grouped[key][0].avgPerDayMonth,
      avgPerDayWeek: grouped[key][0].avgPerDayWeek,
      money: grouped[key][0].money,
      sum: grouped[key][0].sum,
      userId: grouped[key][0].userId,
      curators: grouped[key].map((i) => i.curator),
      curatorsDrop: renderDropDownWithmultiple(
        grouped[key].map((i) => i.curator),
        curatorsData,
        grouped[key][0]
      ),
      commentField: (
        <CommentField key={grouped[key][0].userId} item={grouped[key][0]} />
      ),
      comment: grouped[key][0].comment || "",
    }));

    setAllData(
      formattedWithCurators.map((item) => ({
        email: item.email,
        avgPerDayMonth: item.avgPerDayMonth,
        avgPerDayWeek: item.avgPerDayWeek,
        money: item.money,
        sum: item.sum,
        userId: item.userId,
        curators: item.curators,
        comment: item.comment,
      }))
    );

    const totalCount = {
      avgPerDayWeek: Math.round(
        _.sumBy(formattedWithCurators, "avgPerDayWeek") / data.length
      ),
      avgPerDayMonth: Math.round(
        _.sumBy(formattedWithCurators, "avgPerDayMonth") / data.length
      ),
      sum: _.sumBy(formattedWithCurators, "sum"),
      money: _.sumBy(formattedWithCurators, "money"),
    };

    setAffData({
      columns: [
        {
          label: <div style={{ fontWeight: "bold" }}>ID</div>,
          field: "ind",
          width: 150,
          sort: "disabled",
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Email</div>,
          field: "email",
          width: 150,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>AvgPerDayWeek</div>
              <div style={{ fontStyle: "italic" }}>
                (
                {isNaN(totalCount.avgPerDayWeek) ? 0 : totalCount.avgPerDayWeek}
                )
              </div>
            </div>
          ),
          field: "avgPerDayWeek",
          width: 270,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>AvgPerDayMonth</div>
              <div style={{ fontStyle: "italic" }}>
                (
                {isNaN(totalCount.avgPerDayMonth)
                  ? 0
                  : totalCount.avgPerDayMonth}
                )
              </div>
            </div>
          ),
          field: "avgPerDayMonth",
          width: 200,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>Sum</div>
              <div style={{ fontStyle: "italic" }}>({totalCount.sum})</div>
            </div>
          ),
          field: "sum",
          width: 100,
        },
        {
          label: (
            <div>
              <div style={{ fontWeight: "bold" }}>Money</div>
              <div style={{ fontStyle: "italic" }}>({totalCount.money})</div>
            </div>
          ),
          field: "money",
          width: 100,
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Curators</div>,
          field: "curatorsDrop",
          width: 100,
        },
        {
          label: <div style={{ fontWeight: "bold" }}>Comment</div>,
          field: "commentField",
          width: 100,
        },
      ],
      rows: [...formattedWithCurators],
    });
    setLoading(false);
  };

  const getRows = (rows) => {
    return rows
      .filter((item) => {
        if (!form.curatorName || form.curatorName === "All") {
          return true;
        }
        return item.curators.includes(form.curatorName);
      })
      .filter((r) => r.email.includes(form.email))
      .filter((r) => r.comment.includes(form.comment));
  };

  return (
    <>
      <MDBCard className="p-3 mb-5">
        <MDBRow center>
          <DateInput
            value={dateForm}
            onChange={(value) => setDateForm(value)}
          />

          <MDBCol className="btn-container" lg="6" md="6" sm="6">
            <MDBBtn onClick={() => onClick(false)}>Show</MDBBtn>
            <Download
              form={form}
              data={allData
                .filter((item) => {
                  if (!form.curatorName || form.curatorName === "All") {
                    return true;
                  }
                  return item.curators.includes(form.curatorName);
                })
                .filter((r) => r.email.includes(form.email))
                .filter((r) => r.comment.includes(form.comment))}
            />
          </MDBCol>
          <MDBCol className="btn-container" lg="3" md="6"></MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol lg="1" md="3" middle>
            <MDBCardText>Filter:</MDBCardText>
          </MDBCol>
          <MDBCol lg="3" md="6">
            <MDBInput
              onChange={(e) => {
                setForm({
                  ...form,
                  email: e.target.value,
                });
              }}
              label="Email"
            />
          </MDBCol>

          <MDBCol lg="3" md="6">
            <MDBSelect
              getTextContent={(val) => {
                console.log("val", val);
                setForm({
                  ...form,
                  curatorName: val,
                });
              }}
              label="Curator"
              className="colorful-select dropdown-primary mx-2"
            >
              <MDBSelectInput selected={form.curatorName} />
              <MDBSelectOptions>
                {[{ name: "All" }, ...curators].map((curator) => (
                  <MDBSelectOption key={curator.id} value={curator.name}>
                    {curator.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </MDBCol>

          <MDBCol lg="3" md="6">
            <MDBInput
              onChange={(e) => {
                setForm({
                  ...form,
                  comment: e.target.value,
                });
              }}
              label="Comment"
            />
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCard narrow className="pb-3">
        <MDBCardBody>
          {!loading ? (
            <Fragment>
              <S.RowsTest>
                {[...Array(+getRows([...affData.rows]).length).keys()].map(
                  (i) => (
                    <S.Index>{i + 1}</S.Index>
                  )
                )}
              </S.RowsTest>
              <S.Container>
                <MDBDataTable
                  responsive
                  noBottomColumns
                  displayEntries={false}
                  searching={false}
                  paging={false}
                  data={{
                    columns: [...affData.columns],
                    rows: getRows([...affData.rows]),
                  }}
                  infoLabel={["Showing", "to", "of", "rows"]}
                  entries={50}
                  info={true}
                />
              </S.Container>
            </Fragment>
          ) : (
            <MDBSpinner className="spinner-container" />
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(AffTable);
