import styled from 'styled-components';

export const Container = styled.div`
  .preloader-wrapper.small {
    width: 2.5rem;
    height: 2.5rem;
  }
  .card-block {
    min-height: 178px;
    & .spin {
      margin: auto;
    }
  }
  .card-chart {
    height: 425px;
    & .spin {
      margin: auto;
    }
  }
`;