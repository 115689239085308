import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
  MDBTooltip,
} from "mdbreact";

import React from 'react';

const getOperatorText = (operatorValue, operators) => operators?.find(o => o.value === operatorValue)?.text;
const getOperatorValue = (operatorText, operators) => operators?.find(o => o.text === operatorText)?.value;
const getRestrictionByType = (type, restrictions) => restrictions?.find(r => r.propertyName === type);

export const renderRestrictionEdit = ({
  label,
  type,
  inputType,
  onRestrictionChange = () => {},
  clearRestriction = () => {},
  operators = [],
  restrictions = [],
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {label}:
    <MDBSelect
      getTextContent={(val) => onRestrictionChange(type, 'propertyOperator', getOperatorValue(val, operators))}
      className="colorful-select dropdown-primary mx-2"
      style={{ width: 50 }}
    >
      <MDBSelectInput selected={getOperatorText(getRestrictionByType(type, restrictions)?.propertyOperator, operators) || ''} />
      <MDBSelectOptions>
        {operators.map((operator) => (
          <MDBSelectOption key={operator.text} value={operator.text}>
            {operator.text}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    <input
      value={getRestrictionByType(type, restrictions)?.propertyValue || ''}
      onChange={e => onRestrictionChange(type, 'propertyValue', e.target.value)}
      type={inputType}
      style={{ width: 50, height: 41, border: '1px solid #ced4da', borderRadius: '0.2rem' }}
    />
    <a onClick={() => clearRestriction(type)}>
      <i className='fa fa-times black-text ml-2' />
    </a>
  </div>
)

export const mapRestrictionToLabel = {
  ipinfo_country: 'geo',
};