import React from 'react';

import { MDBBtn, MDBCard, MDBCol, MDBRow, MDBCardBody, MDBDataTable, MDBSpinner, MDBInput, MDBTable } from 'mdbreact';

import http from '../../../../services/http/affiliates';
import { connect } from 'react-redux';
import { MDBValidation } from 'mdb-react-ui-kit';
import Deactivate from '../../../../components/Deactivate';
import { siteUrlsConfig } from '../../../../configs';


const CuratorsTable = ({choosedSiteId}) => {

  const [loading, setLoading] = React.useState(false);
  const [curators, setCurators] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [form, setForm] = React.useState({
    name: '',
  });

  React.useEffect(() => {
    getCurators();
  }, [choosedSiteId]);

  const onInputChange = (value) => {
    setError(false);
    setForm({
      name: value,
    });
  };

  const createCurator = async () => {
    if (!form.name) {
      setError(true);
      return;
    }
    setLoading(true);
    await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/CreateCurator/${form.name}`);
    setForm({
      name: '',
    });
    await getCurators();
  };

  const deleteCurator = async (name) => {
    await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/DeleteCurator/${name}`)
    const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/GetCurators/`);
    setCurators(data);
  }
  const getCurators = async () => {
    setLoading(true);
    const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/GetCurators/`);
    setCurators(data);
    setLoading(false);
  }
  return (
    <>
      <MDBCard className='p-3 mb-5'>
        <MDBRow center>

          <MDBCol lg='3' md='6'>
            <MDBInput
              onChange={(e) => onInputChange(e.target.value)}
              value={form.name}
              label="Name"
            />
          <div style={{display: error ? 'block' : 'none'}} className='invalid-feedback'>Curator’s name cannot be empty</div>
          </MDBCol>

          <MDBCol className="btn-container" lg='3' md='6'>
            <MDBBtn onClick={createCurator} type="submit">Add</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCard narrow className='pb-3'>
          <MDBCardBody>
        {!loading ? <MDBTable responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Deactivate</th>
            </tr>
          </thead>
          <tbody>
            {curators.map(curator => (
              <>
                {!curator.isDeleted && <tr key={curator.id}>
                  <td>{curator.id}</td>
                  <td>{curator.name}</td>
                  <Deactivate onDelete={() => deleteCurator(curator.name)} />
                </tr>}
              </>
            ))}
          </tbody>
        </MDBTable> : <MDBSpinner className="spin" />}
            {/* {!loading ? <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={curators}
              sortable={false}
              entries={50}
              info={false}
            /> : <MDBSpinner className="spinner-container" />} */}
          </MDBCardBody>
        </MDBCard>
      
    </>
  )
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(CuratorsTable);