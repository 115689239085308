export const partnerHeaders = [
  {
    label: "Partner",
    field: "partnerEmail",
  },
  {
    label: "FTD",
    field: "ffd",
  },
  {
    label: "Investment",
    field: "investment",
  },
  {
    label: "Turnover",
    field: "turnover",
  },
  {
    label: "ROI",
    field: "roi",
  },
];

export const linksTableHeaders = [
  {
    label: "Link",
    field: "linkUid",
  },
  {
    label: "Type",
    field: "actionName",
  },
  {
    label: "FTD",
    field: "ffd",
  },
  {
    label: "CountAll",
    field: "countAll",
  },
  {
    label: "Posted",
    field: "count",
  },
  {
    label: "Сoins",
    field: "coins",
  },
  {
    label: "Limit",
    field: "dailyLimit",
  },
  {
    label: "Payout",
    field: "payout",
  },
  {
    label: "Investment",
    field: "investment",
  },
  {
    label: "Turnover",
    field: "turnover",
  },
  {
    label: "Roi",
    field: "roi",
  },
];

export const websTableHeaders = [
  {
    label: "Web",
    field: "web",
  },
  {
    label: "Type",
    field: "actionName",
  },
  {
    label: "FTD",
    field: "ffd",
  },
  {
    label: "CountAll",
    field: "countAll",
  },
  {
    label: "Posted",
    field: "count",
  },
  {
    label: "Сoins",
    field: "coins",
  },
  {
    label: "Limit",
    field: "dailyLimit",
  },
  {
    label: "Payout",
    field: "payout",
  },
  {
    label: "Investment",
    field: "investment",
  },
  {
    label: "Turnover",
    field: "turnover",
  },
  {
    label: "Roi",
    field: "roi",
  },
];

export const subsTableHeaders = [
  {
    label: "Sub",
    field: "sub",
  },
  {
    label: "Type",
    field: "actionName",
  },
  {
    label: "FTD",
    field: "ffd",
  },
  {
    label: "CountAll",
    field: "countAll",
  },
  {
    label: "Posted",
    field: "count",
  },
  {
    label: "Сoins",
    field: "coins",
  },
  {
    label: "Payout",
    field: "payout",
  },
  {
    label: "Investment",
    field: "investment",
  },
  {
    label: "Turnover",
    field: "turnover",
  },
  {
    label: "Roi",
    field: "roi",
  },
];
