import styled from 'styled-components';

export const Container = styled.div`
  .logo {
    padding-left: 20px;
    margin-top: 20px;
  }
  .md-form {
    margin-bottom: 0;
  }
  .side-nav.wide .collapsible-body a {
    padding-left: 23px;
    background-color: transparent;
  }
  .preloader-wrapper {
    margin-left: calc(50% - 50px);
  }
  .btn-container {
    display: flex;
    align-items: center;
  }
`;