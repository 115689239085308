/* eslint-disable react-hooks/exhaustive-deps */
import { MDBBtn, MDBCardText, MDBInput, MDBSpinner } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';
import { failureOptions, siteUrlsConfig, succesOptions } from '../../../../configs';
import http from '../../../../services/http/affiliates';

const CommentField = ({item, choosedSiteId}) => {
  const [isEditing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions)

  useEffect(() => {
    setInputValue(item.comment);
  }, []);

  const onEditClick = () => {
    setEditing(true);
    // setInputValue(item.comment);
  }

  const onSaveClick = async () => {
    setLoading(true);
    try {
      await http.post(`/api/${siteUrlsConfig[choosedSiteId]}/AddAffComment/${item.userId}/${inputValue || '%20'}`)
      openSuccessSnackbar('Comment has been edited')
    } catch (err) {
      openFailureSnackbar('Something went wrong...')
    }
    setLoading(false);
    setEditing(false);
  }

  return (
    <Fragment>
      {
        !isEditing ? (
          <Fragment>
            <MDBCardText>{inputValue || '-'}</MDBCardText>
            <MDBBtn size="sm" onClick={onEditClick}>Edit</MDBBtn>
          </Fragment>
        ) : (
          <Fragment>
            <MDBInput onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
            {!loading ? <MDBBtn size="sm" onClick={onSaveClick}>Save</MDBBtn> : <MDBSpinner small />}
          </Fragment>
        )
      }  
    </Fragment>
  )
}

const mapStateToProps = ({app}) => ({
  choosedSiteId: app.choosedSiteId,
});

export default connect(mapStateToProps, null)(CommentField);
