import React from "react";
import _ from 'lodash';
import {
  MDBTable,
  MDBContainer,
  MDBRow,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBSpinner,
} from "mdbreact";

import { ROLES } from "../../../../constants";

import Deactivate from "../../../../components/Deactivate";

import * as S from "./styled";

const UsersTable = ({ users = [], loading, deleteUser }) => {
  const [activeTab, setActiveTab] = React.useState("0");

  const isRoleFit = (user) => {
    const findRole = user.roles.find((r) => r.role.toString() === activeTab);
    return Boolean(findRole);
  };

  const sortedUsers = _.orderBy(users, (u) => u.email, 'asc');

  return (
    <MDBContainer className="mt-4">
      <MDBRow>
        <S.Container className="classic-tabs w-100">
          <MDBNav classicTabs color="primary">
            {Object.keys(ROLES).map((role) => (
              <MDBNavItem key={role}>
                <MDBNavLink
                  to="#"
                  style={{
                    borderColor:
                      activeTab === role.toString() ? "white" : "#4285f4",
                  }}
                  onClick={() => {
                    setActiveTab(role.toString());
                  }}
                >
                  {ROLES[role]}
                </MDBNavLink>
              </MDBNavItem>
            ))}
          </MDBNav>
          <MDBTabContent className="card" activeItem={activeTab}>
            {Object.keys(ROLES).map((role) => (
              <MDBTabPane key={role} tabId={role.toString()}>
                {!loading ? (
                  <MDBTable responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Deactivate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedUsers
                        .filter((u) => isRoleFit(u))
                        .map((user) => (
                          <>
                            {!user.isDeleted && (
                              <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{ROLES[user.roles[0].role]}</td>
                                <Deactivate
                                  onDelete={() => deleteUser(user.id)}
                                />
                              </tr>
                            )}
                          </>
                        ))}
                    </tbody>
                  </MDBTable>
                ) : (
                  <MDBSpinner className="spin" />
                )}
              </MDBTabPane>
            ))}
          </MDBTabContent>
        </S.Container>
      </MDBRow>
    </MDBContainer>
  );
};

export default UsersTable;
