import React from 'react';
import { MDBContainer, MDBCol, MDBRow} from 'mdbreact';

const MainContainer = ({children}) => (
  <MDBContainer fluid>
    <MDBRow middle center>
      <MDBCol middle md="6">
        {children}
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);

export default MainContainer;
