import React from 'react';
import { MDBContainer} from 'mdbreact';

import Table from './Table';

class AffSalesPage extends React.Component  {

  render() {
    return (
      <MDBContainer fluid id='v6' className='mb-5'>
        <Table />
      </MDBContainer>
    );
  }
}

export default AffSalesPage;