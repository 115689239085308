import {all, fork} from 'redux-saga/effects';

import * as authWatchers from './auth';

export default function* root() {
  yield all([
    fork(authWatchers.watchSignIn),
    fork(authWatchers.watchLogOut),
  ]);
}
