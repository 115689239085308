import {combineReducers} from 'redux';

import auth from './auth';
import app from './app';
import users from './users';

const rootReducer = combineReducers({
  auth,
  app,
  users,
});

export default rootReducer;
