import React, { useEffect, useMemo, useState } from "react";
import {
  MDBSelect,
  MDBCol,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBCardFooter,
} from "mdbreact";
import Select from "react-select";

const values = ["24-", "25-34", "35-44", "45-54", "55-64", "65+"];

const AgeFilter = ({ onChange, value }) => {
  return (
    <>
      <span>Age</span>
      <Select
        isSearchable={false}
        styles={{ control: (s) => ({ ...s, marginTop: 5 }) }}
        placeholder={"Age"}
        options={values.map((s) => ({ label: s, value: s }))}
        isMulti
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default AgeFilter;
