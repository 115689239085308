import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import { MDBRow, MDBCol, MDBCard, MDBSpinner, MDBBtn, MDBIcon, MDBCardHeader, MDBCardBody } from 'mdbreact';
import { Line } from 'react-chartjs-2';

import http from '../../../../services/http';

import * as S from './styled';
import SignalR from '../../../../services/signalR';
import CardBlock from './CardBlock';

const GRAPH_OPTIONS = {
  fill: true,
  lineTension: 0.3,
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "rgb(205, 130,1 58)",
  pointBackgroundColor: "rgb(255, 255, 255)",
  pointBorderWidth: 10,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "rgb(0, 0, 0)",
  pointHoverBorderColor: "rgba(220, 220, 220,1)",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

class Dashboard extends React.Component {
  state = {
    loading: false,
    dataLine: {
      labels: [],
      datasets: [
        {
          ...GRAPH_OPTIONS,
          label: "Fill profiles",
          backgroundColor: "rgba(225, 204, 230, .3)",
          borderColor: "rgb(205, 130, 158)",
          data: []
        },
      ]
    },
    dataLine1: {
      labels: [],
      datasets: [
        {
          ...GRAPH_OPTIONS,
          label: "Sales",
          backgroundColor: "rgba(225, 255 ,230, .3)",
          borderColor: "rgb(205, 255, 158)",
          data: []
        },
      ]
    },
    todayPurchase: 0,
    todayPurchaseLoading: false,
    todayFillProfile: 0,
    todayFillProfileLoading: false,
    yesterdayPurchase: 0,
    yesterdayPurchaseLoading: false,
    yesterdayFillProfile: 0,
    yesterdayFillProfileLoading: false,
    yesterdayTotalAmount: 0,
    yesterdayTotalAmountLoading: false,
    todayTotalAmount: 0,
    todayTotalAmountLoading: false,
    todayConfirmedEmails: 0,
    todayConfirmedEmailsLoading: false,
    yesterdayConfirmedEmails: 0,
    conversationInfo: {
      yesterdayFpEc: 0,
      todayFpEc: 0,
      FpEc: [],
      yesterdayEcFb: 0,
      todayEcFb: 0,
      EcFb: [],
      yesterdayFbRb: 0,
      todayFbRb: 0,
      FbRb: [],
    }
  };


  async componentDidMount() {
    await this.fetchInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.choosedSiteId !== prevProps.choosedSiteId) {
      this.fetchInfo();
    }
  }

  fetchInfo = async () => {
    try {
      const {dataLine, dataLine1} = this.state;
      const {choosedSiteId} = this.props;
      this.setState({loading: true, todayPurchaseLoading: true, todayFillProfileLoading: true, yesterdayPurchaseLoading: true, yesterdayFillProfileLoading: true, yesterdayTotalAmountLoading: true, todayConfirmedEmailsLoading: true})

      //FILL PROFILE FIRST BLOCK
      const {data: yesterdayFillProfile} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'FillProfile',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });
      this.setState({yesterdayFillProfile: yesterdayFillProfile.count, yesterdayFillProfileLoading: false});

      const {data: todayFillProfile} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'FillProfile',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      this.setState({todayFillProfile: todayFillProfile.count, todayFillProfileLoading: false});
      
      // EMAIL CONFIRMED SECOND BLOCK
      const {data: todayConfirmedEmails} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'emailconfirmed',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      const {data: yesterdayConfirmedEmails} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'emailconfirmed',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });

      this.setState({todayConfirmedEmails: todayConfirmedEmails.count, yesterdayConfirmedEmails: yesterdayConfirmedEmails.count, todayConfirmedEmailsLoading: false})
  
      // PURCHASE THIRD BLOCK
      const {data: todayPurchase} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'Purchase',
          Date: moment().format('YYYY-MM-DD'),
        }
      });
      this.setState({todayPurchase: todayPurchase.count, todayPurchaseLoading: false});
    
      const {data: yesterdayPurchase} = await http.get('/reports/report4', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'Purchase',
          Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        }
      });
      this.setState({yesterdayPurchase: yesterdayPurchase.count, yesterdayPurchaseLoading: false});
  
      const {data: fillCount} = await http.get('/reports/report3', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'FillProfile',
        }
      })

      const {data: purchaseCount} = await http.get('/reports/report3', {
        params: {
          SiteId: choosedSiteId,
          ActionType: 'Purchase',
        }
      })
    
      const [{ data: yesterdayTotal }, { data: todayTotal }] = await Promise.all(
        [
          http.get('/reports/report5', 
            {
              params: {
                SiteId: choosedSiteId,
                Date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              }
            },
            
          ),
          http.get('/reports/report5', {
            params: {
              SiteId: choosedSiteId,
              Date: moment().format('YYYY-MM-DD'),
            }
          })
        ]
      );
      this.setState({yesterdayTotalAmount: yesterdayTotal.amount, todayTotalAmount: todayTotal.amount, yesterdayTotalAmountLoading: false });

      this.setState({
        dataLine: {
          ...dataLine,
          labels: fillCount.map(item => moment(item.date).format('DD/MM')),
          datasets: [
            {
              ...dataLine.datasets[0],
              data: fillCount.map(item => item.count),
            },
          ]
        },
        dataLine1: {
          ...dataLine1,
          labels: purchaseCount.map(item => moment(item.date).format('DD/MM')),
          datasets: [
            {
              ...dataLine1.datasets[0],
              data: purchaseCount.map(item => item.count),
            }
          ]
        },
        loading: false,
      })

      const {data: conversationInfo1} = await http.get('/reports/report6', {
        params: {
          SiteId: choosedSiteId,
          From: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          To: moment().format('YYYY-MM-DD'),
          Action1: 'EmailConfirmed',
          Action2: 'FillProfile',
        }
      });

      this.setState({
        conversationInfo: {
          ...this.state.conversationInfo,
          yesterdayFpEc: conversationInfo1[0].conversion,
          todayFpEc: conversationInfo1[1].conversion,
          FpEc: [...conversationInfo1.reverse()],
        }
      })
    
      const {data: conversationInfo2} = await http.get('/reports/report7', {
        params: {
          SiteId: choosedSiteId,
          From: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          To: moment().format('YYYY-MM-DD'),
        }
      });
  
      this.setState({
        conversationInfo: {
          ...this.state.conversationInfo,
          yesterdayEcFb: conversationInfo2[0].conversion,
          todayEcFb: conversationInfo2[1].conversion,
          EcFb: [...conversationInfo2.reverse()]
        }
      })
  
      const {data: conversationInfo3} = await http.get('/reports/report8', {
        params: {
          SiteId: choosedSiteId,
          From: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          To: moment().format('YYYY-MM-DD'),
        }
      });

      this.setState({
        conversationInfo: {
          ...this.state.conversationInfo,
          yesterdayFbRb: conversationInfo3[0].conversion,
          todayFbRb: conversationInfo3[1].conversion,
          FbRb: [...conversationInfo3.reverse()]
        }
      })
    } catch (err) {
    }
  }

  onNewMessage = (message) => {
    if (this.props.choosedSiteId !== message.siteId) {
      return;
    }
    if (message.type.name === "purchase") {
      this.setState(prevState => {
        return {
          ...prevState,
          todayTotalAmount: prevState.todayTotalAmount + (+message.value),
          todayPurchase: prevState.todayPurchase + 1,
        }
      })
    }
    if (message.type.name === "emailconfirmed") {
      this.setState(prevState => {
        return {
          ...prevState,
          todayConfirmedEmails: prevState.todayConfirmedEmails + 1,
        }
      })
    }
    if (message.type.name === "fillprofile") {
      this.setState(prevState => {
        return {
          ...prevState,
          todayFillProfile: prevState.todayFillProfile + 1,
        }
      })
    }
  };

  render() {
    return (
      <S.Container>
        <SignalR onNewMessage={(m) => this.onNewMessage(m)} />
        <section className='mb-4'>
          <MDBRow>
            <CardBlock
              label="Fill Profile"
              todayAmount={this.state.todayFillProfile}
              yesterdayAmount={this.state.yesterdayFillProfile}
              icon="users"
              color="primary"
            />
            <CardBlock
              label="Confirmed"
              todayAmount={this.state.todayConfirmedEmails}
              yesterdayAmount={this.state.yesterdayConfirmedEmails}
              icon="envelope"
              color="warning"
            />
            <CardBlock
              label="Sales"
              todayAmount={this.state.todayPurchase}
              yesterdayAmount={this.state.yesterdayPurchase}
              icon="dollar-sign"
              color="info"
            />
            <CardBlock
              label="Amount"
              todayAmount={this.state.todayTotalAmount}
              yesterdayAmount={this.state.yesterdayTotalAmount}
              icon="database"
              color="danger"
            />
          </MDBRow>
      </section>
      <section>
        <MDBRow center>
          <MDBCol md='6' xl='6'>
          <MDBCard className='mb-4'>
            <MDBCardHeader className='primary-color text-white'>
              Conversion
            </MDBCardHeader>
            <MDBCardBody>
              
              <MDBRow className='mb-1'>
              <MDBCol col='4'>
                  <small className='grey-text'>Day</small>
                  {this.state.conversationInfo.FpEc.map(e => <h5>{moment(e.date).format('DD.MM')}</h5>)}
                </MDBCol>
                <MDBCol col='4'>
                  <small className='grey-text'>FP/ EC</small>
                  {this.state.conversationInfo.FpEc.map(e => <h5>{e.conversion}%</h5>)}
                </MDBCol>
                <MDBCol col='4'>
                  <small className='grey-text'>EC/ FB</small>
                  {this.state.conversationInfo.EcFb.map(e => <h5>{e.conversion}%</h5>)}
                </MDBCol>
                <MDBCol col='4'>
                  <small className='grey-text'>FB/ RB</small>
                  {this.state.conversationInfo.FbRb.map(e => <h5>{e.conversion}%</h5>)}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
      <section className='mb-4'>
            <MDBRow center>
                <MDBCol md='6' xl='6'>
                  <MDBCard cascade className='gradient-card-header white card-chart'>
                    {!this.state.loading ? <Line height={200} data={this.state.dataLine} options={{ responsive: true }} /> : <MDBSpinner className="spin" />}
                  </MDBCard>
                </MDBCol>
                <MDBCol md='6' xl='6'>
                  <MDBCard cascade className='gradient-card-header white card-chart'>
                  {!this.state.loading ? <Line height={200} data={this.state.dataLine1} options={{ responsive: true }} /> : <MDBSpinner className="spin" />}
                  </MDBCard>
                </MDBCol>
            </MDBRow>
      </section>
      </S.Container>
    )
  }
}

const mapStateToProps = ({app}) => ({
  choosedSiteId: app.choosedSiteId,
});

export default withSnackbar(connect(mapStateToProps, null)(Dashboard));