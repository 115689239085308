import { MDBBtn, MDBCol, MDBInput, MDBCard, MDBRow, MDBSpinner, MDBCardText } from 'mdbreact';
import React from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { failureOptions, siteUrlsConfig } from '../../../../configs';
import http from '../../../../services/http/affiliates';

const CheckUser = ({choosedSiteId}) => {
  const [input, setInput] = React.useState('');
  const [isFound, setFound] = React.useState(false);
  const [foundUser, setFoundUser] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const checkUser = async () => {
    setLoading(true);
    setFoundUser({});
    setFound(false);
    try {
      const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/CheckUser/${input}`);
      setFoundUser(data.length ? data[0] : {});
      setFound(Boolean(data.length));
      if (!Boolean(data.length)) {
        openFailureSnackbar('Invalid input');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  return (
    <MDBCard className='p-3 mb-5'>
      <MDBCardText>Check user</MDBCardText>
      <MDBCol lg='6' md='6'>
      <MDBInput
        required
        label="Enter ID or Email"
        group
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
        <MDBBtn onClick={checkUser}>Check</MDBBtn>
        <MDBRow className="mt-5">
          {
            loading ?  <MDBSpinner className="spinner-container" /> : null
          }
          {
            isFound ? (
              <>
                <MDBCol lg='6' md='6'>
                  <img src={foundUser?.imgUrl} />
                </MDBCol>
                <MDBCol lg='6' md='6'>
                  <ul>
                    <li><strong>ID:</strong> {foundUser.id}</li>
                    <li><strong>Email:</strong> {foundUser.email}</li>
                    <li><strong>Name:</strong> {foundUser.name}</li>
                    <li><strong>Location:</strong> {foundUser.location}</li>
                  </ul>
                </MDBCol>
              </>
            ) : null
          }
        </MDBRow>
      </MDBCol>
    </MDBCard>
  )
}

export default CheckUser;
