import styled from 'styled-components';

export const Container = styled.div`
  tbody {
    tr {
      height: 130px !important;
    }
  }
`;
export const RowsTest = styled.div`
  position: absolute;
  /* left: 0; */
  top: 0;
  width: 2rem;
  height: 100%;
  padding-top: 100px;
`;

export const Index = styled.div`
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;