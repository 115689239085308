import React from 'react';
import { MDBContainer } from 'mdbreact';

import PartnerReport from './components/PartnerReport';

import * as S from './styled';

class PartnerReport5Page extends React.Component  {
  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <PartnerReport />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default PartnerReport5Page;