import { formNavigation } from "./helpers";

const TOGGLE_SIDEBAR = 'app/TOGGLE_SIDEBAR';
const TOGGLE_FOLDER = 'app/TOGGLE_FOLDER';
const CHOOSE_SITE = 'app/CHOOSE_SITE';
const TOGGLE_HEADER_DATE = 'app/TOGGLE_HEADER_DATE';

export const initialState = {
  sidebarIsOpen: false,
  choosedSiteId: 3,
  isDateHeaderShow: false,
  dateHeader: '',
  navigation: {
    'reports': {
      icon: "tachometer-alt",
      name: "Reports",
      isOpen: true,
    },
    'affiliates': {
      icon: "home",
      name: "Affiliates",
      isOpen: true,
    },
    'partners': {
      icon: "user",
      name: "Partners",
      isOpen: true,
    },
    'users': {
      icon: "user",
      name: "Users",
      isOpen: true,
    },
    'sites': {
      icon: "image",
      name: "Sites",
      isOpen: true,
    },
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {...state, sidebarIsOpen: !state.sidebarIsOpen};

    case TOGGLE_FOLDER:
      return formNavigation(state, action.field)

    case CHOOSE_SITE:
      return {...state, choosedSiteId: action.choosedSiteId}
    
    case TOGGLE_HEADER_DATE:
      return { ...state, dateHeader: action.dateHeader, isDateHeaderShow: action.flag }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const toggleSideBar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const toggleFolder = (field) => ({
  type: TOGGLE_FOLDER,
  field,
});

export const chooseSite = (siteId) => ({
  type: CHOOSE_SITE,
  choosedSiteId: siteId,
});

export const toggleHeaderDate = (date, flag) => ({
  type: TOGGLE_HEADER_DATE,
  dateHeader: date,
  flag
})
