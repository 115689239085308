import React from 'react';

import CreateSite from './components/CreateSite';
import MainContainer from '../../components/MainContainer';

class CreateSitePage extends React.Component  {

  render() {
    return (
      <MainContainer>
        <CreateSite />
      </MainContainer>
    );
  }
}

export default CreateSitePage;