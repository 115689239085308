import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalBody,
  MDBInput,
} from "mdbreact";
import { TABLE_HEADERS, groupParams } from "./configs";
import { useSelector } from "react-redux";

const filters = [
  {
    label: "Date",
    id: "date",
  },
  {
    label: "LinkId",
    id: "linkid",
  },
  {
    label: "WebId",
    id: "web_id",
  },
  {
    label: "SubId",
    id: "sub_id",
  },
  {
    label: "Age",
    id: "age",
  },
  {
    label: "Country",
    id: "ipinfo_country",
  },
  {
    label: "Utm_Source",
    id: "utm_source",
  },
  {
    label: "Utm_Term",
    id: "utm_term",
  },
  {
    label: "Utm_Campaign",
    id: "utm_campaign",
  },
  {
    label: "OS",
    id: "os",
  },
  {
    label: "Landing",
    id: "path",
  },
  {
    label: "Partner",
    id: "partner",
  },
  {
    label: "Utm_Content",
    id: "utm_content",
  },
];

function SettingRow({ group, setting, onChangeSetting, isEnabled }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ marginBottom: 10 }}>{setting.label}</div>
      <MDBInput
        onChange={() => onChangeSetting(group, setting.id)}
        checked={isEnabled}
        type="checkbox"
        id={`checkbox-${group}-${setting.id}`}
      />
    </div>
  );
}

const settingsInitial = {
  groups: [...groupParams],
  columns: [...TABLE_HEADERS.map((h) => ({ ...h, label: h.header }))],
  filters,
};

export default function SettingsModal({
  visible,
  onClose,
  settingsConfig,
  onChangeSetting,
  toggleAllSelectSettings,
}) {
  const choosedSiteId = useSelector((state) => state.app.choosedSiteId);

  const settings = {
    ...settingsInitial,
    columns: [...settingsInitial.columns.filter(h => {
      if (h.id === "fp4s" || h.id === "sms4Plus35") {
        return choosedSiteId === 37
      }
      return true;
    })]
  }
  const [isMobile] = useState(true);

  const [selectedMobileTab, setSelectedMobileTab] = useState(
    Object.keys(settings)[0]
  );

  const isSelectedAll =
    Object.values(settings).reduce(
      (sum, currentArray) => sum + currentArray.length,
      0
    ) ===
    Object.values(settingsConfig).reduce(
      (sum, currentArray) => sum + currentArray.length,
      0
    );

  const onToggleAllSelectSettings = () => {
    if (!isSelectedAll) {
      const newConfig = {
        groups: [...settings.groups.map((i) => i.id)],
        columns: [...settings.columns.map((i) => i.id)],
        filters: [...settings.filters.map((i) => i.id)],
      };
      toggleAllSelectSettings(newConfig);
    } else {
      const newConfig = {
        groups: [],
        columns: [],
        filters: [],
      };
      toggleAllSelectSettings(newConfig);
    }
  };

  const isGroupSelected = (group) => !settingsConfig[group].length;

  const onToggleGroupSetting = (group) => {
    const isSelected = isGroupSelected(group);
    if (isSelected) {
      const newConfig = {
        ...settingsConfig,
        [group]: [...settings[group].map((i) => i.id)],
      };
      toggleAllSelectSettings(newConfig);
    } else {
      const newConfig = {
        ...settingsConfig,
        [group]: [],
      };
      toggleAllSelectSettings(newConfig);
    }
  };
  return (
    <MDBModal isOpen={visible} toggle={onClose}>
      <MDBModalHeader toggle={onClose}>Settings</MDBModalHeader>
      <MDBModalBody>
        <div style={{ display: "flex" }}>
          {!isSelectedAll ? "Unselect all" : "Select all"}
          <MDBInput
            onChange={onToggleAllSelectSettings}
            checked={!isSelectedAll}
            type="checkbox"
            id={`checkbox-all`}
          />
        </div>
        <div style={{ flex: 1, display: "flex", minHeight: !isMobile ? 200 : 50 }}>
          {Object.keys(settings).map((key) => (
            <>
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                    {!isMobile ? (
                      key.toUpperCase()
                    ) : (
                      <div
                        onClick={() => setSelectedMobileTab(key)}
                        style={{
                          cursor: 'pointer',
                          borderBottom:
                            selectedMobileTab === key
                              ? "2px solid blue"
                              : "none",
                        }}
                      >
                        {key.toUpperCase()}
                      </div>
                    )}
                  </div>
                  <MDBInput
                    onChange={() => onToggleGroupSetting(key)}
                    checked={isGroupSelected(key)}
                    type="checkbox"
                    id={`checkbox-${key}-group`}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        {
          settings[selectedMobileTab].map((setting) => (
            <SettingRow
              isEnabled={!settingsConfig[selectedMobileTab].includes(setting.id)}
              onChangeSetting={onChangeSetting}
              group={selectedMobileTab}
              setting={setting}
            />
          ))
        }
      </MDBModalBody>
    </MDBModal>
  );
}
