import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider}  from 'react-redux';
import SnackbarProvider from 'react-simple-snackbar'
import moment from 'moment';

import TokenStorage from './services/storage/token';
import UserStorage from './services/storage/user';
import SiteStorage from './services/storage/site';
import configureStore from './store/configureStore';
import { initialState as authInitialState } from './store/auth';
import { initialState as appInitialState } from './store/app';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './App.css';
import './index.css';

import AppRoute from './router/AppRoute';
import { getExpTimeFromToken } from './helpers';


const token = TokenStorage.get();
const user = UserStorage.get();
const siteId = SiteStorage.get();
const initialState = {
  auth: {
    ...authInitialState,
    // authenticated: !!token && (moment(moment().unix()).isBefore(getExpTimeFromToken(token))),
    authenticated: !!token,
    user: user,
  },
  app: {
    ...appInitialState,
    choosedSiteId: Boolean(siteId) ? siteId : 3,
  }
};

const store = configureStore(initialState);

ReactDOM.render(
    <Provider store={store}>
      <SnackbarProvider>
        <React.StrictMode>
          <Router>
            <AppRoute />
          </Router>
        </React.StrictMode>
      </SnackbarProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();