import React from 'react';

import * as S from './styled';

const SearchInput = ({onInputChange = () => {}}) => {
  return (
    <S.SearchContainer>
      <input onChange={e => onInputChange(e.target.value)} placeholder="Search" className="form-control form-control-sm ml-0 my-1" />
    </S.SearchContainer>
  )
};

export default SearchInput;
