import React, { useEffect, useRef, useState } from "react";
import { MDBCol, MDBRow, MDBInput } from "mdbreact";
import moment from "moment";

import * as S from "./styled";

const defaultFormat = "YYYY-MM-DD";

const options = [
  {
    label: "Today",
    value: {
      from: moment().format(defaultFormat),
      to: moment().format(defaultFormat),
    },
  },
  {
    label: "Yesterday",
    value: {
      from: moment().subtract(1, "days").format(defaultFormat),
      to: moment().subtract(1, "days").format(defaultFormat),
    },
  },
  {
    label: "Last 7 days",
    value: {
      from: moment().subtract(6, "days").format(defaultFormat),
      to: moment().format(defaultFormat),
    },
  },
  {
    label: "Last 30 days",
    value: {
      from: moment().subtract(29, "days").format(defaultFormat),
      to: moment().format(defaultFormat),
    },
  },
  {
    label: "This month",
    value: {
      from: moment().startOf("month").format(defaultFormat),
      to: moment().format(defaultFormat),
    },
  },
  {
    label: "Last month",
    value: {
      from: moment()
        .subtract(1, "month")
        .startOf("month")
        .format(defaultFormat),
      to: moment().subtract(1, "month").endOf("month").format(defaultFormat),
    },
  },
  {
    label: "Last 90 days",
    value: {
      from: moment().subtract(89, "days").format(defaultFormat),
      to: moment().format(defaultFormat),
    },
  },
];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

const DateInput = ({
  value,
  onChange,
  loadData = () => {},
}) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const prevOpen = usePrevious(isOpen);

  useEffect(() => {
    const selected = options.find(
      (o) => JSON.stringify(value) === JSON.stringify(o.value)
    );
    if (selected) {
      setSelectedLabel(selected.label);
    }
  }, []);

  useEffect(() => {
    if (prevOpen && !isOpen) {
      loadData();
    }
  }, [prevOpen, isOpen])
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onSelect = (val, label) => {
    onChange(val);
    setSelectedLabel(label);
  };

  const onCustomChange = (val, type) => {
    setSelectedLabel(null);
    onChange({
      ...value,
      [type]: val,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <MDBCol lg="3" md="6">
      <S.Container ref={ref}>
        <S.Input onClick={isOpen ? handleClose : handleOpen}>
          {moment(value.from, defaultFormat).format("DD-MM-YYYY")} -{" "}
          {moment(value.to, defaultFormat).format("DD-MM-YYYY")}
        </S.Input>
        {isOpen ? (
          <S.ItemsContainer>
            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  required
                  label="From"
                  type="date"
                  group
                  value={value.from}
                  onChange={(e) => onCustomChange(e.target.value, "from")}
                />
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  required
                  label="To"
                  type="date"
                  group
                  value={value.to}
                  onChange={(e) => onCustomChange(e.target.value, "to")}
                />
              </MDBCol>
            </MDBRow>
            {options.map((option) => (
              <MDBRow>
                <S.Option
                  onClick={() => onSelect(option.value, option.label)}
                  // isSelected={
                  //   JSON.stringify(option.value) === JSON.stringify(value)
                  // }
                  isSelected={selectedLabel === option.label}
                >
                  {option.label}
                </S.Option>
              </MDBRow>
            ))}
          </S.ItemsContainer>
        ) : null}
      </S.Container>
    </MDBCol>
  );
};

export default DateInput;
