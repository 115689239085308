import React, { useState } from "react";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { sortBy } from "../../utils";

import * as S from "./styled";

const BaseTable = ({
  headers,
  rowRender,
  data,
  initialSort = {
    field: "",
    direction: "",
  },
  rowKey = '',
}) => {
  const [sorting, setSorting] = useState(initialSort);

  const onSortClick = (header) => {
    setSorting({
      field: header,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  const sortedData = sortBy(data, sorting.field, sorting.direction);

  return (
    <MDBTable striped responsive>
      <MDBTableHead>
        <tr>
          {headers.map((header) => (
            <S.Th
              key={header.field}
              direction={sorting.direction}
              isSorting={sorting.field === header.field}
              onClick={() => onSortClick(header.field)}
            >
              <u>{header.label}</u>
            </S.Th>
          ))}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {sortedData.map((item) => (
          <React.Fragment key={item[rowKey]}>{rowRender(item)}</React.Fragment>
        ))}
      </MDBTableBody>
    </MDBTable>
  );
};

export default BaseTable;
