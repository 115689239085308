import React from 'react';
import { MDBContainer } from 'mdbreact';

import MegaChatTable from './components/MegaChatTable';

import * as S from './styled';

class MegaChatPage extends React.Component  {
  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <MegaChatTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default MegaChatPage;