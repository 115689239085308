import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
} from "mdbreact";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { failureOptions, succesOptions } from "../../../../configs";
import http from "../../../../services/http";
import Select from "react-select";

import { renderRestrictionEdit } from "../helpers";
import TestModal from "../TestModal";

const currencies = [
  {
    name: "Eur",
    value: 1,
  },
  {
    name: "Rub",
    value: 2,
  },
  {
    name: "Usd",
    value: 0,
  },
];

const AddRow = ({
  setAdding,
  choosedSiteId,
  getInfo,
  userId,
  landings,
  operators,
  rows,
  copyRow,
  resetCopyRow,
  actions,
}) => {
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions);
  const [isTestModalOpen, setTestModalOpen] = useState(false);

  const geoOperators = [
    { value: 0, text: "=" },
    { value: 1, text: "Not" },
    { value: 6, text: "In list" },
    { value: 7, text: "Not in list" },

  ];

  const ageOperators = [
    { value: 0, text: "=" },
    { value: 2, text: ">" },
    { value: 3, text: ">=" },
    { value: 4, text: "<" },
    { value: 5, text: "<=" },
  ];

  const [restrictions, setRestrictions] = useState([]);

  const initialRow = {
    siteId: 0,
    actionTypeId: 0,
    partnerLinkId: 0,
    postback: "",
    payout: 0,
    currency: "Usd",
    isActive: true,
    linkName: "",
    uid: "",
    siteLanding: null,
    dailyLimit: 0,
  };

  const [loading, setLoading] = useState(false);
  const [row, setRow] = React.useState(initialRow);
  const [links, setLinks] = React.useState([]);
  const [isLinkNameDisabled, setLinkNameDisabled] = React.useState(false);

  React.useEffect(() => {
    getLinks();
  }, [userId]);

  const getSitelanding = (row) => {
    if (!row.siteLanding) {
      return landings[0]?.url || null;
    } else {
      return row.siteLanding.url;
    }
  };

  React.useEffect(() => {
    if (copyRow && landings.length && actions.length) {
      setRow({
        uid: copyRow?.partnerLink?.uid,
        linkName: copyRow?.partnerLink?.name,
        actionTypeId: actions.find((a) => a.id === copyRow?.actionType?.id)
          .name,
        siteLanding: getSitelanding(copyRow),
        postback: copyRow.postback,
        isActive: copyRow.isActive,
        currency: copyRow.currencyName,
        payout: copyRow.payout,
        dailyLimit: copyRow.dailyLimit,
      });
      setRestrictions(copyRow.restrictions);
    }
  }, [copyRow, landings, actions]);

  console.log('copyRow', copyRow);
  const getLinks = async () => {
    const { data: linksData } = await http.get("/partnerlinks/byuser", {
      params: {
        userId: userId,
      },
    });
    setLinks(linksData);
  };

  const onFieldChange = (value, prop) => {
    setRow({
      ...row,
      [prop]: value,
    });
  };

  const uniqueDealCheck = (payload) => {
    let isUnique = true;
    const { partnerLinkId, siteId, actionTypeId } = payload;
    const filtered = rows.filter(
      (row) =>
        row.siteId === siteId &&
        row.partnerLink?.id === partnerLinkId &&
        row.actionType?.id === actionTypeId
    );
    if (filtered.length) {
      isUnique = false;
    }
    return isUnique;
  };

  const onSavePress = async () => {
    setLoading(true);
    try {
      const isExistLink = links.find((l) => l.uid === row.uid);
      let payload = {
        siteId: choosedSiteId,
        actionTypeId: actions.find((a) => a.name === row.actionTypeId)?.id,
        postback: row.postback,
        payout: row.payout,
        currency: currencies.find((c) => c.name === row.currency)?.value,
        isActive:
          row.isActive === "true" || row.isActive === true ? true : false,
        dailyLimit: +row.dailyLimit,
        siteLandingId: row.siteLanding
          ? landings.find((l) => l.url === row.siteLanding).id
          : null,
      };
      if (isExistLink) {
        payload.partnerLinkId = isExistLink.id;
      } else {
        const { data: linkData } = await http.post("/partnerlinks", {
          userId: userId,
          name: row.linkName,
          uid: row.uid,
          // siteLandingId: row.siteLanding
          //   ? landings.find((l) => l.url === row.siteLanding).id
          //   : null,
        });
        payload.partnerLinkId = linkData.id;
      }
      const isUnique = uniqueDealCheck(payload);
      if (isUnique) {
        const { data } = await http.post("/partnerdeals", payload);
        await saveRestrictions(data.id);
        getInfo();
        setAdding(false);
        openSuccessSnackbar("Added");
      } else {
        openFailureSnackbar("This deal already exists");
      }
    } catch (err) {
      openFailureSnackbar("Error :(");
    }
    setLoading(false);
    resetCopyRow();
  };

  const onCancel = () => {
    setRow(initialRow);
    setAdding(false);
    setRestrictions([]);
    resetCopyRow();
  };

  const formatLandings = () => {
    if (!landings.length) return [];
    return landings.map((l) => ({
      label: l.url,
      value: l.url,
    }));
  };

  const onUidChange = (e) => {
    const isExistLink = links.find((l) => l.uid === e.target.value);
    if (!isExistLink) {
      setRow({
        ...row,
        uid: e.target.value,
        linkName: "",
      });
      setLinkNameDisabled(false);
    } else {
      setRow({
        ...row,
        uid: e.target.value,
        linkName: isExistLink.name,
        siteLanding: isExistLink.siteLandingId,
      });
      setLinkNameDisabled(true);
    }
  };

  const getSiteLanding = () => {
    const findLanding = landings.find((l) => l.id === row.siteLanding);
    if (findLanding) {
      return findLanding.url;
    }
    return null;
  };

  const saveRestrictions = async (partnerDealId) => {
    const addedPromises = restrictions.map((restriction) => {
      return http.post(`/partnerdeals/${partnerDealId}/restrictions`, {
        propertyName: restriction.propertyName,
        propertyValue: restriction.propertyValue,
        propertyOperator: restriction.propertyOperator,
      });
    });
    await Promise.all([...addedPromises]);
  };

  const onRestrictionChange = (type, property, value) => {
    const newRestrictions = [...restrictions];
    const findRestrictionIndex = newRestrictions.findIndex(
      (r) => r.propertyName === type
    );
    if (findRestrictionIndex > -1) {
      newRestrictions[findRestrictionIndex][property] = value;
      setRestrictions([...newRestrictions]);
    } else {
      setRestrictions([
        ...newRestrictions,
        {
          propertyName: type,
          [property]: value,
        },
      ]);
    }
  };

  const clearRestriction = (type) => {
    const newRestrictions = restrictions.filter((r) => r.propertyName !== type);
    setRestrictions(newRestrictions);
  };

  return (
    <>
      <TestModal
        visible={isTestModalOpen}
        onClose={() => setTestModalOpen(false)}
        onSave={onSavePress}
        actionType={row.actionTypeId}
      />
      <tr>
        {/* UUID */}
        <td>
          <input
            className="form-control form-control-sm"
            value={row.uid}
            onChange={onUidChange}
          />
        </td>
        {/* LinkName */}
        <td>
          <input
            className="form-control form-control-sm"
            value={row.linkName}
            onChange={(e) => onFieldChange(e.target.value, "linkName")}
            disabled={isLinkNameDisabled}
          />
        </td>
        {/* ActionType */}
        <td>
          <MDBSelect
            getTextContent={(val) => onFieldChange(val, "actionTypeId")}
            className="colorful-select dropdown-primary mx-2"
          >
            <MDBSelectInput selected={row.actionTypeId} />
            <MDBSelectOptions>
              {actions.map((action) => (
                <MDBSelectOption key={action.id} value={action.name}>
                  {action.name}
                </MDBSelectOption>
              ))}
            </MDBSelectOptions>
          </MDBSelect>
        </td>
        {/* Landing */}
        <td style={{ width: 250 }}>
          <Select
            isSearchable={false}
            placeholder={"Landing"}
            options={formatLandings()}
            value={formatLandings().find(l => l.value === row.siteLanding)}
            onChange={(val) => onFieldChange(val.value, "siteLanding")}
          />
        </td>
        {/* Afflink */}
        {/* <td style={{ width: 150 }}></td> */}
        {/* Postback */}
        <td>
          <input
            className="form-control form-control-sm"
            value={row.postback}
            onChange={(e) => onFieldChange(e.target.value, "postback")}
          />
        </td>
        {/* Payout */}
        <td>
          <input
            className="form-control form-control-sm"
            value={row.payout}
            onChange={(e) => onFieldChange(e.target.value, "payout")}
          />
        </td>
        {/* Daily limit */}
        <td>
          <input
            className="form-control form-control-sm"
            value={row.dailyLimit}
            onChange={(e) => {
              if (+e.target.value >= 0) {
                onFieldChange(e.target.value, "dailyLimit");
              }
            }}
          />
        </td>
        {/* Restrictions */}
        <td>
          {renderRestrictionEdit({
            label: "Age",
            type: "age",
            inputType: "number",
            onRestrictionChange,
            clearRestriction,
            operators: ageOperators,
            restrictions,
          })}
          {renderRestrictionEdit({
            label: "Geo",
            type: "ipinfo_country",
            inputType: "text",
            onRestrictionChange,
            clearRestriction,
            operators: geoOperators,
            restrictions,
          })}
        </td>
        {/* Currency */}
        {/* <td>
          <MDBSelect
            className="colorful-select dropdown-primary mx-2"
            getTextContent={(val) => onFieldChange(val, "currency")}
          >
            <MDBSelectInput selected={row.currency} />
            <MDBSelectOptions>
              {currencies.map((currency) => (
                <MDBSelectOption key={currency.id} value={currency.name}>
                  {currency.name}
                </MDBSelectOption>
              ))}
            </MDBSelectOptions>
          </MDBSelect>
        </td> */}
        {/* isActive */}
        <td>
          <MDBSelect
            className="colorful-select dropdown-primary mx-2"
            getTextContent={(val) => onFieldChange(val, "isActive")}
          >
            <MDBSelectInput selected={`${row.isActive}`} />
            <MDBSelectOptions>
              {[{ name: "true" }, { name: "false" }].map((action) => (
                <MDBSelectOption key={action.id} value={action.name}>
                  {action.name}
                </MDBSelectOption>
              ))}
            </MDBSelectOptions>
          </MDBSelect>
        </td>
        <td>
          <MDBBtn
            disabled={loading}
            color="success"
            size="sm"
            onClick={onSavePress}
            style={{ width: 100 }}
          >
            Save
          </MDBBtn>
          <MDBBtn
            style={{ width: 100 }}
            color="warning"
            size="sm"
            onClick={() => setTestModalOpen(true)}
          >
            Save and test
          </MDBBtn>
          <MDBBtn
            style={{ width: 100 }}
            color="danger"
            size="sm"
            onClick={onCancel}
          >
            Cancel
          </MDBBtn>
        </td>
      </tr>
    </>
  );
};

export default AddRow;
