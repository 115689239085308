/* eslint-disable no-unused-vars */
import React from 'react';
import {MDBInput, MDBBtn} from 'mdbreact';
import { useSnackbar } from 'react-simple-snackbar'

import http from '../../../../services/http';
import { failureOptions, succesOptions } from '../../../../configs';


const initialSiteForm = {
  name: '',
  domain: '',
};

const CreateSite = () => {
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const [siteForm, setSiteForm] = React.useState(initialSiteForm)

  const onInputChange = (value, inputType) => setSiteForm({
    ...siteForm,
    [inputType]: value,
  });

  const createSite = async () => {
    try {
      const {data} = await http.post('/sites', siteForm);
      console.log('data', data);
      openSuccessSnackbar('Created');
    } catch (err) {
      console.log('err', err);
      openFailureSnackbar('Failure');
    }
    setSiteForm(initialSiteForm)
  };

  return (
    <form>
    <p className="h5 text-center mb-4">Create Site Page</p>
    <div className="grey-text">
      <MDBInput
        onChange={(e) => onInputChange(e.target.value, 'name')}
        value={siteForm.name}
        required
        label="Site name"
        group
      />
      <MDBInput
        onChange={(e) => onInputChange(e.target.value, 'domain')}
        value={siteForm.domain}
        required
        label="Site domain"
        group
      />
    </div>
    <div className="text-center">
      <MDBBtn onClick={createSite}>Create</MDBBtn>
    </div>
  </form>
  )
}

export default CreateSite;