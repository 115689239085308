import React from 'react';
import { MDBInput, MDBSpinner, MDBCardBody,  MDBBtn, MDBDataTable, MDBCard, MDBRow, MDBCol } from 'mdbreact';
import _ from 'lodash';
import { connect } from 'react-redux';

import moment from 'moment';

import http from '../../../../services/http';

const PartnerReport = ({ choosedSiteId, user }) => {
  const [stats, setStats] = React.useState({
      columns: [],
      rows: [],
    });
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    From: moment().format('YYYY-MM-01'),
    To: moment().format('YYYY-MM-DD'),
  });

  const formatData = (data) => {
    return data.map(item => ({
      ...item,
      date: moment(item.date).format('YYYY-MM-DD'),
    }))
  }

  const onClick = async () => {
    setLoading(true);
    const {data} = await http.get('/reports/partner2', {
      params: {
        SiteId: choosedSiteId,
        UserId: parseInt(user.id),
        ...form,
      }
    })

    const totalCount = {
      purchases: _.sumBy(data, 'purchases'),
      fillProfiles: _.sumBy(data, 'fillProfiles'),
      fillProfilesPayout: _.sumBy(data, 'fillProfilesPayout'),
    };

    setStats({
      ...stats,
      columns: [
        {
          label: <div style={{fontWeight: 'bold'}}>Date</div>,
          field: 'date',
          width: 150,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Fill Profiles</div><div style={{fontStyle: 'italic'}}>({totalCount.fillProfiles})</div></div>,
          field: 'fillProfiles',
          width: 270,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Purchases</div><div style={{fontStyle: 'italic'}}>({totalCount.purchases})</div></div>,
          field: 'purchases',
          width: 200,
        },
        {
          label: <div><div style={{fontWeight: 'bold'}}>Fill Profiles Payout</div><div style={{fontStyle: 'italic'}}>({totalCount.fillProfilesPayout})</div></div>,
          field: 'fillProfilesPayout',
          width: 100,
        },
      ],
      rows: formatData(data),
    });
  
    setLoading(false);
  }
  React.useEffect(() => {
    onClick();
    setInitialLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading])
  return (
    <>
        <MDBCard className='p-3 mb-5'>
          <MDBRow>

            <MDBCol lg='3' md='6'>
            <MDBInput
              required
              label="From date"
              type="date"
              group
              value={form.From}
              onChange={(e) => setForm({
                ...form,
                From: e.target.value,
              })}
            />
            </MDBCol>

            <MDBCol lg='3' md='6'>
            <MDBInput
              required
              label="To date"
              group
              type="date"
              value={form.To}
              onChange={(e) => {
                console.log(e);
                setForm({
                  ...form,
                  To: e.target.value,
                })
              }}
            />
            </MDBCol>

            <MDBCol className="btn-container" lg='3' md='6'>
              <MDBBtn onClick={onClick}>Show</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCard>
        <MDBCard narrow className='pb-3'>
          <MDBCardBody>
            {!loading ? <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              data={stats}
              infoLabel={["Showing", "to", "of", "rows"]}
              entries={50}
              info={true}
            /> : <MDBSpinner className="spinner-container" />}
          </MDBCardBody>
        </MDBCard>
    </>
  )
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(PartnerReport);