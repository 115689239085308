import styled from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
    padding-right: 0;
    input {
      width: 165px;
    }
`;
