import React from 'react';

import { MDBCard, MDBCol, MDBRow, MDBCardBody, MDBSpinner, MDBDataTable } from 'mdbreact';

import http from '../../services/http/affiliates';
import { connect } from 'react-redux';
import { siteUrlsConfig } from '../../configs';
import Download from './Download';


const columns = [
  {
    label: 'ProfileId',
    field: 'profileId',
    width: 150,
  },
  {
    label: 'Conversions',
    field: 'conversions',
    width: 150,
  },
];

const Table = ({choosedSiteId}) => {

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    columns: columns,
    rows: [],
  });

  React.useEffect(() => {
    getData();
  }, [choosedSiteId]);

  const getData = async () => {
    setLoading(true);
    const {data} = await http.get(`/api/${siteUrlsConfig[choosedSiteId]}/GetHighRollers/`);
    setData({
      columns: columns,
      rows: data,
    });
    setLoading(false);
  }

  return (
    <>
      <MDBCard className='p-3 mb-5'>
        <MDBRow>

          <MDBCol className="btn-container" lg='3' md='6'>
            <Download data={data.rows} />
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <MDBCard narrow className='pb-3'>
          <MDBCardBody>
            {!loading ? <MDBDataTable
              responsive
              noBottomColumns
              displayEntries={false}
              searching={false}
              paging={false}
              data={data}
              info={false}
            /> : <MDBSpinner className="spin" />}
          </MDBCardBody>
        </MDBCard>
      
    </>
  )
}

const mapStateToProps = ({app, auth}) => ({
  choosedSiteId: app.choosedSiteId,
  user: auth.user,
});

export default connect(mapStateToProps, null)(Table);