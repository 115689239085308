import React from 'react';
import { MDBContainer } from 'mdbreact';

import AffLinksTable from './components/AffLinksTable';

import * as S from './styled';

class AffLinksPage extends React.Component  {

  render() {
    return (
      <S.Container>
        <MDBContainer fluid>
          <AffLinksTable />
        </MDBContainer>
      </S.Container>
    );
  }
}

export default AffLinksPage;