import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBSpinner, MDBBtn, MDBIcon } from 'mdbreact';

const CardBlock = ({todayAmount, yesterdayAmount, label, icon, color}) => {
  return (
    <MDBCol xl='3' md='6' className='mb-4 mb-r'>
      <MDBCard className="card-block">
        {true ?  <>
          <MDBRow className='mt-3'>
            <MDBCol md='5' size='5' className='text-left pl-4'>
              <MDBBtn
                tag='a'
                floating
                size='lg'
                color={color}
                className='ml-4'
                style={{ padding: 0 }}
              >
                <MDBIcon icon={icon} size='2x' />
              </MDBBtn>
            </MDBCol>
            <MDBCol md='7' col='7' className='text-right pr-5'>
              <h5 className='ml-4 mt-4 mb-2 font-weight-bold'>{todayAmount}</h5>
              <p className='font-small grey-text'>{label}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='my-3'>
            <MDBCol md='7' col='7' className='text-left pl-4'>
              <p className='font-small dark-grey-text font-up ml-4 font-weight-bold'>
                Yesterday
              </p>
            </MDBCol>
            <MDBCol md='5' col='5' className='text-right pr-5'>
              <p className='font-small grey-text'>{yesterdayAmount}</p>
            </MDBCol>
          </MDBRow>
        </> : <MDBSpinner className="spin" />}
      </MDBCard>
    </MDBCol>
  )
}

export default CardBlock;