const SET_PARTNERS = 'users/SET_PARTNERS';

export const initialState = {
  partners: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PARTNERS:
      return {...state, partners: action.partners};

    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const setPartners = partners => ({
  type: SET_PARTNERS,
  partners,
});

