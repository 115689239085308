import React from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';

const SurePopup = ({visible, onClose, onConfirm, isLoading}) => {
  return (
    <MDBModal isOpen={visible} toggle={onClose}>
      <MDBModalHeader toggle={onClose}>Are you sure?</MDBModalHeader>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={onClose}>Close</MDBBtn>
        <MDBBtn disabled={isLoading} color="primary" onClick={onConfirm}>Yes</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default SurePopup;