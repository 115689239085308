import React, { PureComponent } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { withSnackbar } from "react-simple-snackbar";
import { connect } from "react-redux";

import ProtectedRoute from "../ProtectedRoute";

import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";

import LoginPage from "../../pages/LoginPage";
import MainPage from "../../pages/MainPage";
import SitesPage from "../../pages/SitesPage";
import CreateSitePage from "../../pages/CreateSitePage";
import UsersPage from "../../pages/UsersPage";
import CreateUserPage from "../../pages/CreateUserPage";
import StatsPage from "../../pages/StatsPage";
import PartnerReport1Page from "../../pages/PartnerReport1Page";
import PartnerReport2Page from "../../pages/PartnerReport2Page";
import PartnerReport4Page from "../../pages/PartnerReport4Page";
import PartnerReport5Page from "../../pages/PartnerReport5Page";
import AffDataPage from "../../pages/AffDataPage";
import ManualActionsPage from "../../pages/ManualActionsPage";
import CuratorsPage from "../../pages/CuratorsPage";
import PartnerDealsPage from "../../pages/PartnerDealsPage";
import PartnerWebPage from "../../pages/PartnerWebPage";
import MegaChatPage from "../../pages/MegaChatPage";
import AffBalancePage from "../../pages/AffBalancePage";
import AffLinksPage from "../../pages/AffLinksPage";
import ActionsPage from "../../pages/ActionsPage";
import AllPage from "../../pages/AllPage";
import ReportPage from "../../pages/ReportPage";
import HighRollersPage from "../../pages/HighRollersPage";
import AffSalesPage from "../../pages/AffSales";
import OptimizationPage from "../../pages/OptimizationPage";
import PaymentsPage from "../../pages/PaymentsPage";
import StatisticsPage from "../../pages/StatisticsPage";
import MonthlyReportPage from "../../pages/MonthlyReport";
import PartnerStatsPage from "../../pages/PartnerStatsPage";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import ChangePasswordPage from "../../pages/ChangePasswordPage";

// import { getAsyncRates } from "../../constants/";
class AppRouter extends PureComponent {
  state = {
    toggle: false,
    windowWidth: 0,
    currentPage: "",
    sideNavToggled: false,
    breakWidth: 1400,
  };

  componentDidMount() {
    const { openSnackbar } = this.props;
    const updateOnlineStatus = (event) => {
      const condition = navigator.onLine
        ? "You are online"
        : " You are offline";
      console.log("condition", condition);
      openSnackbar(condition);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    // await getAsyncRates();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  toggleSideNav = () => {
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled,
      });
    }
  };

  getInitialPage = () => {
    const { user } = this.props;
    const isPartner = user?.role?.includes("Partner");
    const isAffiliate = user?.role?.includes("Affiliate");
    if (isPartner) {
      return MainPage;
    }
    if (isAffiliate) {
      return AffDataPage;
    }
    return StatisticsPage;
  };

  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0",
    };
    const { authenticated, user } = this.props;
    const isAdmin = user?.role?.includes("dmi");
    const isPartner = user?.role?.includes("Partner");
    const isManager = user?.role?.includes("Manager");
    const isAffiliate = user?.role?.includes("Affiliate");
    return (
      <div className="app">
        <div className="white-skin">
          {authenticated && (
            <SideBar
              breakWidth={1400}
              style={{ transition: "all .3s" }}
              triggerOpening={this.state.sideNavToggled}
              onLinkClick={() => this.toggleSideNav()}
            />
          )}
        </div>
        <div className="flexible-content white-skin">
          {authenticated && (
            <TopBar
              toggle={this.state.windowWidth < this.state.breakWidth}
              onSideNavToggleClick={this.toggleSideNav}
              routeName={this.state.currentPage}
              className="white-skin"
            />
          )}
          <main
            style={
              authenticated
                ? { ...dynamicLeftPadding, margin: "8rem 6% 6rem" }
                : { margin: "20rem 15% 6rem" }
            }
          >
            {
              user?.id != '244' ? (
                <Switch>
                <ProtectedRoute
                  path="/sites"
                  authenticated={authenticated && isAdmin}
                  component={SitesPage}
                />
                <ProtectedRoute
                  path="/dashboard"
                  authenticated={authenticated && (isAdmin || isPartner)}
                  component={MainPage}
                />
                <ProtectedRoute
                  path="/all"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={AllPage}
                />
                <ProtectedRoute
                  path="/report"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={MainPage}
                />
                <ProtectedRoute
                  path="/create-site"
                  authenticated={authenticated && isAdmin}
                  component={CreateSitePage}
                />
                <ProtectedRoute
                  path="/users"
                  authenticated={authenticated && isAdmin}
                  component={UsersPage}
                />
                <ProtectedRoute
                  path="/create-user"
                  authenticated={authenticated && isAdmin}
                  component={CreateUserPage}
                />
                <ProtectedRoute
                  path="/by-reg-date"
                  authenticated={authenticated && (isAdmin || isManager)}
                  component={StatsPage}
                />
                <ProtectedRoute
                  path="/aff-data"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={AffDataPage}
                />
                <ProtectedRoute
                  path="/megachat"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={MegaChatPage}
                />
                <ProtectedRoute
                  path="/curators"
                  authenticated={authenticated && isAdmin}
                  component={CuratorsPage}
                />
                <ProtectedRoute
                  path="/manual-actions"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={ManualActionsPage}
                />
                <ProtectedRoute
                  path="/partners/report1"
                  authenticated={authenticated && isPartner}
                  component={PartnerReport1Page}
                />
                <ProtectedRoute
                  path="/partners/report2"
                  authenticated={authenticated && isPartner}
                  component={PartnerReport2Page}
                />
                <ProtectedRoute
                  path="/partners/report4"
                  authenticated={authenticated && isAdmin}
                  component={PartnerReport4Page}
                />
                <ProtectedRoute
                  path="/optimization"
                  authenticated={authenticated && isAdmin}
                  component={OptimizationPage}
                />
                <ProtectedRoute
                  path="/partners/report5"
                  authenticated={authenticated && (isAdmin || isManager)}
                  component={PartnerReport5Page}
                />
                <ProtectedRoute
                  path="/partners/partner-deals"
                  authenticated={authenticated && (isAdmin || isManager)}
                  component={PartnerDealsPage}
                />
                <ProtectedRoute
                  path="/partners/partner-webs"
                  authenticated={authenticated && (isAdmin || isManager)}
                  component={PartnerWebPage}
                />
                <ProtectedRoute
                  path="/balance"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={AffBalancePage}
                />
                <ProtectedRoute
                  path="/links"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={AffLinksPage}
                />
                <ProtectedRoute
                  path="/actions"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={ActionsPage}
                />
                <ProtectedRoute
                  path="/high-rollers"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={HighRollersPage}
                />
                <ProtectedRoute
                  path="/aff-sales"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={AffSalesPage}
                />
                <ProtectedRoute
                  path="/payments"
                  authenticated={authenticated && (isAdmin || isAffiliate)}
                  component={PaymentsPage}
                />
                <ProtectedRoute
                  path="/statistics"
                  authenticated={authenticated && (isAdmin || isManager || isAffiliate)}
                  component={StatisticsPage}
                />
                <ProtectedRoute
                  path="/monthly-report"
                  authenticated={authenticated && (isAdmin || isManager)}
                  component={MonthlyReportPage}
                />
                <ProtectedRoute
                  path="/partners/stats"
                  authenticated={authenticated}
                  component={PartnerStatsPage}
                />
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Route path="/change-password" component={ChangePasswordPage} />
                <Route
                  path="/"
                  component={authenticated ? this.getInitialPage() : LoginPage}
                />
              </Switch>
              ) : (
                <Switch>
                  <Route
                    path="/"
                    component={authenticated ? this.getInitialPage() : LoginPage}
                  />
              </Switch>
              )
            }
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  authenticated: auth.authenticated,
  user: auth.user,
});

export default withSnackbar(
  withRouter(connect(mapStateToProps, null)(AppRouter))
);
