export default class Storage {
    constructor(type) {
      this.type = type;
    }
  
    get = () => {
      const item = localStorage.getItem(`MediaMars-${this.type}`);
  
      return JSON.parse(item);
    };
  
    save = item => localStorage.setItem(`MediaMars-${this.type}`, JSON.stringify(item));
  
    delete = () => localStorage.removeItem(`MediaMars-${this.type}`);
  
  }
  