import React from 'react';
import moment from 'moment';
import { MDBInput, MDBBtn, MDBCard, MDBRow, MDBCol } from 'mdbreact';

const DateFilter = ({onFormChange = () => {}, form, onSubmit}) => {
  return (
    <MDBCard className='p-2 mb-5'>
    <MDBRow>
      <MDBCol lg='3' md='6'>
        <MDBInput
          required
          label="From date"
          type="date"
          group
          value={form.From}
          onChange={(e) => onFormChange('From', e.target.value)}
          max={moment().format('YYYY-MM-DD')}
        />
      </MDBCol>

      <MDBCol lg='3' md='6'>
        <MDBInput
          required
          label="To date"
          group
          type="date"
          value={form.To}
          onChange={(e) => onFormChange('To', e.target.value)}
          max={moment().format('YYYY-MM-DD')}
        />
      </MDBCol>

      <MDBCol className="btn-container" lg='3' md='6'>
        <MDBBtn onClick={onSubmit}>Show</MDBBtn>
      </MDBCol>
    </MDBRow>
  </MDBCard>
  )
}

export default DateFilter;