import React, { useState } from "react";
import { MDBInput, MDBBtn, MDBSpinner } from "mdbreact";
import { useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

import MainContainer from "../../components/MainContainer";
import http from "../../services/http";
import { failureOptions, succesOptions } from "../../configs";

const ChangePasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");


  const location = useLocation();
  const history = useHistory();

  const [openFailureSnackbar] = useSnackbar(failureOptions);
  const [openSuccessSnackbar] = useSnackbar(succesOptions);


  
  const queryParams = new URLSearchParams(location.search);

  const handleResetPassword = async () => {
    if (repeatPassword !== password) {
      openFailureSnackbar('Password missmatch')
      return;
    }
    setLoading(true);
    try {
      await http.post("/auth/resetpassword/confirm", {
        password,
        code: queryParams.get('code'),
      });
      openSuccessSnackbar('Password changed!')
      history.push('/')
    } catch (err) {
      openFailureSnackbar('Something went wrong')
    }
    setLoading(false);

  };

  return (
    <MainContainer>
      <form>
        <p className="h5 text-center mb-4">Reset password</p>
        <div className="grey-text">
          <MDBInput
            onChange={(e) => setPassword(e.target.value)}
            required
            label="Type new password"
            icon="lock"
            group
            type="password"
            validate
          />
          <MDBInput
            onChange={(e) => setRepeatPassword(e.target.value)}
            required
            label="Repeat password"
            icon="lock"
            group
            type="password"
            validate
          />
        </div>
        <div className="text-center">
          {loading ? (
            <MDBSpinner className="spinner" />
          ) : (
            <MDBBtn onClick={handleResetPassword}>Submit</MDBBtn>
          )}
        </div>
      </form>
    </MainContainer>
  );
};

export default ChangePasswordPage;
