import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 1.5rem 0;
  z-index: 1;
  * {
    user-select: none;
  }
`;

export const Input = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #ced4da;
  position: absolute;
  top: 50px;
  border-radius: 4px;
  padding: 1rem;
`;

export const Option = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${({ isSelected }) => isSelected ? '#4285f4' : 'rgba(66, 133, 244, 0.2)'};
  border: 1px solid ${({ isSelected }) => !isSelected ? '#4285f4' : 'transparent'};
  margin: 5px 1rem;
  border-radius: 4px;
  padding: 5px 1rem;
  color: ${({ isSelected }) => isSelected ? 'white' : 'black'};
  :hover {
    opacity: 0.8;
  }
`;
