import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBInput,
  MDBModalBody,
} from "mdbreact";
import styled from "styled-components";
import http from "../../../../services/http";
import { failureOptions, succesOptions } from "../../../../configs";
import { useSnackbar } from "react-simple-snackbar";

const TestModal = ({ visible, onClose, onSave, actionType }) => {
  const [testLink, setTestLink] = useState("");
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions);

  const onSubmit = async () => {
    const { data } = await http.post("/actions/test", {
      url: testLink,
      action: actionType,
    });
    if (data.result === "Fail") {
      openFailureSnackbar("Fail");
    } else {
      openSuccessSnackbar('Success');
    }
    onSave();
    onClose();
  };

  return (
    <MDBModal isOpen={visible} toggle={onClose}>
      <Container>
      <MDBModalBody>
        <span>Test link</span>
        <MDBInput
          value={testLink}
          onChange={(e) => setTestLink(e.target.value)}
          style={{ marginTop: 0 }}
        />
        <span>Action Type</span>
        <MDBInput
          style={{ opacity: 0.4 }}
          disabled
          value={actionType}
        />
      </MDBModalBody>
      </Container>
      <MDBModalFooter>
        <MDBBtn color="warning" onClick={onSubmit}>
          Save and run test
        </MDBBtn>
        <MDBBtn color="secondary" onClick={onClose}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

const Container = styled.div`
  .md-form {
    margin-top: 0;
  }
`;
export default TestModal;
