import styled, { css } from "styled-components";

export const Container = styled.div`
  .card-header {
    background-color: transparent;
  }
`;

export const StepLink = styled.span`
  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
        color: blue;
      `;
    }
  }};
`;

export const RoiCell = styled.td`
  background-color: ${({ roi }) => roi > 1.5 ? '#b2f2bb' : '#ffc9c9'};
  font-weight: bold;
  color: black;
`;

const typeColors = {
  'fillprofile': '#7c501a',
  'emailconfirmed': '#339900',
  'purchase': '#7289da',
  'firstpurchase': '#ffbf00',
}
export const TypeCell = styled.td`
  font-weight: bold;
  color: ${({ type }) => typeColors[type] || 'black'};
`;