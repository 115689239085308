import React from 'react';
import { MDBTable, MDBTabContent, MDBSpinner } from 'mdbreact';

import Deactivate from '../../../../components/Deactivate';

import * as S from './styled';

const SitesTable = ({sites, loading, deleteSite}) => {
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = React.useState("0");
  return (
    <S.Container>
      <MDBTabContent
        className='card'
        activeItem={activeTab}
      >
        {!loading ? <MDBTable responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Domain</th>
              <th>Name</th>
              <th>UID</th>
              <th>Deactivate</th>
            </tr>
          </thead>
          <tbody>
            {sites.map(site => (
              <>
                {!site.isDeleted && <tr key={site.id}>
                  <td>{site.id}</td>
                  <td>{site.domain}</td>
                  <td>{site.name}</td>
                  <td>{site.uid}</td>
                  <Deactivate onDelete={() => deleteSite(site.id)} />
                </tr>}
              </>
            ))}
          </tbody>
        </MDBTable> : <MDBSpinner className="spin" />}
      </MDBTabContent>
    </S.Container>

  )
}

export default SitesTable;