import styled from 'styled-components';

export const Container = styled.div`
  & .nav-link.Ripple-parent {
    border-color: red;
  }
  & .nav-link.Ripple-parent.stop.active {
    border-color: green;
  }
  & .spin {
    margin: auto;
  }
`;
