import React from 'react';
import { MDBContainer } from 'mdbreact';

import Statistics from './components/Statistics';


class StatisticsPage extends React.Component  {
  render() {
    return (
        <MDBContainer fluid>
          <Statistics />
        </MDBContainer>
    );
  }
}

export default StatisticsPage;