import React from 'react';
import { MDBInput, MDBBtn, MDBSpinner } from 'mdbreact';
import { withSnackbar } from 'react-simple-snackbar';
import { connect } from 'react-redux';

import { requestSignIn } from '../../store/auth';

import MainContainer from '../../components/MainContainer';

class LoginPage extends React.Component  {
  state = {
    email: '',
    password: '',
  }

  componentDidMount() {
    this.props.history.push('/');
  }
  componentDidUpdate(prevProps) {
    if ((prevProps.authError !== this.props.authError) && this.props.authError === 400) {
      const { openSnackbar } = this.props
      openSnackbar('You entered invalid credentials!', 3000);
    }
    if ((prevProps.authError !== this.props.authError) && this.props.authError === 500) {
      const { openSnackbar } = this.props
      openSnackbar('Something went wrong :(', 3000);
    }
  }

  onBtnPress = () => {
    const {email, password} = this.state;
    this.props.requestSignIn({
      email,
      password,
    });
  }

  onInputChange = (value, inputType) => this.setState({[inputType]: value});

  handleForgotPassword = () => {
    this.props.history.push('/forgot-password');
  }
  render() {
  const {loading} = this.props;
  return (
    <MainContainer>
      <form>
        <p className="h5 text-center mb-4">Sign in</p>
        <div className="grey-text">
          <MDBInput
            onChange={(e) => this.onInputChange(e.target.value, 'email')}
            required
            label="Type your email"
            icon="envelope"
            group
            type="email"
            validate
            error="wrong"
            success="right"
          />
          <MDBInput
            onChange={(e) => this.onInputChange(e.target.value, 'password')}
            required
            label="Type your password"
            icon="lock"
            group
            type="password"
            validate
          />
        </div>
        <div onClick={this.handleForgotPassword} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>
          Forgot password?
        </div>
        <div className="text-center">
        {loading ? <MDBSpinner className="spinner" /> : <MDBBtn onClick={this.onBtnPress}>Login</MDBBtn>}
        </div>
      </form>
    </MainContainer>
  );
  }
}
const mapStateToProps = ({auth}) => ({
  authError: auth.authError,
  loading: auth.loading,
})

const mapDispatchToProps = {
  requestSignIn,
}

const options = {
  style: {
    backgroundColor: 'red',
    color: 'white',
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(LoginPage), options);