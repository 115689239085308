import React from 'react';
import { MDBContainer } from 'mdbreact';

import http from '../../services/http';

import SitesTable from './components/SitesTable';
import { withSnackbar } from 'react-simple-snackbar';

class SitesPage extends React.Component  {
  state = {
    sites: [],
    loading: false,
  }

  async componentDidMount() {
    this.setState({loading: true});
    const {data} = await http.get('/sites');
    this.setState({sites: data,  loading: false});
  }

  deleteSite = async (siteId) => {
    const {openSnackbar} = this.props;
    await http.delete(`/sites/${siteId}`);
    const {data} = await http.get('/sites');
    openSnackbar('Site deleted', 3000);
    this.setState({sites: data,  loading: false})
  };

  render() {
    return (
      <MDBContainer fluid>
        <SitesTable deleteSite={this.deleteSite} loading={this.state.loading} sites={this.state.sites} />
      </MDBContainer>
    );
  }
}

const options = {
  style: {
    backgroundColor: 'green',
    color: 'white',
  }
}

export default withSnackbar(SitesPage, options);